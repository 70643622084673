import HttpClient from '@/services/http-client'

class ClientsService extends HttpClient {
  /**
   * @param  {Object} config - Configuração de listagem
   * @param  {String} config.q - Texto que será procurado na listagem
   */
  async list(config) {
    const {
      text = '',
      perPage = 10,
      page = 1,
      filter_client = null
    } = config ?? {}

    return this.rest.get('/v1/clients', {
      params: { page, perPage, text, filter_client }
    })
  }

  async get(id) {
    return this.rest.get('/v1/clients/' + id)
  }

  async store(payload) {
    return this.rest.post('/v1/clients', payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/clients/' + id)
  }

  async update({ client, address, contact }) {
    return (
      await this.http.put('/v1/clients/' + client.id, {
        client,
        address,
        contact
      })
    ).data
  }
}

export default () => {
  return new ClientsService()
}
