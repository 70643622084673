'use strict'
// Thi store depends from some plugins like CASL in Vue instance

import { mutate, doAsyncBusyTask, setState } from '@/helpers/store-helpers'
import tableManufactor from '@/features/table-manufactor/table-manufactor-api'

export default {
  namespaced: true,
  state: {
    tableManufactorList: [],
    tableManufactorBusy: false,
    tableManufactorErr: undefined
  },
  actions: {
    async listTableManufactor({ state, commit }, params) {
      try {
        commit('setIsBusy')
        const { data } = await tableManufactor().list(params)
        setState(commit, 'tableManufactorList', data)
      } catch (e) {
        commit('setIsBusy', false)
        throw e
      } finally {
        commit('setIsBusy', false)
      }
    },
    async showTableManufactor({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state }, async ({ state, commit }) => {
        return tableManufactor().get(id)
      })
    },
    async deleteTableManufactor({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state }, async ({ state, commit }) => {
        const result = await tableManufactor().delete(id)
        if (result) await commit('removeRole', id)
        return result
      })
    }
  },
  getters: {
    tableManufactorErr(state) {
      return state.tableManufactorErr
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.tableManufactorErr = undefined
    },
    setIsBusy(state, value = false) {
      state.tableManufactorBusy = value
    }
  }
}
