'use strict'

import roleApi from '@/features/roles/roles-api'
import { filterElements, doAsyncBusyTask, mutate } from '@/helpers/store-helpers'

export default {
  namespaced: true,
  state: {
    roles_list: []
  },
  actions: {
    async createRole({ state, commit }, payload) {
      const created = await roleApi().store(payload)
      commit('insertRole', created)
    },
    async updateRole({ state, commit }, payload) {
      commit('isBusy')
      try {
        const updated = await roleApi().update(payload)
        commit('updateListRef', updated)
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async fetchRoles({ state, commit }, payload) {
      commit('isBusy')
      try {
        const roles = await roleApi().list()
        commit('setRolesList', roles)
        commit('isBusy', false)
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async getRole({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName: 'isBusy' }, async ({ state, commit }) => {
        return roleApi().get(id)
      })
    },
    async deleteRole({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName: 'isBusy' }, async ({ state, commit }) => {
        const result = await roleApi().delete(id)
        if (result) await commit('removeRole', id)
        return result
      })
    }
  },
  getters: {
    totalRoles(state) {
      return (state.roles_list || []).length
    },
    filteredRoles(state) {
      return filterElements(state.roles_list, state.filter)
    }
  },
  mutations: {
    mutate,
    isBusy(state, val = true) {
      state.isBusy = val
    },
    insertRole(state, data) {
      state.roles_list.push(data)
    },
    setRolesList(state, roles) {
      state.roles_list = roles
    },
    removeRole(state, payload) {
      state.roles_list = state.roles_list.filter((value) => value.id !== payload)
    },
    filterRole(state, filter) {
      state.filter = filter.toLowerCase()
    },
    updateListRef(state, update) {
      if (state.roles_list) {
        state.roles_list = state.roles_list.map((val) => {
          if (val.id === update.id) {
            return update
          }
          return val
        })
      }
    }
  }
}
