<template>
  <b-form-group label="Medidores em uso:">
    <AddBtn @click="addMeterModel"></AddBtn>
    <CTable
      bordered
      striped
      responsive
      sticky-header="700px"
      :fields="[
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: 'Nome'
        },
        {
          key: 'actions',
          label: 'Ações'
        }
      ]"
      style="max-height: 400px"
      class="table-responsive"
      :items="value"
    >
      <template v-slot:cell(name)="data">
        {{ formatMetterInfo(data.item) }}
      </template>
      <template v-slot:cell(actions)="data">
        <OpenBtn @click="openMeterModel(data.item)" can="read_meter_models" />

        <RemoveBtn @click="deleteMeterModel(data)" can="update_manufactors" />
      </template>
    </CTable>

    <b-modal size="xs" ref="meterModelSelector" modal-class="modal-fillin">
      <div slot="modal-title">
        Selecionar Modelo de <span class="font-weight-light">Medidor</span
        ><br />
        <small class="text-muted"
          >Busque o modelo que será adicionado a lista</small
        >
      </div>

      <b-overlay
        id="overlay-background"
        variant="white"
        opacity="0.85"
        blur="2px"
        spinner-variant="primary"
        spinner-type="grow"
        :spinner-small="true"
        rounded="sm"
      >
        <MeterModelPicker
          track-by="id"
          label="model_name_for_user_listing"
          v-model="meterModelSelected"
          :state="isValidField('value')"
          :max-height="700"
          :options-limit="1000"
          :searchable="true"
          :multiple="false"
          :hide-selected="false"
          :taggable="false"
          :allowEmpty="false"
          :notIncludeIds="notIncludeIds"
          deselect-label="Remover seleção"
          selectLabel="Escolher"
          selectedLabel="Selecionado"
          tag-placeholder="Escolha um modelo da Lista"
          placeholder="Selecione o modelo de medidor"
          size="sm"
          class="multiselect-primary row-12 mb-0 small"
        />
      </b-overlay>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="md" variant="secondary" @click="cancel">
          Cancelar
        </b-button>
        <b-button
          size="md"
          :variant="isValidMeter ? 'success' : 'secondary'"
          @click="isValidMeter ? finishMeterAdd(ok) : () => {}"
        >
          Selecionar medidor
        </b-button>
      </template>
    </b-modal>
  </b-form-group>
</template>

<style></style>

<script>
import vuelidateStateMixin from '@/mixins/vuelidate-state-mixin'

export default {
  name: 'MeterModelsSelection',
  mixins: [vuelidateStateMixin],
  props: {
    value: {
      default: []
    },
    /** Meter models that will be ignored during search */
    notIncludeIds: {
      default: null
    }
  },
  data() {
    return {
      meterModelSelected: null
    }
  },
  mounted() {
    this.$emit('value', this.value)
  },
  computed: {
    isValidMeter() {
      return !!this?.meterModelSelected?.id
    }
  },
  methods: {
    isValidField(field, validation) {
      return this.validateField(field, validation, !this.isFirstTime)
    },
    async addMeterModel(item) {
      this.$refs.meterModelSelector.show()
    },
    async finishMeterAdd(ok) {
      this.value.splice(0, 0, this.meterModelSelected)
      this.meterModelSelected = null
      ok()
    },
    async deleteMeterModel(item) {
      this.value.splice(item.index, 1)
    },
    async openMeterModel(item) {
      const routeData = await this.$router.resolve({
        path: '/meter-models/' + item.id
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
