var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "layout-footer footer",
    class: _vm.getLayoutFooterBg()
  }, [_c('div', {
    staticClass: "container-fluid container-p-x pb-3"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }