<template>
  <b-input-group class="mb-3">
    <b-input
      :id="id"
      type="text"
      :state="isValid"
      class="form-control"
      :size="size"
      :value="dateTextInput"
      @input="onTypeText"
      placeholder="01/01/1891"
      :disabled="disabled"
    />
    <b-input-group-append>
      <b-form-datepicker
        button-only
        right
        id="date-input"
        v-model="selectedDate"
        :size="size"
        :max="maxDate"
        :min="minDate"
        :value-as-date="true"
        @input="userSelectedPicker"
        input-class="form-control"
        placeholder="01/01/1891"
        :disabled="disabled"
      >
      </b-form-datepicker>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import moment from "moment";

export default {
  props: {
    id: {
      default: undefined,
    },
    value: {
      default: undefined,
    },
    size: {
      default: "md",
    },
    disabled: {
      default: false,
    },
  },
  mounted() {
    this.setValue(this.value);
    // this.dateTextInput = moment(this.value).format('DD/MM/YYYY')
  },
  watch: {
    value(nval) {
      // console.warn('value', nval)
      this.setValue(nval);
    },
  },
  data() {
    return {
      isValid: undefined,
      dateTextInput: "",
      selectedDate: undefined,
      maxDate: undefined,
      minDate: undefined,
    };
  },
  methods: {
    setValue(value) {
      if (typeof value === "string") {
        // console.error('setValue.string')
        this.selectedDate = moment(value).toDate();
      } else if (value instanceof Date) {
        // console.error('setValue.date')
        this.selectedDate = value;
      } else if (value) {
        // console.error('setValue.value')
        this.selectedDate = value;
      } else {
        this.selectedDate = null;
      }
      this.dateTextInput = this.formatDate(this.selectedDate);
      // console.error(value)
    },
    /**
     * Description
     * @param {Date} date
     * @returns {any}
     */
    formatDate(date) {
      if (date) {
        // console.warn('Format date', date)
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      }
      return this.dateTextInput;
    },
    onTypeText(value) {
      this.dateTextInput = value;
      this.updateSelectedDate(value);
    },
    userSelectedPicker(dateObj) {
      this.$emit("input", dateObj);
    },
    updateSelectedDate(value) {
      // console.warn('updateSelectedDate', value)
      const parts = value?.split("/");
      if (value?.length >= 10 && parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        this.selectedDate = new Date(year, month, day);
        this.isValid = true;
      } else {
        this.isValid = false;
        this.selectedDate = null;
      }
      this.$emit("input", this.selectedDate);
    },
  },
};
</script>
