const calcCheckDigit = require('../math/find-check-digit')
const removeCheckDigit = require('../../../utils/remove-check-digit')
const getLastDigit = require('../../../utils/get-last-digit')

/** Digito verificador minimo permitido durante predição de digitos verificadores */
const MIN_ALLOWED_CHECK_DIGIT = 2
/** Digito verificador máximo permitido durante predição de digitos verificadores */
const MAX_ALLOWED_CHECK_DIGIT = 20
// ^ambos atual como um threshold |<- minimo e máximo ->| para a predição ficar entre eles
function withMoreFitness(digitFitness) {
  let withMoreFit
  for (const currentDigit in digitFitness) {
    if (digitFitness[currentDigit]) {
      if (withMoreFit == null) {
        withMoreFit = currentDigit
      }
      if (
        digitFitness[currentDigit] > 0 &&
        digitFitness[currentDigit] > digitFitness[withMoreFit]
      ) {
        withMoreFit = currentDigit
      }
    }
  }
  return withMoreFit
}
/**
 *
 * @param {Array} exampleArray Array from seals examples numbers
 */
function findMagicNumber(exampleArray) {
  const aptitudes = {}

  /// aptitudes store every digit try in memory, for verification in the future, the structures sounds like this
  ///
  /// { 0, 1, 2, 3, 4, 5 } who has more matches its the most suitable digit
  ///  [x,[x,[x,[x,[x,[x,
  ///   x, x] x,    x, x]
  ///   x,    x]    x,
  ///   x]          x]

  for (let currentSeal of exampleArray) {
    currentSeal = currentSeal.toString() // first convert to string to allow split
    const originalDigit = parseInt(getLastDigit(currentSeal)) // original check digit for check
    const withoutVerifier = removeCheckDigit(currentSeal) // number without checkdigit

    for (
      let magicNum = MIN_ALLOWED_CHECK_DIGIT;
      magicNum <= MAX_ALLOWED_CHECK_DIGIT;
      magicNum++
    ) {
      if (!aptitudes[magicNum]) {
        aptitudes[magicNum] = 0
      } // if doesnt have pontuaction define one
      const checkDigit = calcCheckDigit(withoutVerifier, magicNum)
      if (originalDigit === checkDigit) {
        aptitudes[magicNum] += 1
      }
    }
  }
  const fittest = withMoreFitness(aptitudes)
  if (fittest) {
    return parseInt(fittest)
  } else {
    return null
  }
}

module.exports = findMagicNumber
