export default {
  mounted() {},
  methods: {
    statusColor: function (item) {
      switch (item.serviceStatus.slug) {
        case 'created_service':
          return 'secondary'

        case 'relased_to_tests':
          return 'warning'

        case 'finished_service':
          return 'success'

        default:
          return 'primary'
      }
    },
    routeServiceId() {
      return this?.$route?.params?.service_id ?? null
    },
    serviceNoteItemId() {
      return this?.$route?.params?.meter_id ?? null
    },
    paramMeterId() {
      return this?.$route?.params?.meter_id ?? null
    },
    prevStep() {
      this.$emit('prev-step')
    }
  },
  computed: {}
}
