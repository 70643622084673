import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  mounted() {},
  computed: {
    ...mapState('fileDownload', ['downloadBusy']),
    ...mapGetters('fileDownload', [])
  },
  methods: {
    ...mapActions('fileDownload', ['downloadFile', 'downloadFileNewTab', 'fetchFileBase64']),
    ...mapMutations('fileDownload', [])
  }
}
