'use strict'
// Thi store depends from some plugins like CASL in Vue instance

import { mutate } from '@/helpers/store-helpers'
import metersApi from '@/features/meter-models/meter-models-api'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    upload: null,
    meterImage: null,
    meterBusy: false,
    meterError: undefined
  },
  actions: {
    async updateMeterImage({ state, commit }, payload) {
      try {
        commit('meterBusy', true)
        const data = await metersApi().uploadImage(payload)
        commit('setMeterThumb', data)
      } catch (e) {
        console.error(e)
        commit('mutate', { key: 'meterError', value: e.toString() })
      } finally {
        commit('mutate', { key: 'meterBusy', value: false })
      }
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    meterImage(state) {
      return state.meterImage || 'http://omega.3ccloud/static/no-image.png'
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    isBusy(state, value = false) {
      state.meterBusy = false
    },
    setMeterThumb(state, url) {
      state.meterImage = url
    },
    meterBusy(state, value) {
      state.meterBusy = value || false
    }
  }
}
