import HttpClient from '@/services/http-client'

class FiscalNoteItemClient extends HttpClient {
  async list() {
    return this.rest.get('/v1/service-items')
  }

  async get(id) {
    return this.rest.get('/v1/service-items/' + id)
  }

  async delete(id) {
    return this.rest.delete('/v1/service-items/' + id)
  }

  async update(id, payload) {
    return this.rest.patch('/v1/service-items/' + id, payload)
  }

  async setState(id, stateslug) {
    return this.rest.patch('/v1/service-items/' + id + '/state', {
      slug: stateslug
    })
  }

  async addMeterSerial(fiscalItemID, { serial_number }) {
    return this.rest.post(
      '/v1/service-items/' + fiscalItemID + '/meter-serials',
      {
        serial_number: serial_number
      }
    )
  }

  async removeMeterSerial(fiscalItemID, meterSerialID) {
    return this.rest.delete(
      '/v1/service-items/' + fiscalItemID + '/meter-serials/' + meterSerialID
    )
  }
}
export default () => new FiscalNoteItemClient()
