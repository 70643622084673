'use strict'

import {
  mutate,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState,
  doAsyncBusyTask
} from '@/helpers/store-helpers'

import ReportPreviewsService from '@/features/report-previews/report-previews-api'

const busyMutateName = 'setReportPreviewIsBusy'

export default {
  namespaced: true,
  state: {
    reportPreviewList: [],
    reportPreviewFilter: '',
    reportPreviewIsBusy: false,
    reportPreviewTotalRows: 0,
    reportPreviewCurrentPage: 1,
    reportPreviewPerPage: 10,
    reportPreviewLastPage: 0
  },
  actions: {
    async fetchReportPreviewHTML({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, ({ commit, state, busyMutateName }) => {
        const {
          service_id = null,
          service_meter_serial_id = null,
          show_signature = false,
          force_refresh = false,
          show_report_date = false
        } = payload ?? {}

        return ReportPreviewsService().show({
          service_id,
          service_meter_serial_id,
          show_signature,
          show_report_date,
          force_refresh
        })
      })
    }
  },
  getters: {
    filteredReportPreviews(state) {
      return filterElements(state.reportPreviewList, state.filter)
    }
  },
  mutations: {
    mutate,
    setReportPreviewsList(state, payload) {
      state.reportPreviewList = payload
    },
    setReportPreviewsFilter(state, payload) {
      state.reportPreviewList = payload
    },
    setReportPreviewIsBusy(state, value = false) {
      state.reportPreviewIsBusy = value
    },
    unshiftReportPreview(state, payload) {
      state.reportPreviewList.unshift(payload)
    },
    removeReportPreviewInList(state, payload) {
      state.reportPreviewList = state.reportPreviewList.filter((reportPreview) => reportPreview.id !== payload)
    },
    updateReportPreviewInList(state, payload) {
      if (state.reportPreviewList) {
        state.reportPreviewList = state.reportPreviewList.map((val) => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
