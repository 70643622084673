import HttpClient from '@/services/http-client'

class IPEMReportsApi extends HttpClient {
  async list(config) {
    return this.rest.get('/v1/ipem-reports', config)
  }

  async check(config) {
    return this.rest.get('/v1/ipem-reports-busy', config)
  }

  async store(payload) {
    return this.rest.post('/v1/ipem-reports', payload)
  }

  async preview(payload) {
    return this.rest.get('/v1/ipem-reports-preview', {
      params: {
        start_at: payload.startAt,
        end_at: payload.endAt
      }
    })
  }

  async show(id) {
    return this.rest.get('/v1/ipem-reports/' + id)
  }

  async delete(id) {
    return this.rest.delete('/v1/ipem-reports/' + id)
  }
}
export default () => new IPEMReportsApi()
