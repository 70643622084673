'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import anomalyResultService from '@/features/anomaly-results/anomaly-results-service'

const busyMutateName = 'setAnomalyResultsBusy'

export default {
  namespaced: true,
  state: {
    anomalyResultList: [],
    anomalyResultFilter: '',
    anomalyResultIsBusy: false,
    anomalyResultErr: undefined,
    anomalyResultCurrentPage: 1,
    anomalyResultLastPage: 1,
    anomalyResultPerPage: 20
  },
  actions: {
    async listAnomalyResult({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await anomalyResultService().list(page, perPage, text)
          setState(commit, 'anomalyResultTotalRows', payload.total)
          setState(commit, 'anomalyResultCurrentPage', payload.page)
          setState(commit, 'anomalyResultPerPage', payload.perPage)
          setState(commit, 'anomalyResultLastPage', payload.lastPage)

          setState(commit, 'anomalyResultList', payload.data)
        }
      )
    },
    async showAnomalyResult({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return anomalyResultService().show(id)
        }
      )
    },
    async createAnomalyResult({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = anomalyResultService().create(payload)
          commit('insertAnomalyResultInList', entry)
        }
      )
    },
    async updateAnomalyResult({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = anomalyResultService().update(payload)
          commit('updateAnomalyResultInList', entry)
          return entry
        }
      )
    },
    async deleteAnomalyResult({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await anomalyResultService().delete(id)
          if (result) await commit('removeAnomalyResultInList', id)
          return result
        }
      )
    }
  },
  getters: {
    anomalyResultErr(state) {
      return state.anomalyResultErr
    },
    anomalyResulttotalRows(state) {
      return (state.anomalyResultList || []).length
    },
    filteredAnomalyResults(state) {
      return filterElements(state.anomalyResultList, state.filter)
    }
  },
  mutations: {
    mutate,
    cleanErrors(state, value) {
      state.anomalyResultErr = undefined
    },
    setAnomalyResultsList(state, payload) {
      state.anomalyResultList = payload
    },
    setAnomalyResultsFilter(state, payload) {
      state.anomalyResultList = payload
    },
    setAnomalyResultsBusy(state, value = false) {
      state.anomalyResultIsBusy = value
    },
    insertAnomalyResultInList(state, payload) {
      state.anomalyResultList.unshift(payload)
    },
    removeAnomalyResultInList(state, payload) {
      state.anomalyResultList = state.anomalyResultList.filter(
        anomalyResult => anomalyResult.id !== payload
      )
    },
    updateAnomalyResultInList(state, payload) {
      if (state.anomalyResultList) {
        state.anomalyResultList = state.anomalyResultList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
