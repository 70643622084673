<template>
  <b-button
    ref="stampbutton"
    size="md"
    v-b-tooltip.top
    title="Você não possui permissão para esta ação"
    v-bind="{ ...$props, ...$attrs }"
    :variant="'secondary ' + $attrs.variant"
    v-on="$listeners"
  >
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: 'button-not-allowed'
}
</script>

<style>
</style>
