import serviceApi from './service-api'

class AVServiceAPI {
  async list({ statusId, typeId = 1, page = 1, limit = 1000, text }) {
    return serviceApi().list({
      statusId,
      typeId, // auto verificação
      page,
      text,
      limit
    })
  }

  async get(id) {
    return serviceApi().get(id)
  }

  async store(payload) {
    return serviceApi().store(payload)
  }

  async update(payload) {
    return serviceApi().update(payload)
  }

  async changeServiceStatus(payload) {
    return serviceApi().changeServiceStatus(payload)
  }

  async delete(id) {
    return serviceApi().delete(id)
  }
}
export default () => new AVServiceAPI()
