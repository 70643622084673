import Vue from 'vue'
import nProgress from 'nprogress'
import router from '../router'
import vuex from '../stores'
import { setup } from 'axios-cache-adapter'

function showServerErrorDialog(response) {
  Vue.prototype.$swal({
    title: 'Falha',
    html: 'Erro interno do sistema: </br> Por favor contate o administrador do seu sistema',
    icon: 'error'
  })
}

function logoutUser() {
  localStorage.setItem('token', null)
  if (router.currentRoute.path !== '/login') {
    console.warn('currentRoute', router.currentRoute.path)
    router.push('/login')
  }
}

function shouldIgnoreUrlProgress(url) {
  if (!url) {
    return false
  }
  const ignoredSegments = ['/health', '/report-previews/', '/stored-files/', '/expert-mode-extractor/is-busy']
  const match = ignoredSegments.find((segment) => url.toString().includes(segment))
  if (match) {
    return true
  }
  return false
}

export default function () {
  const host = vuex.state.baseAPI
  const axiosInstance = setup({
    baseURL: host, // baseApi Url host.com/api
    timeout: 320000,
    cache: {
      maxAge: 1 * 1000,
      ignoreCache: true,
      exclude: { query: false }
    }
  })
  axiosInstance.interceptors.request.use((config) => {
    // We are importing store before it is populated.
    // We intercept the request and use the current apiKey
    // eslint-disable-next-line dot-notation
    const token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = token
    }

    if (host) config.headers['Access-Control-Allow-Origin'] = host

    return config
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const response = error.response

      if (response && response.status === 401) {
        logoutUser()
      }

      if (response && response.status === 500) {
        showServerErrorDialog(response)
      }

      throw error
    }
  )
  // before a request is made start the nprogress
  axiosInstance.interceptors.request.use((config) => {
    if (!shouldIgnoreUrlProgress(config?.url)) {
      nProgress.start()
    }
    return config
  })

  // before a response is returned stop nprogress
  axiosInstance.interceptors.response.use((response) => {
    if (!shouldIgnoreUrlProgress(response?.config?.url)) {
      nProgress.done()
    }
    return response
  })

  return axiosInstance
}
