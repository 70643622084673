var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.value ? _c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "spinner-small": true,
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "rounded": "sm"
    }
  }, [_c('img', {
    class: _vm.defaultClass,
    style: _vm.defaultStyle,
    attrs: {
      "id": _vm.imgId,
      "src": _vm.imageData
    }
  })]) : _c('div', [_vm._v("SEM IMAGEM")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }