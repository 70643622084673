<template>
  <div>
    <b-btn
      size="sm"
      :variant="buttonColor(value.slug)"
      v-b-tooltip.top
      :title="value.description"
    >
      {{ value.name }}
    </b-btn>
  </div>
</template>

<script>
import statusIndicatorMixin from '@/mixins/statusIndicatorMixin'
export default {
  name: 'sstate-dropwdown',
  props: {
    value: Object
  },
  mixins: [statusIndicatorMixin],

  mounted() {},
  methods: {
    changeState(state, value) {
      if (state === 'finished') {
        this.showInfoModal({
          text:
            'Os medidores ainda não foram testados para passarem para finalizados'
        })
      } else {
        this.activeState = value
        this.state = state
      }
    }
  }
}
</script>
