var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mw-100"
  }, [!_vm.clearBtn ? _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "id": _vm.id,
      "track-by": "id",
      "custom-label": _vm.formattedLabel,
      "hide-selected": false,
      "taggable": false,
      "allowEmpty": true,
      "options": _vm.userList,
      "value": _vm.value,
      "disabled": _vm.disabled,
      "loading": _vm.loadingUser,
      "deselect-label": "",
      "selectLabel": "",
      "selectedLabel": "Em uso",
      "tag-placeholder": "Selecione uma opção",
      "placeholder": "Selecione uma opção"
    },
    on: {
      "open": _vm.onOpenPicker,
      "search-change": _vm.asyncFindUser,
      "select": _vm.onSelectModel,
      "remove": _vm.onRemoveModel
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Sem Signatários.")])]) : _c('b-row', {
    staticClass: "px-2 py-auto"
  }, [_c('b-col', {
    staticClass: "col-11 mr-0 pr-0"
  }, [_c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "id": _vm.id,
      "track-by": "id",
      "custom-label": _vm.formattedLabel,
      "hide-selected": false,
      "taggable": false,
      "allowEmpty": true,
      "options": _vm.userList,
      "value": _vm.value,
      "disabled": _vm.disabled,
      "loading": _vm.loadingUser,
      "deselect-label": "",
      "selectLabel": "",
      "selectedLabel": "Em uso",
      "tag-placeholder": "Selecione uma opção",
      "placeholder": "Selecione uma opção"
    },
    on: {
      "open": _vm.onOpenPicker,
      "search-change": _vm.asyncFindUser,
      "select": _vm.onSelectModel,
      "remove": _vm.onRemoveModel
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Sem Signatários.")])])], 1), _c('b-row', {
    staticClass: "col-1 ml-0 pl-0"
  }, [_c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.right",
      modifiers: {
        "right": true
      }
    }],
    staticClass: "btn-outline-secondary",
    attrs: {
      "size": "xs",
      "title": "Remover Signatário"
    },
    on: {
      "click": _vm.clearValue
    }
  }, [_c('span', {
    staticClass: "ion ion-md-close"
  })])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }