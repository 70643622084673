<template>
  <label class="switcher switcher-sm mr-0" :disabled="disabled">
    <input :id="id" type="checkbox" class="switcher-input" :checked="isChecked" :disabled="disabled" @change="change" />
    <span class="switcher-indicator">
      <span class="switcher-yes"></span>
      <span class="switcher-no"></span>
    </span>
    <span class="switcher-label"> <slot></slot> </span>
  </label>
  <!-- <label class="switcher">
    <input
      type="checkbox"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      :checked="value"
      @change="trigger"
      @input="trigger"
      class="switcher-input"
    />
    <span class="switcher-indicator">
      <span class="switcher-yes"
        ><span class="ion ion-md-checkmark"></span
      ></span>
      <span class="switcher-no"><span class="ion ion-md-close"></span></span>
      </span>
      <span class="switcher-label"> <slot></slot> </span>
  </label> -->
</template>

<script>
export default {
  name: 'ks-switcher',
  props: {
    id: {
      default: undefined
    },
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      default: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    disabled: {
      default: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    emitOnMount: {
      default: true
    }
  },
  mounted() {
    if (this.emitOnMount) {
      this.$emit('input', this.isChecked)
    }
  },
  computed: {
    isChecked() {
      return this.value === '1' || this.value === true || this.value === 1
    }
  },
  methods: {
    change(e) {
      this.$emit('input', !!e.target.checked)
      this.$emit('change', !!e.target.checked)
    }
  }
}
</script>

<style></style>
