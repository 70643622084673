'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import meterPieceService from '@/features/meter-piece/service'

const busyMutateName = 'setMeterPiecesBusy'

export default {
  namespaced: true,
  state: {
    meterPieceList: [],
    meterPieceFilter: '',
    meterPieceIsBusy: false,
    meterPieceErr: undefined,
    meterPieceTotalRows: 0,
    meterPieceCurrentPage: 1,
    meterPiecePerPage: 10,
    meterPieceLastPage: 1
  },
  actions: {
    async listMeterPieces({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await meterPieceService().list(page, perPage, text)
          setState(commit, 'anomalyTypeFilter', text)
          setState(commit, 'meterPieceTotalRows', payload.total)
          setState(commit, 'meterPieceCurrentPage', payload.page)
          setState(commit, 'meterPiecePerPage', payload.perPage)
          setState(commit, 'meterPieceLastPage', payload.lastPage)

          setState(commit, 'meterPieceList', payload.data)
        }
      )
    },
    async showMeterPiece({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return meterPieceService().show(id)
        }
      )
    },
    async createMeterPiece({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = meterPieceService().create(payload)
          commit('insertMeterPieceInList', entry)
        }
      )
    },
    async updateMeterPiece({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = meterPieceService().update(payload)
          commit('updateMeterPieceInList', entry)
          return entry
        }
      )
    },
    async deleteMeterPiece({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await meterPieceService().delete(id)
          if (result) await commit('removeMeterPieceInList', id)
          return result
        }
      )
    }
  },
  getters: {
    meterPieceErr(state) {
      return state.meterPieceErr
    },
    filteredMeterPieces(state) {
      return filterElements(state.meterPieceList, state.filter)
    }
  },
  mutations: {
    mutate,
    cleanErrors(state, value) {
      state.meterPieceErr = undefined
    },
    setMeterPiecesBusy(state, value = false) {
      state.meterPieceIsBusy = value
    },
    insertMeterPieceInList(state, payload) {
      state.meterPieceList.unshift(payload)
    },
    removeMeterPieceInList(state, payload) {
      state.meterPieceList = state.meterPieceList.filter(
        meterPiece => meterPiece.id !== payload
      )
    },
    updateMeterPieceInList(state, payload) {
      if (state.meterPieceList) {
        state.meterPieceList = state.meterPieceList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
