<template>
  <span>
    <PermissiveBtn
      :size="size"
      title="Adicionar Entrada"
      titleBlocked="Sem permissão para editar"
      :can="can"
      @click="click"
      :disabled="disabled"
      :variant="variant"
      v-b-tooltip.hover
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    >
      Adicionar
    </PermissiveBtn>
  </span>
</template>

<style></style>

<script>
export default {
  name: 'AddBtn',
  props: {
    can: {
      default: null
    },
    click: {
      default: null
    },
    variant: {
      default: 'success'
    },
    size: {
      default: 'xs'
    },
    disabled: {
      default: false
    }
  }
}
</script>
