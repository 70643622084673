<template>
  <div class="mw-100">
    <b-row class="px-2 py-auto">
      <b-col
        class="col-11"
        v-b-tooltip.top
        :title="formattedLabel(value)"
        :disabled="disabled"
      >
        <Multiselector
          track-by="id"
          :custom-label="formattedLabel"
          :hide-selected="false"
          :taggable="false"
          :allowEmpty="true"
          :options="manufactorsList"
          :value="value"
          :disabled="disabled"
          :loading="isBusy"
          selectLabel="Escolher"
          tag-placeholder="Selecione a filial"
          placeholder="Selecione a filial"
          v-bind="{ ...$props, ...$attrs }"
          v-on="$listeners"
        >
          <span slot="noResult">Sem Fabricantes Encontradas</span>
        </Multiselector>
      </b-col>
      <b-row class="col-1 p-0 pl-1">
        <b-btn
          size="xs"
          class="btn-outline-secondary"
          v-b-tooltip.right
          :aria-disabled="disabled"
          :disabled="disabled"
          title="Limpar Seleção"
          @click="clean"
          ><span class="ion ion-md-close"></span
        ></b-btn>
      </b-row>
    </b-row>
  </div>
</template>

<script>
import branchesApi from '@/features/manufactors/manufactors-api'

export default {
  name: 'manufactor-picker',
  props: {
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    emitOnMount: {
      default: true
    }
  },
  data() {
    return {
      selectedEntry: null,
      isBusy: false,
      manufactorsList: []
    }
  },
  async mounted() {
    this.refreshManufactor()
    if (this.emitOnMount) {
      this.$emit('input', this.value)
    }
  },
  methods: {
    clean() {
      this.$emit('value', null)
      this.$emit('input', null)
    },
    formattedLabel(manufactor) {
      return `${manufactor?.name ?? 'NI'} - #${manufactor?.id ?? 'ID'}`
    },
    async refreshManufactor() {
      this.isBusy = true
      try {
        const response = await branchesApi().list()
        this.manufactorsList = response.data
      } catch (error) {
        this.isBusy = false
      }
      this.isBusy = false
    }
  }
}
</script>
