/**
 * Generates a new verification digit from trs
 * @param {array|number|string} digits Array with all seal digits
 * @param {number} sumDigit Digit used to sum in check digit calc
 */
function generateCheckDigit(digits, sumDigit = 4) {
  if (!Array.isArray(digits)) {
    digits = digits.toString().split('')
  }

  let currentPosition = digits.length + 1

  function multiplyByPosition(value) {
    return value * currentPosition--
  }

  function sumPreviousWithCurrent(previous, current) {
    return (previous += current)
  }

  const codArray = digits.map(multiplyByPosition)

  return ((codArray.reduce(sumPreviousWithCurrent) + sumDigit) % 11) % 10
}

module.exports = generateCheckDigit
