import { isNil } from 'lodash'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {
  mounted() {
    this.cleanErrors()
  },
  methods: {
    ...mapMutations('authentication', [
      'setLoginUser',
      'setLoginPass',
      'setLoginBranch',
      'cleanErrors'
    ]),
    ...mapActions('authentication', [
      'doLogout',
      'doLogin',
      'fetchBranches',
      'fetchUserInfo'
    ]),
    async authenticate() {
      await this.doLogin()
    },
    isAnotherUser(userObject) {
      if (!isNil(userObject) && !isNil(userObject.id)) {
        return !this.isCurrentlyUser(userObject)
      }
      return false
    },
    isCurrentlyUser(userObject) {
      if (userObject && userObject?.id) {
        return this.isCurrentlyUserId(userObject?.id)
      }
      return false
    },
    isCurrentlyUserId(userId) {
      if (userId) {
        if (userId === this?.userInfo?.id) {
          return true
        }
      }
      return false
    }
  },
  computed: {
    ...mapState('authentication', [
      'username',
      'password',
      'branches',
      'allowedBranches',
      'branchActive',
      'loginBusy',
      'userInfo'
    ]),
    ...mapGetters('authentication', [
      'isAuthenticated',
      'userName'
    ])
  }
}
