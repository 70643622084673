export default {
  methods: {
    showErrorModal: function (props) {
      const {
        title = 'Falha !!!',
        text = '',
        cancelButtonText = 'Cancelar'
      } = props ?? {}
      return this.$swal(
        Object.assign(props, {
          title: title,
          text: text,
          icon: 'error',
          cancelButtonText: cancelButtonText
        })
      )
    }
  }
}
