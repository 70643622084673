'use strict'

import expertModeService from '@/features/expert-modes/expert-mode-api'
import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'
import { isNil } from 'lodash'

import reportPreviewsApi from '@/features/report-previews/report-previews-api'
const busyMutateName = 'setExpertModesBusy'

export default {
  namespaced: true,
  state: {
    expertModeList: [],
    expertModeFilter: '',
    expertModeIsBusy: false,
    expertModeTotalRows: 0,
    expertModeCurrentPage: 1,
    expertModePerPage: 10,
    expertModeLastPage: 0,
    lastFetchedHTML: null,
    service_id: null,
    service_meter_serial_id: null,
    force_refresh: false,
    show_signature: true
  },
  actions: {
    async listExpertModes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await expertModeService().list(page, perPage, text)
        setState(commit, 'expertModeTotalRows', payload.total)
        setState(commit, 'expertModeCurrentPage', payload.page)
        setState(commit, 'expertModePerPage', payload.perPage)
        setState(commit, 'expertModeLastPage', payload.lastPage)

        commit('setExpertModesList', payload.data)
      })
    },

    async fetchExpertModeValues({ state, commit }, serviceMeterSerialId) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return expertModeService().fetchServiceSerialExpertMode(serviceMeterSerialId)
      })
    },
    async finishMeterActiveExpertMode({ state, commit }) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return expertModeService().finishInspection(state.service_meter_serial_id)
      })
    },
    async openServiceMeter({ state, commit }, serviceMeterSerialId) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return expertModeService().openServiceMeter(serviceMeterSerialId)
      })
    },
    async showExpertMode({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return expertModeService().show(id)
      })
    },
    async createExpertMode({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = expertModeService().create(payload)
        commit('unshiftExpertMode', entry)
      })
    },
    async updateExpertMode({ state, commit }, entity) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await expertModeService().update(entity)
        console.log('updated in list', entry)
        commit('updateExpertModeInList', entry)
        return entry
      })
    },
    async deleteExpertMode({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await expertModeService().delete(id)
        if (result) await commit('removeExpertModeInList', id)
        return result
      })
    },
    async generatePhrases({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const phrase = expertModeService().generateDescription(payload)
        return phrase
      })
    },
    async updateReportPreviewInfo({ state, commit, dispatch }, payload) {
      console.log('updateReportPreviewInfo', payload)
      commit('updateMeterInspectionData', payload)
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        if (payload?.html) {
          commit('setPreviewHTML', payload?.html)
        } else {
          dispatch('fetchActiveReportInfo')
        }
      })
    },
    async fetchActiveReportInfo({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        try {
          const html = await reportPreviewsApi().show({
            service_id: state.service_id,
            service_meter_serial_id: state.service_meter_serial_id,
            show_signature: true,
            force_refresh: state.force_refresh
          })
          if (html) commit('setPreviewHTML', html)
        } catch (err) {
          if (err?.response?.status === 422) {
            commit(
              'setPreviewHTML',
              `<span style="color:red"> ${err?.response?.data?.error?.message ??
                err?.response?.data?.message ??
                'Falha durante geração da prévia contate o administrador do sistema.'} <span>`
            )
          } else {
            throw err
          }
        }
      })
    },
    async fetchLastPreviewHtml({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, ({ commit, state, busyMutateName }) => {
        return reportPreviewsApi().show({
          service_id: state.service_id,
          service_meter_serial_id: state.service_meter_serial_id,
          show_signature: true,
          force_refresh: state.force_refresh
        })
      })
    }
  },
  getters: {
    filteredExpertModes(state) {
      return filterElements(state.expertModeList, state.filter)
    },
    previewHTML: (state) =>
      state.lastFetchedHTML ??
      '<span style="color:red"> DADOS VAZIOS (recarregue ou clique no botão atualizar acima) <span>',
    activeServiceId: (state) => state.service_id ?? null
  },
  mutations: {
    mutate,
    updateMeterInspectionData(state, payload) {
      const { service_id, service_meter_serial_id, force_refresh, show_signature } = payload ?? {}
      console.error('updateMeterInspectionData', payload)
      if (!isNil(service_meter_serial_id)) state.service_meter_serial_id = service_meter_serial_id
      if (!isNil(service_id)) state.service_id = service_id
      if (!isNil(force_refresh)) state.force_refresh = force_refresh
      if (!isNil(show_signature)) state.show_signature = show_signature
    },
    setPreviewHTML(state, html) {
      state.lastFetchedHTML =
        html.replace('zoom: 77%', 'zoom: 130%') ??
        '<span style="color:red"> DADOS VAZIOS (recarregue ou clique no botão atualizar acima) <span>'
    },
    setExpertModesList(state, payload) {
      state.expertModeList = payload
    },
    setExpertModesFilter(state, payload) {
      state.expertModeList = payload
    },
    [busyMutateName](state, value = false) {
      state.expertModeIsBusy = value
    },
    unshiftExpertMode(state, payload) {
      state.expertModeList.unshift(payload)
    },
    removeExpertModeInList(state, payload) {
      state.expertModeList = state.expertModeList.filter((expertMode) => expertMode.id !== payload)
    },
    updateExpertModeInList(state, payload) {
      if (state.expertModeList) {
        state.expertModeList = state.expertModeList.map((val) => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
