'use strict'

import serviceApi from '@/features/service-types/service-types-api'
import { optOp } from '@/helpers/operators'
import { setState, mutate, isBusy } from '@/helpers/store-helpers'
function currentTypeLowered(state) {
  return optOp(optOp(state, 'currentType'), 'name', '').toLowerCase()
}
function currentTypeDesc(state) {
  return optOp(
    optOp(state, 'currentType'),
    'description',
    '{nome-tipo-servico}'
  )
}
// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    service_list: [],
    services: [],
    currentType: null
  },
  actions: {
    async createService({ state, commit }, payload) {
      const created = await serviceApi().get(payload)
      commit('insertService', created)
    },
    async getServiceType({ state, commit }, id) {
      commit('isBusy')
      try {
        const typeFound = await serviceApi().get(id)
        setState(commit, 'currentType', typeFound)
      } catch (e) {
        console.error(e)
        commit('isBusy', false)
        throw e
      } finally {
        commit('isBusy', false)
      }
    }
  },
  getters: {
    totalServices(state) {
      return (state.services || []).length
    },
    errorMessage(state) {
      return state.errMessage
    },
    read_servicePerm(state) {
      try {
        return `read_${currentTypeLowered(state)}_service`
      } catch (e) {
        console.warn(e)
        return '--'
      }
    },
    create_servicePerm(state) {
      try {
        return `create_${currentTypeLowered(state)}_service`
      } catch (e) {
        console.warn(e)
        return '--'
      }
    },
    delete_servicePerm(state) {
      try {
        return `delete_${currentTypeLowered(state)}_service`
      } catch (e) {
        console.warn(e)
        return '--'
      }
    },
    update_servicePerm(state) {
      try {
        return `update_${currentTypeLowered(state)}_service`
      } catch (e) {
        console.warn(e)
        return '--'
      }
    },
    serviceTypeName(state) {
      try {
        return currentTypeDesc(state)
      } catch (error) {
        console.warn(error)
        return '{tipo-servico}'
      }
    },
    filteredServices(state) {
      // filter our data
      if (state.service_list) {
        const filtered = state.service_list.filter(service => {
          // Concat data
          if (service) {
            return (
              Object.keys(service)
                .map(k => String(service[k]))
                .join('|')
                .toLowerCase()
                .indexOf(state.filter) !== -1 || !state.filter
            )
          } else return null
        })
        // update the rows
        return filtered
      } else {
        return []
      }
    },

    hasNewServices(state) {
      let check
      try {
        if (this.isFirstTime) {
          check = undefined
        } else {
          check = state.services.length > 3
        }
      } catch (e) {
        return false
      }
      return check
    }
  },
  mutations: {
    mutate,
    isBusy,
    filterService(state, filter) {
      state.filter = filter.toLowerCase()
    },
    removeFromList(state, payload) {
      state.service_list = state.service_list.filter(
        value => value.id !== payload
      )
    },
    insertService(state, data) {
      state.service_list.push(data)
    },
    setInitial(state, service) {
      state.initialService = service
    },
    setFinal(state, service) {
      state.finalService = service
    },
    updateListRef(state, update) {
      if (state.service_list) {
        state.service_list = state.service_list.map(val => {
          if (val.id === update.id) {
            return update
          }
          return val
        })
      }
    },
    updateServices(state, services) {
      state.service_list = services || []
    },
    resetServicesState(state, _) {
      state.initialService = null
      state.finalService = null
    }
  }
}
