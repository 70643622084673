<template>
  <b-btn variant="default" v-bind="{ ...$props, ...$attrs }" v-on="$listeners" title="Limpar"
    ><span class="ion ion-md-close"></span
  ></b-btn>
</template>

<script>
export default {}
</script>

<style></style>
