import HttpClient from '@/services/http-client'

class RolesAPI extends HttpClient {
  async list(opts = {}) {
    const { page = 1, perPage = 10, text = '' } = opts || {}
    return (
      await this.http.get('/v1/roles', {
        params: {
          page,
          perPage,
          text
        }
      })
    ).data
  }

  async get(id) {
    return (await this.http.get('/v1/roles/' + id)).data
  }

  async store(payload) {
    return (await this.http.post('/v1/roles', payload)).data
  }

  async delete(id) {
    return (await this.http.delete('/v1/roles/' + id)).data
  }

  async update(payload) {
    return this.rest.patch('/v1/roles/' + payload.id, payload)
  }
}

export default () => new RolesAPI()
