'use strict'

import clientsApi from '@/features/clients/clients-api'
import { filterElements, setState, mutate } from '@/helpers/store-helpers'

// Thi store depends from some plugins like CASL in Vue instance

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    isBusy: false,
    clients: [],
    filter: ''
  },
  actions: {
    async fetchClients({ state, commit }) {
      setState(commit, 'isBusy', true)
      try {
        const allClients = await clientsApi().list()
        setState(commit, 'clients', allClients)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        setState(commit, 'isBusy', false)
      }
    },
    async storeClient({ state, commit }, payload) {
      setState(commit, 'isBusy', true)
      try {
        const newClient = await clientsApi().store(payload)
        commit('addClient', newClient)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        setState(commit, 'isBusy', false)
      }
    },
    async updateClient({ state, commit }, payload) {
      setState(commit, 'isBusy', true)
      try {
        const allClients = await clientsApi().update(payload)
        commit('updateClient', allClients)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        setState(commit, 'isBusy', false)
      }
    },
    async getClient({ state, commit }, id) {
      setState(commit, 'isBusy', true)
      try {
        const client = await clientsApi().get(id)
        setState(commit, 'isBusy', false)
        return client
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        setState(commit, 'isBusy', false)
      }
    },
    async deleteClient({ state, commit }, id) {
      setState(commit, 'isBusy', true)
      try {
        const client = await clientsApi().delete(id)
        commit('deleteClientID', id)
        setState(commit, 'isBusy', false)
        return client
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        setState(commit, 'isBusy', false)
      }
    }
  },
  mutations: {
    // needed to setstate
    mutate,
    isBusy(state, val = false) {
      state.meterBusy = val
    },
    addClient(state, client) {
      state.clients = state.clients.slice(state.clients.length, 0, client)
    },
    updateClient(state, updated) {
      const updatedListing = state.clients.map(client => {
        if (client.id === updated.id) {
          return updated
        }
        return client
      })
      state.clients = updatedListing
    },
    deleteClientID(state, id) {
      state.clients = state.clients.filter(client => {
        return client.id !== id
      })
    },

    filterClients(state, filter) {
      state.filter = filter.toLowerCase()
    }
  },
  getters: {
    totalClients(state) {
      return (state.clients || []).length
    },
    filteredClients(state) {
      return filterElements(state.clients, state.filter)
    }
  }
}
