/**
 * A store deve se encontrar seguindo a seguinte estrutura
 * pasta com nome kebab case `minha-store`
 * possuir um arquivo `index.js` dentro da pasta
 * possuir um arquivo `mixin.js `
 * o arquivo `index.js` será carregado seguinto o nome da pasta em camel case
 * exemplo
 * `minha-store/index.js`
 * será carregado como
 *
 * ```javascript
 * const minhaStore = require('./minha-store/index.js')
 * const modules = [ minhaStore ]
 * ```
 * @returns {Array} array
 */
export function loadStoreModules() {
  const loadedStores = {}
  const regexMatchStoreIndex = /([\w-]+)\/store\/index.js/im // => casa com o seguinte  `palavra-casada/index.js`
  const scriptFiles = require.context(
    '../features/',
    true,
    /store\/index\.(js)$/i
  )

  const storeIndexFiles = scriptFiles
    .keys()
    .filter(filename => regexMatchStoreIndex.test(filename))

  // Para cada script de rota encontrada faça o mapeamento
  for (const storeFiles of storeIndexFiles) {
    const name = storeFiles.match(regexMatchStoreIndex)[1]
    const kebabCaseName = name
      .split(/[-_]/im)
      .reduce((fullWord, currWord, wordIdx, all) => {
        if (fullWord.length) {
          return (
            fullWord +
            currWord.substring(0, 1).toUpperCase() +
            currWord.substring(1)
          )
        } else {
          return fullWord + currWord
        }
      }, '')
    // const object = {
    //   name: name,
    //   default: scriptFiles(key).default
    // }
    loadedStores[kebabCaseName] = scriptFiles(storeFiles).default
  }

  console.log('[PRELOAD STORES]', storeIndexFiles.length)
  return loadedStores
}
