import authApi from '@/features/authentication/auth-api'
import branchesApi from '@/features/branches/branches-api'
import userApi from '@/features/users-editor/user-api'
import { optOp } from '@/helpers/operators'

import { mutate, setState } from '@/helpers/store-helpers'
import router from '@/router'

// Thi store depends from some plugins like CASL in Vue instance
import Vue from 'vue'

import { AbilityBuilder } from '@casl/ability'

export default {
  namespaced: true,
  state: {
    branches: [],
    allowedBranches: [],
    username: null,
    password: null,
    userInfo: {
      name: 'deslogado'
    },
    branchActive: { name: 'Não Associado', id: 1 },
    credentials: null,
    loginBusy: false
  },
  actions: {
    async fetchUserInfo({ state, commit }) {
      const userInfo = await userApi().getUserInfo()

      const { can, rules } = new AbilityBuilder()

      can(userInfo.permissions_slugs)
      Vue.prototype.$ability.update(rules)
      setState(commit, 'userInfo', userInfo)
      return userInfo
    },
    async doLogin({ state, commit }) {
      try {
        commit('cleanErrors')
        commit('loginBusy', true)
        const payload = {
          username: state.username,
          password: state.password
        }

        if (state.branchActive) {
          payload.branch_id = state.branchActive.id
        }

        const credentials = await authApi().login(payload)
        commit('setCredentials', credentials)
        commit('setUserInfo', credentials.details)
        const userInfo = await userApi().getUserInfo()
        setState(commit, 'userInfo', userInfo)
        router.push('/')
      } catch (e) {
        commit('loginBusy', false)
        throw e
      }
    },
    async doLogout({ state, commit }) {
      await commit('setCredentials', null)
      router.push('/login')
    },
    async fetchBranches({ state, commit }) {
      const branches = await branchesApi().list()
      setState(commit, 'branches', branches)
      const allowedBranches = await branchesApi().getAllowed()
      setState(commit, 'allowedBranches', allowedBranches)
    }
  },
  getters: {
    isAuthenticated(state) {
      const token = localStorage.getItem('token')
      if (token !== null && token !== undefined && token !== 'null') {
        return true
      } else return false
    },
    userName(state) {
      return optOp(optOp(state, 'userInfo'), 'name') || 'Deslogado'
    },
    userId(state) {
      return optOp(optOp(state, 'userInfo'), 'id') || 'Deslogado'
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    markFoundError(state, message) {
      state.errMessage = message
    },
    setLoginBranch(state, branch) {
      if (!branch) branch = state.branches[0]
      state.branchActive = branch
    },
    setCredentials(state, newCredential) {
      if (newCredential && newCredential.token && newCredential.type) {
        const token = `${newCredential.type} ${newCredential.token}`
        localStorage.setItem('token', token)
        state.credentials = newCredential
      } else {
        state.credentials = newCredential
        localStorage.setItem('token', null)
      }
    },
    setLoginUser(state, user) {
      state.username = user
    },
    setLoginPass(state, pass) {
      state.password = pass
    },
    loginBusy(state, value) {
      state.loginBusy = value
    }
  }
}
