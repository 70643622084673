var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-select', _vm._g(_vm._b({
    attrs: {
      "id": _vm.id,
      "options": [{
        text: 'Aprovado',
        value: 1
      }, {
        text: 'Reprovado',
        value: 0
      }, {
        text: 'Não Aplicável',
        value: null
      }]
    }
  }, 'b-select', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }