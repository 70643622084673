<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
export default {
  name: 'app',
  metaInfo: {
    title: 'LAB',
    titleTemplate: '%s - 3CServices'
  },
  updated() {
    // Remove loading state
    document.getElementsByClassName('app-splash-screen')[0].style.display =
      'none'
    document.body.classList.remove('app-loading')
  }
}
</script>
