var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('PermissiveBtn', _vm._g(_vm._b({
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "can": _vm.can,
      "size": "xs",
      "variant": "warning",
      "title": "Apagar Entrada",
      "titleBlocked": "Sem permissão para apagar",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.click
    }
  }, 'PermissiveBtn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._v(" Remover ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }