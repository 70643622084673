'use strict'
// Thi store depends from some plugins like CASL in Vue instance

import { mutate, doAsyncBusyTask, setState } from '@/helpers/store-helpers'
import testTable from '@/features/test-table/test-table-api'

export default {
  namespaced: true,
  state: {
    testTableList: [],
    // pagination: {
    currentTestTablePage: 1,
    // }
    testTableBusy: false,
    testTableErr: undefined
  },
  actions: {
    async listTestTable({ state, commit }, { page, perPage, text }) {
      try {
        commit('setIsBusy', true)
        const { data } = await testTable().list(page, perPage, text)
        setState(commit, 'testTableList', data)
      } catch (e) {
        commit('setIsBusy')
        throw e
      } finally {
        commit('setIsBusy')
      }
    },
    async updateTestTable({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state }, async ({ state, commit }) => {
        const updated = await testTable().update(payload)
        if (state.testTableList) {
          setState(
            commit,
            'testTableList',
            state.testTableList.map(testTable => {
              if (testTable.id === updated.id) {
                return updated
              }
              return testTable
            })
          )
        }
        return updated
      })
    },
    async showTestTable({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state }, async ({ state, commit }) => {
        return testTable().show(id)
      })
    },
    async createTestTable({ state, commit }, data) {
      return doAsyncBusyTask({ commit, state }, async ({ state, commit }) =>
        testTable().create(data)
      )
    },
    async deleteTestTable({ state, commit }, tableid) {
      return doAsyncBusyTask({ commit, state }, async ({ state, commit }) => {
        const result = await testTable().delete(tableid)
        console.warn('delete testTable', result)
        if (result) {
          setState(
            commit,
            'testTableList',
            state.testTableList.filter(testTable => {
              return testTable.id !== tableid
            })
          )
        }
        return result
      })
    }
  },
  getters: {
    testTableErr(state) {
      return state.testTableErr
    },
    filteredTestTables(state) {
      return state.testTableList
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.testTableErr = undefined
    },
    setIsBusy(state, value = false) {
      state.testTableBusy = value
    }
  }
}
