var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', _vm._g(_vm._b({
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    ref: "stampbutton",
    attrs: {
      "size": "md",
      "title": "Você não possui permissão para esta ação",
      "variant": 'secondary ' + _vm.$attrs.variant
    }
  }, 'b-button', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }