'use strict'
// Thi store depends from some plugins like CASL in Vue instance
import meterSealsApi from '@/features/seals-editor/seals-api'
import { setState, filterElements, mutate } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    busy: false,
    seals: [],
    manufactors: [],
    rated_voltages: [],
    meterError: undefined
  },
  actions: {
    async fetchSeals({ state, commit }, payload = {}) {
      await commit('isBusy', true)
      try {
        const seals = await meterSealsApi().list(payload ?? {})

        commit('setSeals', seals)
      } catch (e) {
        console.error(e)
        commit('mutate', { key: 'meterError', value: e.toString() })
      } finally {
        commit('isBusy', false)
      }
    },
    async updateSeal({ state, commit }, meterSeal) {
      try {
        const data = await meterSealsApi().updateSeal(meterSeal)
        commit('updateSeal', data)
      } catch (error) {
        console.log(error)
      }
    },
    async switchDamaged({ state, commit }, item) {
      try {
        const data = await meterSealsApi().setDamaged(item.id, !item.is_damaged)

        commit('updateSeal', data)
      } catch (error) {
        console.log(error)
      }
    },

    async deleteSeal({ state, commit }, meterSeal) {
      commit('isBusy', true)
      try {
        const data = await meterSealsApi().deleteSeal(meterSeal.id)
        commit('removeSeal', meterSeal)

        console.log('Fetched', data)
      } catch (error) {
        commit('showError', error.message)
        console.log(error)
      } finally {
        commit('isBusy', false)
      }
    },
    async openMeterEditor({ state, commit }, meter) {
      try {
        setState(commit, 'meterToEdit', meter)
      } catch (error) {
        console.log(error)
      }
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    isBusy(state, val = false) {
      state.busy = val
    },
    setSeals(state, seals) {
      state.seals = seals.data
      state.page = seals.page
      state.perPage = seals.perPage
      state.sealstotal = seals.total
    },
    addSeal(state, seal) {
      state.seals.push(seal)
    },
    updateSeal(state, seal) {
      const updatedSeal = state.seals.map(val => {
        if (val.id === seal.id) {
          return seal
        }
        return val
      })
      state.seals = updatedSeal
    },
    removeSeal(state, seal) {
      state.seals = state.seals.filter(met => met.id !== seal.id)
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalItems(state) {
      return state.seals.length || 0
    },
    selectedMeter(state) {
      return state.selected
    },

    sheetFields(state) {
      return {}
    },
    sealsFields(state) {
      return [
        {
          key: 'name',
          sortable: true,
          label: 'Nome'
        },
        {
          key: 'seal',
          sortable: true,
          label: 'Selo'
        },
        {
          label: 'Fabricante',
          sortable: true,
          key: 'manufactor.name'
        },
        {
          label: 'Classe',
          sortable: true,
          key: 'class.name'
        },
        {
          key: 'wires',
          sortable: true,
          label: 'Fios'
        },
        {
          label: 'Tensões Nominais',
          sortable: true,
          key: 'ratedVoltages',
          formatter: ratedVoltages =>
            ratedVoltages.map(val => val.name).join(' , ')
        },
        {
          label: 'Opções',
          sortable: true,
          key: 'edit'
        }
      ]
    },
    filteredSeals(state) {
      // filter our data
      return filterElements(state.seals, state.filter)
    }
  }
}
