var render = function () {
  var _vm$regulatoryOrdinan,
    _this = this,
    _vm$value,
    _vm$value2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "px-2 py-auto"
  }, [_c('b-col', {
    staticClass: "mw-100"
  }, [_c('Multiselector', _vm._g(_vm._b({
    staticClass: "mw-100",
    attrs: {
      "track-by": "id",
      "label": "number",
      "custom-label": _vm.ordinanceText,
      "hide-selected": false,
      "taggable": true,
      "allowEmpty": true,
      "options": (_vm$regulatoryOrdinan = _vm.regulatoryOrdinanceList) !== null && _vm$regulatoryOrdinan !== void 0 ? _vm$regulatoryOrdinan : [],
      "value": _vm.value,
      "disabled": _vm.disabled,
      "selectLabel": "Escolher",
      "selectedLabel": "Selecionado",
      "tag-placeholder": "Escolha uma opção",
      "placeholder": "Escolha uma opção"
    },
    on: {
      "search-change": _vm.onSearchModel,
      "tag": _vm.onTag,
      "select": _vm.onSelectModel,
      "remove": _vm.onRemoveModel
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1), _c('b-row', [_c('b-btn-group', {
    staticClass: "btn-group mr-1"
  }, [_c('Can', {
    attrs: {
      "I": "create_regulatory_ordinances"
    }
  }, [_c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "slot": "append",
      "id": "create-ordinance",
      "size": "sm",
      "variant": "primary icon-btn",
      "title": "Cadastrar Nova"
    },
    on: {
      "click": function click() {
        var _this$$refs, _this$$refs$ordinance;
        // eslint-disable-next-line vue/no-mutating-props
        _this.value = null;
        (_this$$refs = _this.$refs) === null || _this$$refs === void 0 ? void 0 : (_this$$refs$ordinance = _this$$refs.ordinanceModal) === null || _this$$refs$ordinance === void 0 ? void 0 : _this$$refs$ordinance.show();
      }
    },
    slot: "append"
  }, [_c('span', {
    staticClass: "ion ion-md-add-circle"
  })])], 1), _c('Can', {
    attrs: {
      "I": "update_regulatory_ordinances"
    }
  }, [_c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "id": "update-ordinance",
      "size": "sm",
      "disabled": !((_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.id),
      "variant": "primary icon-btn bg-periscope",
      "title": "Editar Portaria"
    },
    on: {
      "click": function click() {
        var _this$$refs2, _this$$refs2$ordinanc, _this$$refs3, _this$$refs3$ordinanc;
        (_this$$refs2 = _this.$refs) === null || _this$$refs2 === void 0 ? void 0 : (_this$$refs2$ordinanc = _this$$refs2.ordinanceModal) === null || _this$$refs2$ordinanc === void 0 ? void 0 : _this$$refs2$ordinanc.show();
        (_this$$refs3 = _this.$refs) === null || _this$$refs3 === void 0 ? void 0 : (_this$$refs3$ordinanc = _this$$refs3.ordinanceEditor) === null || _this$$refs3$ordinanc === void 0 ? void 0 : _this$$refs3$ordinanc.fetch();
      }
    }
  }, [_c('span', {
    staticClass: "ion ion-ios-create"
  })])], 1)], 1), _c('b-modal', {
    ref: "ordinanceModal",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top",
      "hide-footer": ""
    }
  }, [_c('OrdinanceEditor', {
    ref: "ordinanceEditor",
    attrs: {
      "entity-id": (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.id
    },
    on: {
      "onGoBack": function onGoBack(ordinance) {
        var _this$$refs4, _this$$refs4$ordinanc;
        (_this$$refs4 = _this.$refs) === null || _this$$refs4 === void 0 ? void 0 : (_this$$refs4$ordinanc = _this$$refs4.ordinanceModal) === null || _this$$refs4$ordinanc === void 0 ? void 0 : _this$$refs4$ordinanc.hide();
        _this.listRegulatoryOrdinances({});
        // eslint-disable-next-line vue/no-mutating-props
        _this.value = ordinance;
        _this.onSelectModel(ordinance);
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }