var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "label": "Medidores em uso:"
    }
  }, [_c('AddBtn', {
    on: {
      "click": _vm.addMeterModel
    }
  }), _c('CTable', {
    staticClass: "table-responsive",
    staticStyle: {
      "max-height": "400px"
    },
    attrs: {
      "bordered": "",
      "striped": "",
      "responsive": "",
      "sticky-header": "700px",
      "fields": [{
        key: 'id',
        label: 'ID'
      }, {
        key: 'name',
        label: 'Nome'
      }, {
        key: 'actions',
        label: 'Ações'
      }],
      "items": _vm.value
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatMetterInfo(data.item)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('OpenBtn', {
          attrs: {
            "can": "read_meter_models"
          },
          on: {
            "click": function click($event) {
              return _vm.openMeterModel(data.item);
            }
          }
        }), _c('RemoveBtn', {
          attrs: {
            "can": "update_manufactors"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteMeterModel(data);
            }
          }
        })];
      }
    }])
  }), _c('b-modal', {
    ref: "meterModelSelector",
    attrs: {
      "size": "xs",
      "modal-class": "modal-fillin"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Cancelar ")]), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": _vm.isValidMeter ? 'success' : 'secondary'
          },
          on: {
            "click": function click($event) {
              _vm.isValidMeter ? _vm.finishMeterAdd(ok) : function () {};
            }
          }
        }, [_vm._v(" Selecionar medidor ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_vm._v(" Selecionar Modelo de "), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("Medidor")]), _c('br'), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Busque o modelo que será adicionado a lista")])]), _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('MeterModelPicker', {
    staticClass: "multiselect-primary row-12 mb-0 small",
    attrs: {
      "track-by": "id",
      "label": "model_name_for_user_listing",
      "state": _vm.isValidField('value'),
      "max-height": 700,
      "options-limit": 1000,
      "searchable": true,
      "multiple": false,
      "hide-selected": false,
      "taggable": false,
      "allowEmpty": false,
      "notIncludeIds": _vm.notIncludeIds,
      "deselect-label": "Remover seleção",
      "selectLabel": "Escolher",
      "selectedLabel": "Selecionado",
      "tag-placeholder": "Escolha um modelo da Lista",
      "placeholder": "Selecione o modelo de medidor",
      "size": "sm"
    },
    model: {
      value: _vm.meterModelSelected,
      callback: function callback($$v) {
        _vm.meterModelSelected = $$v;
      },
      expression: "meterModelSelected"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }