/* eslint-disable no-undef */
export default (Vue) => {
  Caman.Pixel.prototype.coordinatesToLocation = Caman.Pixel.coordinatesToLocation

  Caman.Pixel.prototype.locationToCoordinates = Caman.Pixel.locationToCoordinates

  Caman.Pixel.prototype.putPixelRelative = function (horiz, vert, rgba) {
    if (this.c == null) {
      throw Error('Requires a CamanJS context')
    }
    const newLoc = this.loc + this.c.dimensions.width * 4 * (vert * -1) + 4 * horiz
    if (newLoc > this.c.pixelData.length || newLoc < 0) {
      return
    }
    this.c.pixelData[newLoc] = rgba.r
    this.c.pixelData[newLoc + 1] = rgba.g
    this.c.pixelData[newLoc + 2] = rgba.b
    this.c.pixelData[newLoc + 3] = rgba.a
    return true
  }

  Caman.Pixel.prototype.putPixel = function (x, y, rgba) {
    if (this.c == null) {
      throw new Error('Requires a CamanJS context')
    }
    const loc = this.coordinatesToLocation(x, y, img.width)
    this.c.pixelData[loc] = rgba.r
    this.c.pixelData[loc + 1] = rgba.g
    this.c.pixelData[loc + 2] = rgba.b
    this.c.pixelData[loc + 3] = rgba.a
    return true
  }

  Caman.Blender.register('maxrgb', function (rgbaLayer, rgbaParent) {
    return {
      r: rgbaParent.r > 128 ? 255 : rgbaParent.r - rgbaLayer.r,
      g: rgbaParent.g > 128 ? 255 : rgbaParent.g - rgbaLayer.g,
      b: rgbaParent.b > 128 ? 255 : rgbaParent.b - rgbaLayer.b
    }
  })

  Caman.Blender.register('minrgb', function (rgbaLayer, rgbaParent) {
    return {
      r: rgbaParent.r < 128 ? rgbaParent.r + rgbaLayer.r : 0,
      g: rgbaParent.g < 128 ? rgbaParent.g + rgbaLayer.r : 0,
      b: rgbaParent.b < 128 ? rgbaParent.r + rgbaLayer.r : 0
    }
  })
  Caman.Filter.register('emboss', function () {
    this.processKernel('Emboss', [-2, -1, 0, -1, 1, 1, 0, 1, 2])
  })

  Caman.Filter.register('threshold', function (limit) {
    this.process('threshold', function (rgba) {
      const luminance = 0.2126 * rgba.r + 0.7152 * rgba.g + 0.0722 * rgba.b
      // quando for muito claro  torne mais claro ainda senão transforme em preto
      rgba.r = luminance > limit ? 255 : rgba.r
      rgba.g = luminance > limit ? 255 : rgba.g
      rgba.b = luminance > limit ? 255 : rgba.b
    })
    return this
  })
}
