import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  mounted() {},
  methods: {
    ...mapActions('testTable', [
      'listTestTable',
      'showTestTable',
      'deleteTestTable',
      'createTestTable',
      'updateTestTable'
    ]),
    ...mapMutations('testTable', [
      'cleanErrors',
      'markFoundError',
      'setMeterImage',
      'setMeterThumb'
    ])
  },
  computed: {
    ...mapState('testTable', [
      'testTableList',
      'testTableBusy',
      'currentTestTablePage'
    ]),
    ...mapGetters('testTable', ['testTableErr', 'filteredTestTables'])
  }
}
