import HttpClient from '@/services/http-client'

class Settings extends HttpClient {
  async list(config) {
    const { branchId = null } = config
    return (
      await this.http.get('/v1/settings', {
        params: { branchId }
      })
    ).data
  }

  async update(id, payload) {
    return (await this.http.patch('/v1/settings/' + id, payload)).data
  }

  async show(id) {
    return (await this.http.get('/v1/settings/' + id)).data
  }

  async delete(id) {
    return (await this.http.get('/v1/settings/' + id)).data
  }

  async searchModels(id, { search, filters, page, limit }) {
    return (
      await this.http.get(`/v1/settings/${id}/model-entries`, {
        params: {
          search: search || '',
          page: page || 1,
          filters: filters || [],
          limit: limit || 1000
        }
      })
    ).data
  }

  async reset(id) {
    return (await this.http.get('/v1/settings/' + id + '/reset')).data
  }
}
export default () => new Settings()
