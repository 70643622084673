import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import nProgress from 'nprogress'

// Layouts
import Layout2 from '@/layout/Layout2'
import globals from '@/globals'

import notFound404Route from './routes/not-found-404-route'

import loginRoute from './routes/login'
import { getPagesModules } from './getPagesModules'
Vue.use(Router)
Vue.use(Meta)

const navigationPages = getPagesModules()

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Layout2,
      children: navigationPages
    },
    loginRoute,
    notFound404Route
  ]
})

router.afterEach((to, from) => {
  // On small screens collapse sidenav
  if (
    window?.layoutHelpers &&
    window?.layoutHelpers?.isSmallScreen() &&
    !window?.layoutHelpers?.isCollapsed()
  ) {
    setTimeout(() => {
      window?.layoutHelpers?.setCollapsed(true, true)
    }, 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => {
    next()
  }, 10)
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    nProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // remove loading indicator
  document.body.classList.remove('app-loading')
  // Complete the animation of the route progress bar.
  nProgress.done()
})

export default router
