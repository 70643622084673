import createPersistedState from 'vuex-persistedstate'

import Vue from 'vue'
import Vuex from 'vuex'
import { loadStoreModules } from './loadStoreFromFeatures'

Vue.use(Vuex)

const modules = loadStoreModules()

export default new Vuex.Store({
  strict: true,
  state: {
    envBaseUrl: process.env.BASE_URL ?? 'http://localhost',
    packageVersion: process.env.PACKAGE_VERSION ?? '--.--.--',
    noImageUrl: process.env.NO_IMAGE_URL ?? '/uploads/meters/no-image.png',
    baseAPI: process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_API_URL + '/api' : '/api'
  },
  modules,
  mutations: {},
  actions: {},
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    },
    appUrl: (state) => {
      return state.envBaseUrl
    },
    // used when note have image
    noImageUrl: (state) => {
      return state.noImageUrl
    }
  },
  plugins: [
    createPersistedState({
      paths: ['authentication', 'persistentValues']
    })
  ]
})
