var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', _vm._g(_vm._b({
    attrs: {
      "id": "overlay-background",
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, 'b-overlay', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }