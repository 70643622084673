import HttpClient from '@/services/http-client'

class VirtualSealsLoadsAPI extends HttpClient {
  async list(config) {
    return this.rest.get('/v1/virtual-seals-loads', config)
  }

  async store(payload) {
    return (
      await this.http.post('/v1/virtual-seals-loads', payload, {
        timeout: (payload.range_end - payload.range_start) * 1000
      })
    ).data
  }

  async update(payload) {
    return this.rest.patch('/v1/virtual-seals-loads/' + payload.id, payload)
  }

  async show(id) {
    return this.rest.get('/v1/virtual-seals-loads/' + id)
  }

  async delete(id) {
    return this.rest.delete('/v1/virtual-seals-loads/' + id)
  }
}
export default () => new VirtualSealsLoadsAPI()
