'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import meterGroupService from '@/features/meter-groups/meter-groups-api'

const busyMutateName = 'setMeterGroupsBusy'

export default {
  namespaced: true,
  state: {
    meterGroupList: [],
    meterGroupFilter: '',
    meterGroupIsBusy: false,
    meterGroupTotalRows: 0,
    meterGroupCurrentPage: 1,
    meterGroupPerPage: 10,
    meterGroupLastPage: 0
  },
  actions: {
    async listMeterGroups({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await meterGroupService().list(page, perPage, text)
          setState(commit, 'meterGroupTotalRows', payload.total)
          setState(commit, 'meterGroupCurrentPage', payload.page)
          setState(commit, 'meterGroupPerPage', payload.perPage)
          setState(commit, 'meterGroupLastPage', payload.lastPage)

          commit('setMeterGroupsList', payload.data)
        }
      )
    },
    async showMeterGroup({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return meterGroupService().show(id)
        }
      )
    },
    async createMeterGroup({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = meterGroupService().create(payload)
          commit('unshiftMeterGroup', entry)
        }
      )
    },
    async updateMeterGroup({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = meterGroupService().update(payload)
          commit('updateMeterGroupInList', entry)
          return entry
        }
      )
    },
    async deleteMeterGroup({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await meterGroupService().delete(id)
          if (result) await commit('removeMeterGroupInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredMeterGroups(state) {
      return filterElements(state.meterGroupList, state.filter)
    }
  },
  mutations: {
    mutate,
    setMeterGroupsList(state, payload) {
      state.meterGroupList = payload
    },
    setMeterGroupsFilter(state, payload) {
      state.meterGroupList = payload
    },
    setMeterGroupsBusy(state, value = false) {
      state.meterGroupIsBusy = value
    },
    unshiftMeterGroup(state, payload) {
      state.meterGroupList.unshift(payload)
    },
    removeMeterGroupInList(state, payload) {
      state.meterGroupList = state.meterGroupList.filter(
        meterGroup => meterGroup.id !== payload
      )
    },
    updateMeterGroupInList(state, payload) {
      if (state.meterGroupList) {
        state.meterGroupList = state.meterGroupList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
