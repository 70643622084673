<template>
  <BusyOverlay :show="regulatoryOrdinanceIsBusy">
    <!-- RegulatoryOrdinance -->
    <b-card-header class="with-elements">
      <b-col class="col-md-8">
        <b-row class="mb-2">
          <span class="card-header-title mr-2">
            <h5 class="m-0 mr-2">{{ pageTitle }}</h5>
          </span>
        </b-row>
        <b-row>
          <span class="card-subtitle text-muted mb-2">
            <h5 class="m-0 mr-2">{{ pageSubTitle }}</h5>
          </span>
        </b-row>
      </b-col>
      <b-col class="col-md-4">
        <!-- Dates -->
        <div
          v-if="editing"
          class="row row-bordered row-border-light ui-bordered"
        >
          <div class="col py-1 px-3">
            <div class="text-muted small">Criado em</div>
            <div class="font-weight-semibold">
              {{ createdAt }}
            </div>
          </div>
          <div class="col py-1 px-3">
            <div class="text-muted small">Modificado em</div>
            <div class="font-weight-semibold">
              {{ updatedAt }}
            </div>
          </div>
        </div>
      </b-col>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col class="col-md-6">
          <b-form-group
            label="Número da Portaria (Ex.: 431/2007)"
            class="col-md-12"
          >
            <b-input
              v-model="$v.regulatoryOrdinance.name.$model"
              placeholder="Número da Portaria"
              :state="isValid('regulatoryOrdinance.name')"
            />
            <div
              class="invalid-tooltip"
              v-if="!isValid('regulatoryOrdinance.name', 'minLength')"
            >
              Número da Portaria é muito curto
            </div>
            <div
              class="invalid-tooltip"
              v-if="!isValid('regulatoryOrdinance.name', 'required')"
            >
              Número da Portaria é obrigatório
            </div>
          </b-form-group>
          <b-form-group label="Link para o INMETRO:" class="col-md-12">
            <b-textarea
              v-model="$v.regulatoryOrdinance.inmetro_link.$model"
              rows="4"
              max-rows="10"
            ></b-textarea>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6">
          <MeterModelsSelection
            v-model="regulatoryOrdinance.meterModels"
            :notIncludeIds="
              this?.regulatoryOrdinance?.meterModels?.map(val => val.id) ?? null
            "
          />
        </b-col>
      </b-row>
    </b-card-body>
    <!-- / Ticket settings -->

    <!-- Footer -->
    <b-card-footer>
      <b-btn
        v-if="editing"
        variant="primary"
        class="mr-2"
        @click="saveRegulatoryOrdinance"
        >Atualizar Portaria</b-btn
      >
      <b-btn
        v-if="!editing"
        :variant="this.$v.$invalid ? 'secondary' : 'success'"
        class="mr-2"
        :disable="this.$v.$invalid"
        @click="this.saveRegulatoryOrdinance"
        >Criar Portaria</b-btn
      >
      <b-btn variant="default" @click="closeEdit">Cancelar</b-btn>
    </b-card-footer>
  </BusyOverlay>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import { dateFn } from '@/helpers/table-formatter'

import regulatoryOrdinanceMixin from '@/features/regulatory-ordinances/store/mixin'
import vuelidateStateMixin from '@/mixins/vuelidate-state-mixin'

export default {
  components: {},
  mixins: [regulatoryOrdinanceMixin, vuelidateStateMixin],
  metaInfo: {
    title: 'Portarias Regulatória'
  },
  props: {
    entityId: {
      required: true,
      default: null
    }
  },
  data: () => ({
    allreadyValidated: false,
    regulatoryOrdinance: {
      id: null,
      name: null,
      inmetro_link: null,
      meterModels: []
    },
    editing: false
  }),
  validations: {
    regulatoryOrdinance: {
      name: { required, minLength: minLength(4) },
      inmetro_link: {},
      meterModels: {}
    }
  },
  async mounted() {
    try {
      await this.fetch()
      this.allreadyValidated = false
    } catch (error) {
      console.error(error)
      throw error
    }
    this.$v.$reset()
  },
  methods: {
    isValid(path, checkField = '$invalid') {
      return this.validateField(path, checkField, this.allreadyValidated)
    },
    async fetch() {
      this.editing = this?.entityId
      if (this.editing) {
        const payload = await this.fetchRegulatoryOrdinance(this.entityId)
        this.regulatoryOrdinance = JSON.parse(
          JSON.stringify(Object.assign(this.regulatoryOrdinance, payload))
        )
      }
    },
    async closeEdit() {
      const result = await await this.showLostChanges()
      if (result.isConfirmed) {
        return this.goBack(this.regulatoryOrdinance)
      }
    },
    async goBack(ordinance) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$listeners?.hasOwnProperty('onGoBack')) {
        return this.$emit('onGoBack', ordinance)
      }
      return this.$router.push('/regulatory-ordinances')
    },
    async saveRegulatoryOrdinance() {
      this.allreadyValidated = true
      this.$v.$touch()

      console.warn(this.$v)
      if (this.$v.$invalid) {
        await this.showErrorModal({
          text: 'Alguns campos estão inválidos por favor verifique e tente novamente.'
        })
      } else {
        // do your submit logic here
        if (this.editing) {
          await this.updateRegulatoryOrdinance(this.regulatoryOrdinance)
        } else {
          await this.createRegulatoryOrdinance(this.regulatoryOrdinance)
        }
        await this.showSuccessModal({})
        return this.goBack(this.regulatoryOrdinance)
      }
    }
  },
  computed: {
    pageTitle() {
      if (!this.editing) {
        return 'Novo Portarias Regulatórias do Inmetro'
      } else {
        return (
          'Editando ' +
          (this.regulatoryOrdinance.name ? this.regulatoryOrdinance.name : '')
        )
      }
    },
    pageSubTitle() {
      if (!this.editing) {
        return 'Você está criando um novo Portarias Regulatórias do Inmetro'
      } else {
        return 'Você está editando um Portarias Regulatórias do Inmetro'
      }
    },
    createdAt() {
      return this.regulatoryOrdinance.created_at
        ? dateFn()(this.regulatoryOrdinance.created_at)
        : '--/--/--'
    },
    updatedAt() {
      return this.regulatoryOrdinance.updated_at
        ? dateFn()(this.regulatoryOrdinance.updated_at)
        : '--/--/--'
    }
  }
}
</script>
