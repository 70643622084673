'use strict'

import { setState, mutate } from '@/helpers/store-helpers'

import servicesStatusesApi from '@/features/services-statuses/services-statuses-api'

export default {
  namespaced: true,
  state: {
    statusesBusy: false,
    serviceStatuses: []
  },
  actions: {
    async fetchMounted({ state, dispatch }) {
      if (!state.serviceStatuses || state.serviceStatuses.length === 0) {
        await dispatch('fetchStatuses')
      }
    },
    async fetchStatuses({ state, commit }, id) {
      if (!state.statusesBusy) {
        try {
          setState(commit, 'statusesBusy', true)
          const allStatuses = await servicesStatusesApi().list()
          setState(commit, 'serviceStatuses', allStatuses)
        } catch (e) {
          console.error(e)
          throw e
        } finally {
          setState(commit, 'statusesBusy', false)
        }
      }
    }
  },
  getters: {},
  mutations: {
    // needed by setState
    mutate
  }
}
