'use strict'

// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './stores'
import { sync } from 'vuex-router-sync'

import BootstrapVue from 'bootstrap-vue'
import Notifications from 'vue-notification'

import globals from '@/globals'
import Popper from 'popper.js'
import http from '@/services/http'
import VueCurrencyInput from 'vue-currency-input'

import globalMixin from '@/mixins/global-mixin.js'
import authenticationMixin from '@/features/authentication/store/mixin'
import LazyComponent from 'v-lazy-component'
import pluginsLoader from '@/load-plugins'

import './registerServiceWorker'

Vue.config.productionTip = false
/**
 * Event bus responsável por notificar qualquer componente sobre um certo evento
 */
export const eventBus = new Vue() // creating an event bus.

Vue.prototype.$http = http()

pluginsLoader(Vue)

// Required to enable animations on dropdowns/tooltips/popovers
// @ts-ignore
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
Vue.config.productionTip = false
// hide components when not visible
Vue.use(LazyComponent)
Vue.mixin(globalMixin)
Vue.mixin(authenticationMixin)

// format inputs
Vue.use(VueCurrencyInput)

// validate fieldsplugins
Vue.use(BootstrapVue)
// show toasty notification common used to errors
Vue.use(Notifications)
// Global RTL flag
Vue.mixin({
  data: globals
})

require('./extensions/index')(Vue) // Carrea todas extensões de diretivas e etc

// will persist any state
sync(store, router)

export const { $store } = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
