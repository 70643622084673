// Arquivo de teste validador
const calcCheckDigit = require('../math/find-check-digit')
const removeCheckDigit = require('../../../utils/remove-check-digit')
const getLastDigit = require('../../../utils/get-last-digit')

/**
 * Validate Digits from seals
 * @param {String} seal Seal that will be validated without check digit
 */
function validateSeal(seal, sumDigit, verbose = false) {
  if (!sumDigit) sumDigit = 4
  try {
    seal = seal.toString()

    const checkDigit = parseInt(getLastDigit(seal))
    const currentSeal = removeCheckDigit(seal)

    const foundedDigit = calcCheckDigit(currentSeal.split(''), sumDigit)
    if (verbose) {
      return {
        currentSeal,
        checkDigit,
        foundedDigit,
        isValid: checkDigit === foundedDigit
      }
    } else return checkDigit === foundedDigit
  } catch (error) {
    console.error('[ERROR] Seal parsed', seal, error)
    throw error
  }
}

module.exports = validateSeal
