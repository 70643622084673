import HttpClient from '@/services/http-client'

class ReportEmissionApi extends HttpClient {
  async index() {
    return (await this.http.get('/v1/report-types')).data
  }

  async get(id) {
    return (await this.http.get('/v1/report-types/' + id)).data
  }

  async delete(id) {
    return (await this.http.delete('/v1/report-types/' + id)).data
  }
}
export default () => new ReportEmissionApi()
