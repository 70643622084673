var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": _vm.buttonColor(_vm.value.slug),
      "title": _vm.value.description
    }
  }, [_vm._v(" " + _vm._s(_vm.value.name) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }