import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    goToRegulatoryOrdinance(id) {
      this.$router.push('/regulatory-ordinances/' + id)
    },
    ...mapActions('regulatoryOrdinances', [
      'listRegulatoryOrdinances',
      'fetchRegulatoryOrdinance',
      'deleteRegulatoryOrdinance',
      'createRegulatoryOrdinance',
      'updateRegulatoryOrdinance'
    ]),
    ...mapMutations('regulatoryOrdinances', [
      'cleanRegulatoryOrdinancesErrors',
      'setRegulatoryOrdinancesList',
      'setRegulatoryOrdinancesFilter',
      'setRegulatoryOrdinancesBusy'
    ])
  },
  computed: {
    ...mapState('regulatoryOrdinances', [
      'regulatoryOrdinanceIsBusy',
      'regulatoryOrdinanceList',
      'regulatoryOrdinanceFilter',
      'regulatoryOrdinancePerPage',
      'regulatoryOrdinanceCurrentPage',
      'regulatoryOrdinanceTotalRows',
      'regulatoryOrdinanceLastPage'
    ]),
    ...mapGetters('regulatoryOrdinances', [
      'filteredRegulatoryOrdinances'
    ])
  }
}
