import HttpClient from '@/services/http-client'

class ATMeterSerial extends HttpClient {
  async service(id) {
    return this.rest.get(`/v1/services/${id}`)
  }

  // /services/${serviceId}/meter-serials
  async list({
    page = 1,
    limit = 20,
    service_id = null,
    meter_serial = null,
    reserved_number = null,
    toi = null,
    uc = null,
    status = null
  }) {
    return this.rest.get(`/v1/services/${service_id}/meter-serials`, {
      params: {
        page,
        limit,
        meter_serial,
        reserved_number,
        toi,
        uc,
        status
      }
    })
  }

  async store({ payload, serviceId }) {
    return this.rest.post(`/v1/services/${serviceId}/meter-serials`, payload)
  }

  async get(id, { serviceId }) {
    return this.rest.get(`/v1/services/${serviceId}/meter-serials/` + id)
  }

  async delete(id, { serviceId }) {
    return this.rest.delete(`/v1/services/${serviceId}/meter-serials/` + id)
  }

  async update({ payload: serviceMeterSerial, serviceId }) {
    return this.rest.patch(
      `/v1/services/${serviceId}/meter-serials/` + serviceMeterSerial.id,
      serviceMeterSerial
    )
  }
}
export default () => new ATMeterSerial()
