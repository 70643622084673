import HttpClient from '@/services/http-client'

class TRSService extends HttpClient {
  async list(opts) {
    const { page, q, limit } = opts || {}

    return this.rest.get('/v1/seals-bag-liability-term', {
      params: {
        page: page ?? 1,
        limit: limit ?? undefined,
        q: q ?? undefined
      }
    })
  }

  async store(payload) {
    payload.seals = payload.seals.map(val => {
      return { code: val.code }
    })

    return this.rest.post('/v1/seals-bag-liability-term', payload, {
      timeout: 120000
    })
  }

  async delete(trsID) {
    return this.rest.delete('/v1/seals-bag-liability-term/' + trsID)
  }
}
export default () => new TRSService()
