'use strict'

import HttpClient from '@/services/http-client'

import ValidationException from '@/features/exceptions/ValidationException'

class Anomalies extends HttpClient {
  async list(opts) {
    const {
      page = 1,
      text = null,
      perPage = 10,
      onlyPieceId = null,
      excludePieceId = null,
      clientId = null
    } = opts || {}

    return this.rest.get('/v1/anomalies', {
      params: {
        page: page,
        limit: perPage,
        q: text,
        onlyPieceId,
        excludePieceId,
        clientId
      },
      cache: {
        maxAge: 10 * 1000,
        ignoreCache: false,
        exclude: { query: false }
      }
    })
  }

  async copy(opts) {
    const { from = null, to = null } = opts ?? {}
    return this.rest.post('/v1/copy-anomalies', { from, to })
  }

  async get(id) {
    return this.rest.get('/v1/anomalies/' + id)
  }

  async getServiceMeterSerialAnomaly(service_meter_serial_id) {
    return this.rest.get('/v1/anomaly-meter-serial/' + service_meter_serial_id)
  }

  async updateServiceMeterSerialAnomaly(
    serviceMeterSerialId,
    anomalies,
    anomaly_description
  ) {
    return this.rest.patch('/v1/anomaly-meter-serial/' + serviceMeterSerialId, {
      anomalies,
      anomaly_description
    })
  }

  async store(payload) {
    try {
      return this.rest.post('/v1/anomalies', payload)
    } catch (error) {
      // adiciona o id do selo a mensagem de erro
      if (error.response.status === 400) {
        throw new ValidationException(
          'Alguns campos estão inválidos',
          error.response.data
        )
      }
    }
  }

  async update(payload) {
    return this.rest.patch('/v1/anomalies/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/anomalies/' + id)
  }
}
export default () => new Anomalies()
