export default {
  methods: {
    showSuccessModal: function (opts) {
      const {
        title = 'Feito, com sucesso !',
        text = '',
        type = 'success'
      } = opts || {}
      return this.$swal({ title: title, text: text, icon: type })
    }
  }
}
