<template>
  <span>
    <template v-if="can">
      <Can :I="can">
        <b-btn
          @click="click"
          :size="size"
          :variant="variant"
          :disabled="disabled"
          :title="title"
          v-b-tooltip.hover
          v-bind="{ ...$props, ...$attrs }"
          v-on="$listeners"
          ><slot></slot
        ></b-btn>
      </Can>
      <Can not :I="can">
        <b-btn
          :size="size"
          :variant="variant"
          @click="() => {}"
          disabled
          v-bind="{ ...$props, ...$attrs }"
          v-on="$listeners"
          :title="titleBlocked"
          v-b-tooltip.hover
        >
          <slot> </slot>
        </b-btn>
      </Can>
    </template>
    <template v-else>
      <b-btn
        :disabled="disabled"
        :size="size"
        :variant="variant"
        :title="title"
        v-b-tooltip.hover
        @click="click"
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
        ><slot></slot>
      </b-btn>
    </template>
  </span>
</template>

<script>
export default {
  name: 'PermissiveBtn',
  props: {
    can: {
      default: null
    },
    click: {
      default: null
    },
    disabled: {
      default: false
    },
    title: {
      default: 'Botão ativado'
    },
    titleBlocked: {
      default: 'Botão desativado'
    },
    size: {
      default: 'xs'
    },
    variant: {
      default: 'primary'
    }
  }
}
</script>

<style></style>
