import HttpClient from '@/services/http-client'

class ReportPreviewApi extends HttpClient {
  async show({ service_id, service_meter_serial_id, show_signature, force_refresh }) {
    return this.rest.get(`/v1/service/${service_id}/report-previews/${service_meter_serial_id}`, {
      params: { show_signature, force_refresh }
    })
  }
}

export default () => new ReportPreviewApi()
