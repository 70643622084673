'use strict'

export default Vue => {
  const importedModules = require.context('./plugins', true, /\.(js|vue)$/i)
  // const stringList = new Map() // for debug purposes
  const plugins = importedModules.keys().map(key => {
    // const name = key.match(/[\w\s-]+/)[0] // for debug purposes
    if (process?.env?.NODE_ENV !== 'production') {
      // stringList.set(name, key) // for debug purposes
    }
    return importedModules(key).default
  })
  for (const registerFn of plugins) {
    registerFn(Vue)
  }
  // if (process?.env?.NODE_ENV !== 'production') {
  //   console.log('[LOADED PLUGINS]', stringList) // for debug purposes
  // } // for debug purposes
}
