import HttpClient from '@/services/http-client'

class ServiceTypeApi extends HttpClient {
  async get(id) {
    return this.rest.get(`/v1/service-types/${id}`)
  }

  async list(page = 1, limit = 1000, text = null) {
    return this.rest.get('/v1/service-types', {
      params: {
        page,
        limit,
        q: text
      }
    })
  }
}

export default function () {
  return new ServiceTypeApi()
}
