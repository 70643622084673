<template>
  <span>
    <template v-if="can">
      <Can :I="can">
        <b-btn
          variant="primary rounded-pill"
          @click="click"
          :disabled="disabled"
          :title="activeTitle"
          v-b-tooltip.bottom
          v-bind="{ ...$props, ...$attrs }"
          v-on="$listeners"
        >
          <slot></slot>
        </b-btn>
      </Can>
      <Can not :I="can">
        <b-btn
          variant="primary rounded-pill"
          @click="click"
          :disabled="true"
          :title="disableTitle"
          v-b-tooltip.bottom
          v-bind="{ ...$props, ...$attrs }"
          v-on="$listeners"
        >
          <slot></slot>
        </b-btn>
      </Can>
    </template>
    <template v-else>
      <b-btn
        variant="primary rounded-pill"
        @click="click"
        :disabled="disabled"
        :title="activeTitle"
        v-b-tooltip.bottom
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
      >
        <slot></slot>
      </b-btn>
    </template>
  </span>
</template>

<style></style>

<script>
export default {
  name: 'EditBtn',
  props: {
    can: {
      default: null
    },
    click: {
      default: null
    },
    disabled: {
      default: false
    },
    activeTitle: {
      default: 'Realizar Ação'
    },
    disableTitle: {
      default: 'Sem Permissão Para Realizar Ação'
    }
  }
}
</script>
