var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mw-100"
  }, [_c('b-row', {
    staticClass: "px-2 py-auto"
  }, [_c('b-col', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    staticClass: "col-11",
    attrs: {
      "title": _vm.formattedLabel(_vm.value),
      "disabled": _vm.disabled
    }
  }, [_c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "custom-label": _vm.formattedLabel,
      "hide-selected": false,
      "taggable": false,
      "allowEmpty": true,
      "options": _vm.manufactorsList,
      "value": _vm.value,
      "disabled": _vm.disabled,
      "loading": _vm.isBusy,
      "selectLabel": "Escolher",
      "tag-placeholder": "Selecione a filial",
      "placeholder": "Selecione a filial"
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Sem Fabricantes Encontradas")])])], 1), _c('b-row', {
    staticClass: "col-1 p-0 pl-1"
  }, [_c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.right",
      modifiers: {
        "right": true
      }
    }],
    staticClass: "btn-outline-secondary",
    attrs: {
      "size": "xs",
      "aria-disabled": _vm.disabled,
      "disabled": _vm.disabled,
      "title": "Limpar Seleção"
    },
    on: {
      "click": _vm.clean
    }
  }, [_c('span', {
    staticClass: "ion ion-md-close"
  })])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }