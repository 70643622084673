import Vue from 'vue'

import { merge } from 'lodash'

import {
  // eslint-disable-next-line no-unused-vars
  AxiosInstance
} from 'axios'

export default class HttpClient {
  /** @type {AxiosInstance} */
  http

  constructor() {
    this.http = Vue.prototype.$http
  }

  get rest() {
    const that = this
    return {
      get: async (...args) => {
        if (/\d+$/.test(args[0].toString())) {
          // quando for método show /:id ele ignora cache
          return (
            await that.http.get(...merge(args, [args[0], { cache: false }]))
          ).data
        }
        return (await that.http.get(...args)).data
      },
      post: async (...args) => {
        return (await that.http.post(...args)).data
      },
      patch: async (...args) => {
        return (await that.http.patch(...args)).data
      },
      update: async (...args) => {
        return (await that.http.put(...args)).data
      },
      put: async (...args) => {
        return (await that.http.put(...args)).data
      },
      delete: async (...args) => {
        return (await that.http.delete(...args)).data
      }
    }
  }
}
