import HttpClient from '@/services/http-client'

import ValidationException from '@/features/exceptions/ValidationException'

class AVServiceAPI extends HttpClient {
  async list({ statusId, typeId, page, limit, text }) {
    const params = {}
    if (statusId) params.status_id = statusId
    if (typeId) params.type_id = typeId
    params.page = page || 1
    params.limit = limit || 1000
    params.q = text || undefined

    return this.rest.get('/v1/services', {
      params: params
    })
  }

  async get(id) {
    return this.rest.get('/v1/services/' + id)
  }

  async store(payload) {
    try {
      return this.rest.post('/v1/services', payload)
    } catch (error) {
      // adiciona o id do selo a mensagem de erro
      if (error.response.status === 400) {
        throw new ValidationException('Alguns campos estão inválidos', error.response.data)
      }
    }
  }

  async update(payload) {
    return this.rest.patch('/v1/services/' + payload.service.id, payload)
  }

  async changeServiceStatus(payload) {
    return this.rest.put('/v1/change-service-status/' + payload.id, {
      service_status_id: payload.service_status_id
    })
  }

  async delete(id) {
    return this.rest.delete('/v1/services/' + id)
  }
}
export default () => new AVServiceAPI()
