'use strict'

import manualInspectionApi from '@/features/manual-inspections/manual-inspections-api'

import { debounce } from 'lodash'

// eslint-disable-next-line no-unused-vars
import { setState, filterElements, mutate } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    _filter: '',
    inspectionsBusy: false,
    inspectionsSerials: [],
    aditionalFields: [],
    fieldsLegends: [],
    inspectionsFields: [
      {
        key: 'id',
        sortable: true,
        label: 'ID'
      },
      {
        key: 'serial_number',
        sortable: true,
        label: 'Código de série'
      }
    ]
    // table
  },
  actions: {
    debounceAction: debounce(({ dispatch }, { name, value }) => {
      dispatch(name, value)
    }, 1000),
    async repproveAllInspections({ state, commit }, { service_id, note_item_id }) {
      try {
        setState(commit, 'inspectionsBusy', true)
        console.warn('inspections', { service_id, note_item_id })
        const inspectionSerials = await manualInspectionApi().repproveAllInspections(service_id, note_item_id)

        if (inspectionSerials) {
          commit('updateInspectionSerials', inspectionSerials)
        }
        setState(commit, 'inspectionsBusy', false)
      } catch (error) {
        setState(commit, 'inspectionsBusy', false)
        throw error
      }
    },
    async approveAllInspections({ state, commit }, { service_id, note_item_id }) {
      try {
        console.warn('inspections', { service_id, note_item_id })
        setState(commit, 'inspectionsBusy', true)
        const inspectionSerials = await manualInspectionApi().approveAllInspections(service_id, note_item_id)
        if (inspectionSerials) {
          commit('updateInspectionSerials', inspectionSerials)
        }
        setState(commit, 'inspectionsBusy', false)
      } catch (error) {
        setState(commit, 'inspectionsBusy', false)
        throw error
      }
    },
    async needManualInspection({ state, commit }, { service_id, note_item_id }) {
      try {
        setState(commit, 'inspectionsBusy', true)
        const inspectionSerials = await manualInspectionApi().needManualInspection(service_id, note_item_id)
        if (inspectionSerials) {
          commit('updateInspectionSerials', inspectionSerials)
        }
        setState(commit, 'inspectionsBusy', false)
        return inspectionSerials
      } catch (error) {
        setState(commit, 'inspectionsBusy', false)
        throw error
      }
    },
    async switchSerialInspection(
      { state, commit, dispatch },
      { serviceItemId, inspectionId, meterSerial = null, inspectionTypeId = null }
    ) {
      try {
        setState(commit, 'inspectionsBusy', true)
        const payload = await manualInspectionApi().switchManualInspections(
          serviceItemId,
          inspectionId,
          null,
          meterSerial,
          inspectionTypeId
        )
        await dispatch('meterSerialsAnomalies/fetchSerials', serviceItemId, {
          root: true
        })
        if (payload) {
          // commit('updateInspectionSerials', payload)
        }
        setState(commit, 'inspectionsBusy', false)
        return payload
      } catch (error) {
        setState(commit, 'inspectionsBusy', false)
        throw error
      }
    }
  },
  mutations: {
    // set state need this
    mutate,
    updateInspectionSerials(state, payload) {
      state.fieldsLegends = []
      if (payload && payload.length > 0) {
        state.inspectionsSerials = payload

        for (const inspField of state.inspectionsSerials[0].manualInspections) {
          state.fieldsLegends.push(inspField.inspectionType)
          state.aditionalFields.push({
            key: inspField.inspectionType.slug,
            label: inspField.inspectionType.sheet_column_name,
            inspection_type_id: inspField.inspectionType.id,
            approved: false,
            allowed: false,
            sortable: true
          })
        }
        console.log('state.inspectionsSerial', state.inspectionsSerials[0])
        console.log('state.aditionalFields', state.aditionalFields)
        console.log('state.fieldsLegends', state.fieldsLegends)
      } else {
        state.inspectionsSerials = []
        state.aditionalFields = []
        state.fieldsLegends = []
      }
    },
    filterInspections(state, newValue) {
      state._filter = newValue
    }
  },
  getters: {
    filteredInspections(state) {
      // filter our data
      return filterElements(state.inspectionsSerials, state._filter)
    },
    normalizedInpectionFields(state) {
      try {
        return [...state.inspectionsFields, ...state.aditionalFields]
      } catch (e) {
        console.error(e)
      }
      return state.inspectionsFields
    }
  }
}
