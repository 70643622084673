import HttpClient from '@/services/http-client'

class ExpertModeService extends HttpClient {
  async show(id) {
    return this.rest.get('/v1/expert-modes/' + id)
  }

  async fetchServiceSerialExpertMode(id) {
    return this.rest.get('/v1/expert-modes/find-or-create', {
      params: {
        service_meter_serial_id: id
      }
    })
  }

  async finishInspection(serviceMeterSerialId) {
    return this.rest.post('/v1/expert-modes/finish-meter', {
      service_meter_serial_id: serviceMeterSerialId
    })
  }

  async openServiceMeter(serviceMeterSerialId) {
    return this.rest.post('/v1/expert-modes/open-meter', {
      service_meter_serial_id: serviceMeterSerialId
    })
  }

  async moveMeterOnService(serviceMeterSerialId, newServiceId) {
    return this.rest.post('/v1/expert-modes/move-meter', {
      service_meter_serial_id: serviceMeterSerialId,
      service_id: newServiceId
    })
  }

  async getServiceMeterHistory(serviceMeterSerialId) {
    return this.rest.post('/v1/expert-modes/history', {
      service_meter_serial_id: serviceMeterSerialId
    })
  }

  async list(page = 1, limit = 1000, text = null) {
    return this.rest.get('/v1/expert-modes', {
      params: {
        page,
        limit,
        q: text
      }
    })
  }

  async create(payload) {
    return this.rest.post('/v1/expert-modes', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/expert-modes/' + payload.id, payload)
  }

  async generateDescription(payload) {
    return this.rest.patch('/v1/expert-modes/' + payload.id + '/generate-description', payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/expert-modes/' + id)
  }

  async addToDescription(text, description) {
    return this.rest.patch('/v1/expert-modes/add-description', {
      text,
      description
    })
  }

  async removeOfDescription(text, description) {
    return this.rest.patch('/v1/expert-modes/remove-description', {
      text,
      description
    })
  }
}

export default () => new ExpertModeService()
