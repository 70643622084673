var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "id": _vm.id
    }
  }, [_vm.permission && _vm.path ? _c('div', [_vm.text ? _c(_vm.componentParentName, {
    tag: "component",
    attrs: {
      "I": _vm.permission
    }
  }, [_c('b-link', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: true,
      expression: "true",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.text
    },
    on: {
      "click": _vm.openLink
    }
  }, [_vm._v(_vm._s(_vm.formattedText))])], 1) : _c('span', [_vm._v(" N/I")]), _vm.text ? _c(_vm.componentParentName, {
    tag: "component",
    attrs: {
      "not": "",
      "I": _vm.permission
    }
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: true,
      expression: "true",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.text + ' (sem permissão)'
    }
  }, [_vm._v(_vm._s(_vm.formattedText))])]) : _c('span', [_vm._v(" N/I")])], 1) : _c('div', [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: true,
      expression: "true",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.text
    }
  }, [_vm._v(_vm._s(_vm.formattedText))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }