var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mw-100"
  }, [_c('b-row', {
    staticClass: "px-1 py-auto"
  }, [_c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('BusyOverlay', {
    attrs: {
      "show": _vm.meterModelBusy
    }
  }, [_c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "custom-label": _vm.formatMetterInfo,
      "hide-selected": false,
      "taggable": false,
      "allowEmpty": true,
      "options": _vm.meterModelList,
      "value": _vm.value,
      "disabled": _vm.disabled,
      "loading": _vm.loadingMeterModel,
      "deselect-label": "Remover seleção",
      "selectLabel": "Escolher",
      "selectedLabel": "Selecionado",
      "tag-placeholder": "Selecione a um modelo de medidor",
      "placeholder": "Selecione o modelo de medidor"
    },
    on: {
      "search-change": _vm.asyncFindMeterModel,
      "select": _vm.onSelectModel,
      "remove": _vm.onRemoveModel,
      "open": _vm.onOpenDropdown
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Sem modelos encontrados.")])])], 1)], 1), _c('b-row', {
    staticClass: "md-1"
  }, [_vm.value ? _c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    staticClass: "btn-outline-danger",
    attrs: {
      "size": "xs",
      "title": "Remover Seleção"
    },
    on: {
      "click": _vm.clean
    }
  }, [_c('span', {
    staticClass: "ion ion-md-close"
  })]) : _vm._e(), _c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    staticClass: "btn-outline-success",
    attrs: {
      "size": "xs",
      "variant": "success",
      "title": "Criar Modelo de Medidor"
    },
    on: {
      "click": _vm.createMeter
    }
  }, [_c('span', {
    staticClass: "ion ion-md-add"
  })])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }