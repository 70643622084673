'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import expertModeExtractorService from '@/features/expert-mode-extractors/expert-mode-extractors-api'
import { debounce } from 'lodash'

const busyMutateName = 'setExpertModeExtractorsBusy'

export default {
  namespaced: true,
  state: {
    expertModeExtractorList: [],
    expertModeExtractorFilter: '',
    expertModeExtractorIsBusy: false,
    expertModeExtractorTotalRows: 0,
    expertModeExtractorCurrentPage: 1,
    expertModeExtractorPerPage: 10,
    expertModeExtractorLastPage: 0,
    unfinishedMeters: false,
    lastFinished: null,
    lastCreationTime: null
  },
  actions: {
    debounceAction: debounce(({ dispatch }, { name, value }) => {
      dispatch(name, value)
    }, 500),
    async listExpertModeExtractors({ state, commit }, payload = {}) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        window.stop()
        setState(commit, 'expertModeExtractorList', [])
        setState(commit, 'hasUnfinishedMeter', false)
        const data = await expertModeExtractorService().getReportPreview(payload)
        setState(commit, 'expertModeExtractorTotalRows', data.total)
        setState(commit, 'expertModeExtractorCurrentPage', data.page)
        setState(commit, 'expertModeExtractorPerPage', data.perPage)
        setState(commit, 'expertModeExtractorLastPage', data.lastPage)
        setState(commit, 'expertModeExtractorList', data.data)
        setState(commit, 'unfinishedMeters', data.hasUnfinished)
      })
    },
    async downloadExpertModeReport({ state, commit }, params = {}) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return expertModeExtractorService().startDownloadGeneration(params)
      })
    },
    async downloadExpertModeReportFile({ state, commit }, params = {}) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await expertModeExtractorService().downloadReportNewWindow(params)
        return payload
      })
    },
    async showExpertModeExtractor({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return expertModeExtractorService().show(id)
      })
    },
    async createExpertModeExtractor({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = expertModeExtractorService().create(payload)
        commit('unshiftExpertModeExtractor', entry)
        await dispatch('listExpertModeExtractors')
        return entry
      })
    },
    async updateExpertModeExtractor({ state, commit, dispatch }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = expertModeExtractorService().update(payload)
        commit('updateExpertModeExtractorInList', entry)
        await dispatch('listExpertModeExtractors')
        return entry
      })
    },
    async deleteExpertModeExtractor({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await expertModeExtractorService().delete(id)
        if (result) await commit('removeExpertModeExtractorInList', id)
        return result
      })
    }
  },
  getters: {
    filteredExpertModeExtractors(state) {
      return filterElements(state.expertModeExtractorList, state.filter)
    },
    hasUnfinishedMeters(state) {
      for (const key in state.unfinishedMeters) {
        if (Object.hasOwnProperty.call(state.unfinishedMeters, key)) {
          return true
        }
      }
      return false
    }
  },
  mutations: {
    mutate,
    setExpertModeExtractorsList(state, payload) {
      state.expertModeExtractorList = payload
    },
    setExpertModeExtractorsFilter(state, payload) {
      state.expertModeExtractorList = payload
    },
    setExpertModeExtractorsBusy(state, value = false) {
      state.expertModeExtractorIsBusy = value
    },
    unshiftExpertModeExtractor(state, payload) {
      state.expertModeExtractorList.unshift(payload)
    },
    removeExpertModeExtractorInList(state, payload) {
      state.expertModeExtractorList = state.expertModeExtractorList.filter(
        (expertModeExtractor) => expertModeExtractor.id !== payload
      )
    },
    updateExpertModeExtractorInList(state, payload) {
      if (state.expertModeExtractorList) {
        state.expertModeExtractorList = state.expertModeExtractorList.map((val) => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
