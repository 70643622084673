<template>
  <span>
    <PermissiveBtn
      size="xs"
      variant="primary"
      title="Abrir Entrada"
      titleBlocked="Sem permissão para abrir"
      :can="can"
      @click="click"
      :disabled="disabled"
      v-b-tooltip.left
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    >
      Ver
    </PermissiveBtn>
  </span>
</template>

<style></style>

<script>
export default {
  name: 'RemoveBtn',
  props: {
    can: {
      default: null
    },
    click: {
      default: null
    },
    disabled: {
      default: false
    }
  }
}
</script>
