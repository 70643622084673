'use strict'
// Thi store depends from some plugins like CASL in Vue instance

import ipemReportsApi from '@/features/ipem-reports/ipem-reports-api'
import { setState, filterElements, mutate } from '@/helpers/store-helpers'
import { debounce } from 'lodash'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    ipemGeneration: false,
    isLoadBusy: false,
    meterError: undefined,
    filter: '',
    ipemReports: [],
    ipemReportsPreview: [],
    isPreviewBusy: false,
    previewLabels: [
      {
        sortable: true,
        label: 'Nº Nota Fiscal',
        key: 'nf_number'
      },
      {
        sortable: true,
        label: 'Nº de série',
        key: 'serial_number'
      },
      {
        sortable: true,
        label: 'Selo Físico',
        key: 'seal'
      },
      {
        sortable: true,
        label: 'Modelo do Medidor',
        key: 'model_name'
      },
      {
        sortable: true,
        label: 'Fabricante',
        key: 'manufactor'
      },
      {
        sortable: true,
        label: 'Data de Criação da Nota',
        key: 'nf_created_at'
      }
    ]
  },
  actions: {
    debounceAction: debounce(({ dispatch }, { name, value }) => {
      dispatch(name, value)
    }, 2000),
    async fetchPreview({ state, commit }, payload) {
      try {
        setState(commit, 'isPreviewBusy', true)
        const reportsPreview = await ipemReportsApi().preview(payload, {
          params: {
            page: 1,
            limit: 1000
          }
        })
        setState(commit, 'ipemReportsPreview', reportsPreview)
        setState(commit, 'isPreviewBusy', false)
        return reportsPreview
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        setState(commit, 'isPreviewBusy', false)
        throw e
      } finally {
        setState(commit, 'isPreviewBusy', false)
      }
    },
    async checkBusyIPEM({ state, commit, dispatch }) {
      try {
        commit('setBusy', true)
        const data = await ipemReportsApi().check()
        await setState(commit, 'ipemGeneration', data.file)
        if (data.file !== state.ipemGeneration) {
          await dispatch('fetchIpemReports')
        }
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    },
    async fetchIpemReports({ state, commit }) {
      try {
        commit('setBusy', true)
        const IPEMReports = await ipemReportsApi().list({
          params: {
            page: 1,
            limit: 1000
          }
        })
        setState(commit, 'ipemReports', IPEMReports.data)
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    },
    async createIpemReport({ state, commit }, payload) {
      try {
        setState(commit, 'currentTermLoaded', null)
        commit('setBusy', true)
        const ipemReports = await ipemReportsApi().store(payload)
        setState(commit, 'currentTermLoaded', ipemReports)
        return ipemReports
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    },
    async deleteIpemReport({ state, commit }, payload) {
      try {
        commit('setBusy', true)
        const createdLoad = await ipemReportsApi().delete(payload.id)
        if (createdLoad) await commit('removeFromIpemList', payload.id)
      } catch (e) {
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    addToIPEMReports(state, load) {
      state.ipemReports.push(load)
    },
    removeFromIpemList(state, id) {
      const reports = JSON.stringify(
        state.ipemReports.filter(repo => repo.id !== id)
      )
      state.ipemReports = JSON.parse(reports)
    },
    setBusy(state, val = false) {
      state.isLoadBusy = val
    },
    filterTerms(state, filter = '') {
      state.filter = filter
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalItems(state) {
      if (state.ipemReports) return state.ipemReports.length || 0
      return 0
    },
    selectedMeter(state) {
      return state.selected
    },
    ipemReportsFields(state) {
      return [
        {
          label: 'ID',
          sortable: true,
          key: 'id'
        },
        {
          label: 'Arquivo de Download',
          sortable: true,
          key: 'file'
        },
        {
          label: 'Criado por',
          sortable: true,
          key: 'createdBy'
        },
        {
          sortable: true,
          label: 'Gerado em',
          key: 'created_at'
        },

        {
          label: 'Opções',
          sortable: false,
          key: 'edit'
        }
      ]
    },
    virtualSealsFields(state) {
      return [
        {
          sortable: true,
          label: 'Nº do Selo Virtual',
          key: 'number'
        },
        {
          sortable: true,
          label: 'Medidor Associado',
          key: 'meterSerials'
        }
      ]
    },
    filteredIpemReports(state) {
      return filterElements(state.ipemReports, state.filter)
    }
  }
}
