<template>
  <b-input
    :id="id"
    ref="input"
    class="form-control"
    @change="onChange"
    @input="onInput"
    :value="formattedValue"
    v-currency="options"
    v-bind="{ ...$props, ...$attrs }"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </b-input>
</template>

<script>
export default {
  name: 'FloatInput',
  props: {
    id: {
      default: undefined
    },
    value: {
      type: Number,
      default: null
    },
    options: {
      type: Object,
      default: () => {
        return {
          currency: {
            suffix: null,
            prefix: null
          },
          precision: {
            min: 0
          },
          valueAsInteger: false,
          distractionFree: false,
          autoDecimalMode: false,
          allowNegative: true
        }
      }
    }
  },
  data() {
    return {
      formattedValue: null
    }
  },
  watch: {
    value(value) {
      this.setValue(value)
    }
  },
  mounted() {
    this.setValue(this.value)
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value)
    },
    onInput(value) {
      this.$emit('input', this.$ci.getValue(this.$refs.input))
      this.formattedValue = value
    },
    onChange(value) {
      this.$emit('change', this.$ci.getValue(this.$refs.input))
      this.formattedValue = value
    }
  }
}
</script>
