var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.can ? _c('div', [_c('Can', {
    attrs: {
      "I": _vm.can
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.path
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    ref: "stampbutton",
    attrs: {
      "title": _vm.buttonTxt + ' ' + _vm.name,
      "size": "md",
      "variant": "primary rounded-pill"
    }
  }, [_c('i', {
    staticClass: "ion ion-md-add"
  }), _vm._v("  " + _vm._s(_vm.buttonTxt + ' ' + _vm.name) + " ")])], 1)], 1), _c('Can', {
    attrs: {
      "not": "",
      "I": _vm.can
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    ref: "stampbutton",
    attrs: {
      "title": 'Você não tem permissão para ' + _vm.buttonTxt + ' ' + _vm.name,
      "size": "md",
      "variant": "primary rounded-pill",
      "disabled": ""
    }
  }, [_c('i', {
    staticClass: "ion ion-md-add"
  }), _vm._v("  " + _vm._s(_vm.buttonTxt + ' ' + _vm.name) + " ")])], 1)], 1) : _c('div', [_c('router-link', {
    attrs: {
      "to": _vm.path
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    ref: "stampbutton",
    attrs: {
      "title": _vm.buttonTxt + ' ' + _vm.name,
      "size": "md",
      "variant": "primary rounded-pill"
    }
  }, [_c('i', {
    staticClass: "ion ion-md-add"
  }), _vm._v("  " + _vm._s(_vm.buttonTxt + ' ' + _vm.name) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }