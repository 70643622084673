import HttpClient from '@/services/http-client'

class CalibrationCertificatesService extends HttpClient {
  async list(params) {
    const { page = 1, perPage = 50, text = null, onlyActive = false } = params || {}
    return this.rest.get('/v1/calibration-certificates', {
      params: {
        perPage,
        page,
        q: text,
        onlyActive: onlyActive
      }
    })
  }

  async get(id) {
    return this.rest.get('/v1/calibration-certificates/' + id)
  }

  async store(payload, onUpload) {
    const formData = this.createFormDataUpload(payload)

    return (
      await this.http.post('/v1/calibration-certificates', formData, {
        timeout: 3600000,
        onUploadProgress: onUpload || null
      })
    ).data
  }

  async update(payload, onUpload) {
    const formData = this.createFormDataUpload(payload)

    return this.rest.put('/v1/calibration-certificates/' + payload.id, formData, {
      timeout: 3600000,
      onUploadProgress: onUpload || null
    })
  }

  async delete(id) {
    return this.rest.delete('/v1/calibration-certificates/' + id)
  }

  createFormDataUpload({
    spreadsheet,
    procedure,
    number,
    password,
    expires_at,
    calibrated_at,
    emitted_at,
    testTables
  }) {
    const formData = new FormData()
    if (spreadsheet) {
      formData.append('spreadsheet', spreadsheet.file, spreadsheet.file.name)
    }
    if (password) formData.append('password', password)
    if (procedure) formData.append('procedure', procedure)
    if (number) formData.append('number', number)
    if (expires_at) formData.append('expires_at', expires_at)
    if (calibrated_at) formData.append('calibrated_at', calibrated_at)
    if (emitted_at) formData.append('emitted_at', emitted_at)
    if (testTables) formData.append('testTables', JSON.stringify(testTables))
    console.warn('Form Data', formData)
    return formData
  }
}

export default () => new CalibrationCertificatesService()
