var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isValid ? _c('section', {
    staticClass: "photo-capture",
    style: _vm.styling
  }, [_c('video', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showVideo,
      expression: "showVideo"
    }],
    ref: "player",
    staticClass: "camera",
    attrs: {
      "autoplay": "",
      "playsinline": ""
    }
  }), _c('canvas', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showVideo,
      expression: "!showVideo"
    }],
    ref: "canvas",
    staticClass: "preview"
  }), !_vm.hideBtns ? _c('div', {
    staticClass: "center photo-capture-actions"
  }, [_vm.showVideo ? _c('button', {
    class: 'btn flex-center ' + _vm.buttonsClasses,
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.capture.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.captureBtnContent) + " ")]) : _c('div', {
    staticClass: "controls"
  }, [_c('button', {
    class: 'btn ' + _vm.buttonsClasses,
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.cancelBtnContent) + " ")]), _c('button', {
    class: 'btn ' + _vm.buttonsClasses,
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.done.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.doneBtnContent) + " ")])])]) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }