'use strict'

export default {
  namespaced: true,
  state: {
    breadCrumbSegments: []
  },
  actions: {
    updateBreadCrumb({ commit, state }, segments) {
      commit('setBreadCrumbPath', segments)
    }
  },
  mutations: {
    setBreadCrumbPath(state, path = []) {
      state.breadCrumbSegments = path
    }
  },
  getters: {
    breadCrumbPath(state) {
      return state.breadCrumbSegments ?? []
    }
  }
}
