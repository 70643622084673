'use strict'

import { optOp } from '../../../helpers/operators'
// Thi store depends from some plugins like CASL in Vue instance
import anomaliesApi from '@/features/anomalies/anomalies-api'
import {
  setState,
  doAsyncBusyTask,
  mutate,
  filterElements
} from '@/helpers/store-helpers'
import expertModeApi from '@/features/expert-modes/expert-mode-api'

const busyMutateName = 'isBusy'

export default {
  namespaced: true,
  state: {
    currentAnomalyClient: null,
    anomalyBusy: false,
    anomalyName: null,
    anomalyCategory: null,
    anomalyDescription: null,
    anomalyID: null,
    anomalyList: [],
    anomalyFilter: '',
    anomalyCurrentPage: 1,
    anomalyTotalRows: 0,
    anomalyLastPage: 1,
    anomalyPerPage: 40
  },
  actions: {
    async copyAnomalyToClient({ state, commit }, payload) {
      const {
        page = 1,
        perPage = 40,
        text = '',
        onlyPieceId = null,
        clientId = null,
        excludePieceId = null,
        from = null,
        to = null
      } = payload ?? {}
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const migrated = await anomaliesApi().copy({ from, to })
          const payload = await anomaliesApi().list({
            page,
            perPage,
            text,
            onlyPieceId,
            excludePieceId,
            clientId
          })
          setState(commit, 'anomalyFilter', text)
          setState(commit, 'anomalyTotalRows', payload.total)
          setState(commit, 'anomalyCurrentPage', payload.page)
          setState(commit, 'anomalyPerPage', payload.perPage)
          setState(commit, 'anomalyLastPage', payload.lastPage)
          setState(commit, 'anomalyList', payload.data)

          return migrated
        }
      )
    },
    async listAnomalies({ state, commit }, payload = {}) {
      const {
        page = 1,
        perPage = 10,
        text = '',
        onlyPieceId = null,
        clientId = null,
        excludePieceId = null
      } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await anomaliesApi().list({
            page,
            perPage,
            text,
            onlyPieceId,
            excludePieceId,
            clientId
          })
          setState(commit, 'anomalyFilter', text)
          setState(commit, 'anomalyTotalRows', payload.total)
          setState(commit, 'anomalyCurrentPage', payload.page)
          setState(commit, 'anomalyPerPage', payload.perPage)
          setState(commit, 'anomalyLastPage', payload.lastPage)
          setState(commit, 'anomalyList', payload.data)
          return payload
        }
      )
    },
    async createOrUpdateAnomaly({ state, commit }, payload) {
      try {
        commit('isBusy', true)
        const isEditing = optOp(payload)
        if (isEditing) {
          const anomaly = await anomaliesApi().update(payload)
          await commit('updateListRef', anomaly)
        } else {
          const anomaly = await anomaliesApi().store(payload)
          await commit('unshiftToList', anomaly)
        }
      } catch (error) {
        commit('isBusy', false)
        throw error
      } finally {
        commit('isBusy', false)
      }
    },
    async addToDescription({ state, commit }, { text, description }) {
      try {
        commit('isBusy', true)
        const data = await expertModeApi().addToDescription(text, description)
        commit('isBusy', false)
        return data
      } catch (error) {
        commit('isBusy', false)
        throw error
      }
    },
    async removeOfDescription({ state, commit }, { text, description }) {
      try {
        commit('isBusy', true)
        const data = await expertModeApi().removeOfDescription(text, description)
        commit('isBusy', false)
        return data
      } catch (error) {
        commit('isBusy', false)
        throw error
      }
    },
    async deleteAnomaly({ state, commit }, payload) {
      try {
        commit('isBusy', true)
        const data = await anomaliesApi().delete(payload.id)
        await commit('removeFromList', data)
      } catch (error) {
        commit('isBusy', false)
        throw error
      } finally {
        commit('isBusy', false)
      }
    },
    async fetchMeterSerialAnomalies({ state, commit }, payload = {}) {
      try {
        const { service_meter_serial_id } = payload
        commit('isBusy', true)
        const data = await anomaliesApi().getServiceMeterSerialAnomaly(
          service_meter_serial_id
        )

        return data
      } catch (error) {
        commit('isBusy', false)
        throw error
      } finally {
        commit('isBusy', false)
      }
    },
    async updateMeterSerialAnomalies({ state, commit }, payload = {}) {
      try {
        const {
          serviceMeterSerialId = null,
          anomalies = [],
          anomaly_description = ''
        } = payload
        commit('isBusy', true)
        const data = await anomaliesApi().updateServiceMeterSerialAnomaly(
          serviceMeterSerialId,
          anomalies,
          anomaly_description
        )
        return data
      } catch (error) {
        commit('isBusy', false)
        throw error
      } finally {
        commit('isBusy', false)
      }
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    filteredAnomalies(state) {
      return filterElements(state.anomalyList, state.filter)
    }
  },
  mutations: {
    mutate,
    isBusy(state, busyValue = false) {
      state.anomalyBusy = busyValue
    },
    unshiftToList(state, model) {
      state.anomalyList.unshift(model)
    },
    updateListRef(state, update) {
      if (state.anomalyList) {
        state.anomalyList = state.anomalyList.map(val => {
          if (val.id === update.id) {
            return update
          }
          return val
        })
      }
    },
    removeFromList(state, model) {
      state.anomalyList = state.anomalyList.filter(met => met.id !== model.id)
    }
  }
}
