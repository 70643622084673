import HttpClient from '@/services/http-client'

class MeterSerialTableReportsApi extends HttpClient {
  async list(config) {
    const { serviceId, noteItemId, serialNumber } = config
    return (
      await this.http.get('/v1/meter-serial-table-reports', {
        params: { serviceId, noteItemId, meterSerialNumber: serialNumber }
      })
    ).data
  }

  async get(id) {
    return (await this.http.get('/v1/meter-serial-table-reports/' + id)).data
  }

  async associateSerialReport({ serviceItemID, tableReportID, positions }) {
    const config = {
      timeout: 60000
    }

    return (
      await this.http.post(
        '/v1/meter-serial-table-reports',
        {
          service_item_id: serviceItemID,
          table_report_id: tableReportID,
          positions: positions
        },
        config
      )
    ).data
  }

  async delete(id) {
    return (await this.http.delete('/v1/meter-serial-table-reports/' + id)).data
  }

  async dissociateAll(id) {
    return (
      await this.http.delete('/v1/meter-serial-table-reports/' + id, {
        params: {
          all: true
        }
      })
    ).data
  }

  async recalculateSerialReport(meterSerialTableReportId) {
    return (
      await this.http.put(
        '/v1/meter-serial-table-reports/' + meterSerialTableReportId
      )
    ).data
  }
}
export default () => new MeterSerialTableReportsApi()
