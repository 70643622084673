import HttpClient from '@/services/http-client'

class ServiceMeterSerialApi extends HttpClient {
  async list() {
    return this.rest.get('/v1/service-meter-serials')
  }

  async create(serviceId, payload) {
    return this.rest.post('/v1/services/' + serviceId + '/meter-serials', payload)
  }

  async get(id) {
    return this.rest.get(`/v1/service-meter-serials/${id}`)
  }

  async update(id, payload) {
    return this.rest.patch(`/v1/service-meter-serials/${id}`, payload)
  }

  async updatePDF(serviceMeterSerial) {
    return this.rest.post('/v1/service-meter-serials/update-pdf/', serviceMeterSerial)
  }

  async delete(id) {
    return this.rest.delete(`/v1/service-meter-serials/${id}`)
  }
}
export default () => new ServiceMeterSerialApi()
