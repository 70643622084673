<template>
  <b-select
    :id="id"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    :options="[
      { text: 'Aprovado', value: 1 },
      { text: 'Reprovado', value: 0 },
      { text: 'Não Aplicável', value: null }
    ]"
  />
</template>

<script>
export default {
  name: 'approved-select',
  data() {},
  mounted() {
    if (this.emitOnMount) {
      this.$emit('input', this.value)
    }
  },
  props: {
    id: {
      default: undefined
    },
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      default: null,
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    size: {
      type: [String],
      default: 'md',
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    options: {
      type: [Array],
      required: false,
      default: () => [{ laable: 'Conforme', id: 1 }]
    },
    // eslint-disable-next-line vue/no-unused-properties
    emitOnMount: {
      default: true
    }
  }
}
</script>

<style></style>
