'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import manufactorService from '@/features/manufactors/manufactors-api'

const busyMutateName = 'setManufactorsBusy'

export default {
  namespaced: true,
  state: {
    manufactorList: [],
    manufactorFilter: '',
    manufactorIsBusy: false,
    manufactorTotalRows: 0,
    manufactorCurrentPage: 1,
    manufactorPerPage: 10,
    manufactorLastPage: 0
  },
  actions: {
    async listManufactors({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await manufactorService().list(page, perPage, text)
          setState(commit, 'manufactorTotalRows', payload.total)
          setState(commit, 'manufactorCurrentPage', payload.page)
          setState(commit, 'manufactorPerPage', payload.perPage)
          setState(commit, 'manufactorLastPage', payload.lastPage)

          commit('setManufactorsList', payload.data)
        }
      )
    },
    async showManufactor({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return manufactorService().show(id)
        }
      )
    },
    async createManufactor({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = manufactorService().create(payload)
          commit('unshiftManufactor', entry)
        }
      )
    },
    async updateManufactor({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = manufactorService().update(payload)
          commit('updateManufactorInList', entry)
          return entry
        }
      )
    },
    async deleteManufactor({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await manufactorService().delete(id)
          if (result) await commit('removeManufactorInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredManufactors(state) {
      return filterElements(state.manufactorList, state.filter)
    }
  },
  mutations: {
    mutate,
    setManufactorsList(state, payload) {
      state.manufactorList = payload
    },
    setManufactorsFilter(state, payload) {
      state.manufactorList = payload
    },
    setManufactorsBusy(state, value = false) {
      state.manufactorIsBusy = value
    },
    unshiftManufactor(state, payload) {
      state.manufactorList.unshift(payload)
    },
    removeManufactorInList(state, payload) {
      state.manufactorList = state.manufactorList.filter(
        manufactor => manufactor.id !== payload
      )
    },
    updateManufactorInList(state, payload) {
      if (state.manufactorList) {
        state.manufactorList = state.manufactorList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
