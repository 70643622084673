<template>
  <b-table
    v-on="$listeners"
    :items="items"
    :fields="customFields"
    v-bind="{ ...$props, ...$attrs }"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </b-table>
</template>

<script>
/* eslint-disable */
import { dateFn, dateOnlyFn } from "@/helpers/table-formatter";
export default {
  name: "CTable",
  props: {
    fields: {
      type: Array,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => null,
    },
    // default values
    bordered: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    striped: {
      type: Boolean,
      default: true,
    },
    isStickyHeader: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    isSortIconLeft: {
      type: Boolean,
      default: false,
    },
    isStacked: {
      type: Boolean,
      default: false,
    },
    isStriped: {
      type: Boolean,
      default: false,
    },
    isHoverable: {
      type: Boolean,
      default: true,
    },
    isBordered: {
      type: Boolean,
      default: false,
    },
    isBorderless: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    isFootClone: {
      type: Boolean,
      default: false,
    },
    headerStyle: {
      default: null,
    },
    // Table options
    headerStyleOptions: [
      { text: "Default", value: null },
      { text: "Light", value: "light" },
      { text: "Dark", value: "dark" },
    ],
  },
  computed: {
    customFields() {
      return this.fields.map((value) => {
        const mapped = value;
        if (
          [
            "start_at",
            "end_at",
            "created_at",
            "updated_at",
            "deleted_at",
            "changed_at",
          ].includes(mapped.key)
        ) {
          mapped.formatter = dateFn();
        }
        if (
          [
            "toi_date",
            "afferition_date",
            "emitted_at",
            "expires_at",
            "opened_at",
            "finished_at",
          ].includes(mapped.key)
        ) {
          mapped.formatter = dateOnlyFn();
        }
        if (mapped.formatter === undefined) {
          mapped.formatter = function (value) {
            if (value) return value;
            else return "NI";
          };
        }
        return mapped;
      });
    },
  },
  methods: {},
};
</script>
