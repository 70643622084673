'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import errorThresholdService from '@/features/error-thresholds/error-thresholds-api'

const busyMutateName = 'setErrorThresholdsBusy'

export default {
  namespaced: true,
  state: {
    errorThresholdList: [],
    errorThresholdFilter: '',
    errorThresholdIsBusy: false,
    errorThresholdTotalRows: 0,
    errorThresholdCurrentPage: 1,
    errorThresholdPerPage: 10,
    errorThresholdLastPage: 0
  },
  actions: {
    async listErrorThresholds({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await errorThresholdService().list(page, perPage, text)
          setState(commit, 'errorThresholdTotalRows', payload.total)
          setState(commit, 'errorThresholdCurrentPage', payload.page)
          setState(commit, 'errorThresholdPerPage', payload.perPage)
          setState(commit, 'errorThresholdLastPage', payload.lastPage)

          commit('setErrorThresholdsList', payload.data)
        }
      )
    },
    async showErrorThreshold({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return errorThresholdService().show(id)
        }
      )
    },
    async createErrorThreshold({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = errorThresholdService().create(payload)
          commit('unshiftErrorThreshold', entry)
        }
      )
    },
    async updateErrorThreshold({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = errorThresholdService().update(payload)
          commit('updateErrorThresholdInList', entry)
          return entry
        }
      )
    },
    async deleteErrorThreshold({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await errorThresholdService().delete(id)
          if (result) await commit('removeErrorThresholdInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredErrorThresholds(state) {
      return filterElements(state.errorThresholdList, state.filter)
    }
  },
  mutations: {
    mutate,
    setErrorThresholdsList(state, payload) {
      state.errorThresholdList = payload
    },
    setErrorThresholdsFilter(state, payload) {
      state.errorThresholdList = payload
    },
    setErrorThresholdsBusy(state, value = false) {
      state.errorThresholdIsBusy = value
    },
    unshiftErrorThreshold(state, payload) {
      state.errorThresholdList.unshift(payload)
    },
    removeErrorThresholdInList(state, payload) {
      state.errorThresholdList = state.errorThresholdList.filter(
        errorThreshold => errorThreshold.id !== payload
      )
    },
    updateErrorThresholdInList(state, payload) {
      if (state.errorThresholdList) {
        state.errorThresholdList = state.errorThresholdList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
