import HttpClient from '@/services/http-client'

class TableReportsApi extends HttpClient {
  async list() {
    return (await this.http.get('/v1/table-reports')).data
  }

  async get(id) {
    return (await this.http.get('/v1/table-reports/' + id)).data
  }

  async delete(id) {
    return (await this.http.delete('/v1/table-reports/' + id)).data
  }

  async update(id, payload) {
    return (await this.http.patch('/v1/table-reports/' + id, payload)).data
  }

  async uploadReport(
    selectedFile,
    serviceID,
    onUploadProgress = progressEvent => {}
  ) {
    const config = {
      timeout: 60000
    }

    if (onUploadProgress) config.onUploadProgress = onUploadProgress

    const fd = new FormData()
    fd.append('file', selectedFile.file, selectedFile.file.name)

    return (
      await this.http.post(
        `/v1/service-items/${serviceID}/table-reports`,
        fd,
        config
      )
    ).data
  }
}
export default () => new TableReportsApi()
