'use strict'

import { debounce } from 'lodash'
import { setState, filterElements, mutate } from '@/helpers/store-helpers'

import temperaturesApi from '@/features/inspection-temperatures/inspection-temperature-api'
// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    tempBusy: false,
    filter: null,
    humidity: 5,
    initialTemperature: 20,
    finalTemperature: 20,
    busyIndicators: {},
    temperatureSerials: [],
    temperatureFields: [
      {
        sortable: true,
        key: 'id',
        label: 'ID'
      },
      {
        sortable: true,
        key: 'serial_number',
        label: 'Número de Série'
      },
      {
        sortable: true,
        key: 'initial_temp',
        label: 'Temperatura Inicial'
      },
      {
        sortable: true,
        key: 'final_temp',
        label: 'Temperatura Final'
      }
    ]
    // table
  },
  actions: {
    debounceTempAction: debounce(({ dispatch, commit }, { name, value }) => {
      dispatch(name, value)
    }, 1000),
    async needTemperatures({ state, commit }, { noteItemId, serviceId }) {
      try {
        if (!noteItemId && !serviceId) {
          return
        }
        setState(commit, 'tempBusy', true)
        const inspectionTemps = await temperaturesApi().listMetersTemperatures({
          noteItemId,
          serviceId
        })

        if (inspectionTemps.length > 0) {
          setState(commit, 'temperatureSerials', inspectionTemps)
        }
        return inspectionTemps
      } catch (error) {
        setState(commit, 'tempBusy', false)
        throw error
      }
    },
    async updateWholeTemperatures({ state, commit }, { noteItemId, serviceId }) {
      try {
        setState(commit, 'tempBusy', true)
        const inspectionTemps = await temperaturesApi().updateWholeTemperatures({
          serviceId,
          noteItemId,
          payload: {
            initial_temp: state.initialTemperature,
            final_temp: state.finalTemperature,
            humidity: state.humidity
          }
        })

        if (inspectionTemps.length > 0) {
          setState(commit, 'temperatureSerials', inspectionTemps)
        }
        return inspectionTemps
      } catch (error) {
        setState(commit, 'tempBusy', false)
        throw error
      }
    },
    async updateTemp({ state, commit }, payload) {
      const { noteItemId, id, serviceId, index } = payload || {}
      commit('setTempBusy', { index, value: true })

      try {
        const inspectionTemps = await temperaturesApi().updateTemperature({
          noteItemId,
          id,
          serviceId,
          payload
        })

        commit('updateListTemp', inspectionTemps)

        commit('setTempBusy', { index, value: false })
        return inspectionTemps
      } catch (error) {
        commit('setTempBusy', { index, value: false })
        throw error
      }
    },
    async setInitialTemp({ state, commit }, value) {
      commit('setInitialTemp', value)
    },
    async setFinalTemp({ state, commit }, value) {
      commit('setFinalTemp', value)
    },
    async setHumidty({ state, commit }, value) {
      commit('setHumidty', value)
    }
  },
  mutations: {
    // set state need this
    mutate,
    setFinalTemp(state, value) {
      state.finalTemperature = value
    },
    setInitialTemp(state, value) {
      state.initialTemperature = value
    },
    setHumidity(state, value) {
      state.humidity = value
    },
    updateListTemp(state, newTemp) {
      state.temperatureSerials = state.temperatureSerials.map((val) => {
        if (val.initialTemperatures && val.initialTemperatures[0].id === newTemp.id) {
          val.initialTemperatures[0] = newTemp
        }
        return val
      })
    },
    filterTemperatures(state, newValue) {
      state.filter = newValue
    },
    setTempBusy(state, { index, value }) {
      state.busyIndicators[index] = value
    }
  },
  getters: {
    filteredTemperatures(state) {
      return filterElements(state.temperatureSerials, state.filter)
    }
  }
}
