<template>
  <div>
    <div v-if="value">
      <b-link @click="downloadRemoteFile" target="_blank">
        {{ value.original_name || 'N/D' }}
      </b-link>
    </div>
    <div v-else>Sem Arquivo</div>
  </div>
</template>

<script>
import 'moment'
import mixin from '@/features/file-download/store/mixin'

export default {
  mixins: [mixin],
  props: {
    value: Object
  },
  methods: {
    async downloadRemoteFile() {
      this.downloadFile(this.value)
    }
  }
}
</script>
