<template>
  <BusyOverlay :show="isBusy">
    <b-select
      :options="typeOptions"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    />
  </BusyOverlay>
</template>

<script>
import meterTypesClient from '@/features/meter-types/meter-type-client'

export default {
  name: 'meter-type-selector',
  computed: {
    typeOptions() {
      return [...this.defaultOptions, ...this.options]
    }
  },
  data() {
    return {
      defaultOptions: [{ text: 'Nenhum', value: null }],
      options: [],
      isBusy: false
    }
  },
  async mounted() {
    try {
      this.isBusy = true
      const payload = await meterTypesClient().list(1, 1000)
      this.options = payload.map(value => {
        return {
          text: value.name,
          value: value.id
        }
      })
    } catch (error) {
      this.isBusy = false
      throw error
    }
    if (this.emitOnMount) {
      setTimeout(() => this.$emit('input', this.selectedEntry), 400)
    }
    this.isBusy = false
  },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      required: false,
      default: 2
    },
    // eslint-disable-next-line vue/no-unused-properties
    size: {
      type: [String],
      default: 'md',
      required: false
    },
    emitOnMount: { default: true }
    // // eslint-disable-next-line vue/no-unused-properties
    // options: {
    //   type: [Array],
    //   required: false,
    //   default: () => [
    //     { text: 'A', value: 1 },
    //     { text: 'B', value: 2 },
    //     { text: 'Nenhum', value: null }
    //   ]
    // }
  }
}
</script>

<style></style>
