<template>
  <b-breadcrumb
    v-show="breadCrumbSegments?.length"
    :items="breadCrumbSegments"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  />
</template>

<script>
import breadCrumbMixin from '@/features/bread-crumbs/store/mixin'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'CBreadCrumb',
  mixins: {
    breadCrumbMixin
  },
  data() {
    return {}
  },
  async mounted() {},
  methods: {
    ...mapActions('breadCrumbs', ['updateBreadCrumb']),
    setPath(value) {
      this.updateBreadCrumb(value)
    }
  },
  computed: {
    ...mapState('breadCrumbs', ['breadCrumbSegments'])
  },
  props: {}
}
</script>

<style></style>
