// allows manage permissions in components
import { abilitiesPlugin, Can } from '@casl/vue'
import { AbilityBuilder, Ability } from '@casl/ability'

export default Vue => {
  /**
   * @param user contains details about logged in user: its id, name, email, etc
   */
  function defineAbilitiesFor(user) {
    const { rules } = new AbilityBuilder()

    return new Ability(rules)
  }
  Vue.use(abilitiesPlugin, defineAbilitiesFor(''))
  Vue.component('Can', Can)
}
