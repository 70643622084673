<template>
  <div>
    <b-overlay
      v-if="value"
      :show="isBusy"
      :spinner-small="true"
      variant="white"
      opacity="0.85"
      blur="2px"
      spinner-variant="primary"
      spinner-type="grow"
      rounded="sm"
    >
      <img :id="imgId" :src="imageData" :class="defaultClass" :style="defaultStyle" />
    </b-overlay>
    <div v-else>SEM IMAGEM</div>
  </div>
</template>

<style></style>

<script>
import 'moment'
import mixin from '@/features/file-download/store/mixin'

export default {
  watch: {
    value(newValue, oldValue) {
      this.downloadImage(newValue)
    }
  },
  computed: {
    defaultClass() {
      if (this.imgClass) {
        return this.imgClass + ' d-block ui-bordered'
      } else {
        return 'ui-w-140 d-block ui-bordered'
      }
    },
    defaultStyle() {
      if (this.imgStyle) {
        return this.imgStyle
      } else {
        return 'min-height: 120px'
      }
    }
  },
  async mounted() {
    await this.onMount()
    await this.downloadImage(this.value)
  },
  data() {
    return {
      imageData: null
    }
  },
  name: 'Base64Image',
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      required: false
    },
    imgId: {
      default: undefined
    },
    isBusy: {
      default: false
    },
    imgClass: {
      type: String,
      default: null
    },
    imgStyle: {
      type: String,
      default: null
    }
  },
  methods: {
    async onMount() {
      this.imageData = null
    },
    async downloadImage(fileModel) {
      const base64 = await this.fetchFileBase64(fileModel)
      this.imageData = 'data:image/png;base64,' + base64
    }
  }
}
</script>
