import HttpClient from '@/services/http-client'

class SamplingReportsApi extends HttpClient {
  async list(config) {
    return this.rest.get('/v1/sampling-reports', config)
  }

  async store(payload) {
    return this.rest.post('/v1/sampling-reports', payload)
  }

  async preview(payload) {
    return this.rest.get('/v1/sampling-preview', {
      params: {
        start_at: payload.startAt,
        end_at: payload.endAt
      }
    })
  }

  async previewFiscalNote(fiscalNoteId) {
    return this.rest.get('/v1/sampling-fiscal-note-preview', {
      params: {
        id: fiscalNoteId
      }
    })
  }

  async renderPDF(id) {
    const url = `/api/v1/sampling-reports/${id}/render-pdf/relatorio-de-amostragem.pdf`
    console.log('downloading url ->:', url)
    window.open(url, '_blank')
  }

  async show(id) {
    return this.rest.get('/v1/sampling-reports/' + id)
  }

  async delete(id) {
    return this.rest.delete('/v1/sampling-reports/' + id)
  }
}
export default () => new SamplingReportsApi()
