<template>
  <b-select
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    :options="complianceOptions"
  />
</template>

<script>
export default {
  name: 'non-compliance-select',
  data() {
    return {
      complianceOptions: [
        { text: 'Conforme', value: 1 },
        { text: 'Não Conforme', value: 0 },
        { text: 'Não Aplicável', value: null }
      ]
    }
  },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    size: {
      type: [String],
      default: 'md',
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    options: {
      type: [Array],
      required: false,
      default: () => [{ laable: 'Conforme', id: 1 }]
    }
  }
}
</script>

<style></style>
