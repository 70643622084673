<template>
  <div class="mw-100">
    <b-row class="px-1 py-auto">
      <b-col md="11">
        <BusyOverlay :show="meterModelBusy">
          <Multiselector
            track-by="id"
            :custom-label="formatMetterInfo"
            :hide-selected="false"
            :taggable="false"
            :allowEmpty="true"
            :options="meterModelList"
            :value="value"
            :disabled="disabled"
            @search-change="asyncFindMeterModel"
            @select="onSelectModel"
            @remove="onRemoveModel"
            @open="onOpenDropdown"
            :loading="loadingMeterModel"
            deselect-label="Remover seleção"
            selectLabel="Escolher"
            selectedLabel="Selecionado"
            tag-placeholder="Selecione a um modelo de medidor"
            placeholder="Selecione o modelo de medidor"
            v-bind="{ ...$props, ...$attrs }"
            v-on="$listeners"
          >
            <span slot="noResult">Sem modelos encontrados.</span>
          </Multiselector>
        </BusyOverlay>
      </b-col>
      <b-row class="md-1">
        <b-btn v-if="value" size="xs" class="btn-outline-danger" v-b-tooltip.top title="Remover Seleção" @click="clean">
          <span class="ion ion-md-close"></span>
        </b-btn>
        <b-btn
          size="xs"
          class="btn-outline-success"
          variant="success"
          v-b-tooltip.top
          title="Criar Modelo de Medidor"
          @click="createMeter"
        >
          <span class="ion ion-md-add"></span>
        </b-btn>
      </b-row>
    </b-row>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import meterModelMixin from '@/features/meter-models/store/mixin'

export default {
  name: 'MeterModelPicker',

  mixins: [meterModelMixin],
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    notIncludeIds: {
      type: Array,
      default: null,
      required: false
    },
    clientId: {
      type: Number,
      default: null,
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    emitOnMount: {
      default: true
    }
  },
  data() {
    return {
      selectedEntry: null,
      meterModelQuery: null,
      loadingMeterModel: false
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal?.id != oldVal?.id) {
        this.fetchModelData().then((val) => {})
      }
    }
  },
  components: {},
  async mounted() {
    if (this.emitOnMount) {
      await this.fetchModelData()
      // eslint-disable-next-line vue/no-mutating-props
      this.$emit('input', this.value)
      // eslint-disable-next-line vue/no-mutating-props
      this.$emit('value', this.value)
    }
  },
  methods: {
    async onOpenDropdown() {
      if (this.meterModelList.length <= 1) {
        await this.refreshMeterModels({ perPage: 20 })
      }
    },
    async fetchModelData() {
      if (this?.value?.id) {
        const meterModel = await this.getMeterModel(this?.value?.id)
        // eslint-disable-next-line vue/no-mutating-props
        this.value = meterModel
      }
    },
    clean() {
      // eslint-disable-next-line vue/no-mutating-props
      this.value = null
      this.$emit('input', null)
      this.$emit('onRemoveMeterModel', null)
      this.$emit('value', null)
    },
    async refreshMeterModels(config) {
      const { perPage = 20 } = config ?? {}
      await this.listModels({
        page: 1,
        perPage: perPage,
        text: this.meterModelQuery ?? null,
        clientId: this.clientId ?? null,
        notIncludeIds: this.notIncludeIds ?? null
      })
    },
    onSelectModel(value) {
      this.$emit('input', value)
      this.$emit('value', value)
      this.$emit('onSelectMeterModel', value)
    },
    onRemoveModel(value, arg2) {
      this.$emit('remove', value)
      this.$emit('value', value)
      this.$emit('onRemoveMeterModel', value)
    },
    asyncFindMeterModel(text) {
      this.meterModelQuery = text
      this.findMeterModel()
      text = ''
    },
    findMeterModel: debounce(function() {
      this.loadingMeterModel = true
      this.refreshMeterModels().then((response) => {
        this.meterModel = response
        this.loadingMeterModel = false
      })
    }, 500)
  }
}
</script>
