<template>
  <b-col>
    <b-row class="row-md-8">
      <flat-pickr
        v-model="dateRange"
        :config="dateRangeConfig"
        :placeholder="!isIEMode ? 'Selecione o Intervalo' : null"
        size="sm"
      />
    </b-row>

    <!-- // TODO: Corrigir botão de limpar valor<b-row class="mx-0 px-0 row-md-3">
      <clear-btn @click="clearValues"></clear-btn>
    </b-row> -->
  </b-col>
</template>

<style
  src="@/vendor/libs/vue-flatpickr-component/vue-flatpickr-component.scss"
  lang="scss"
></style>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
const formatStart = value =>
  moment(value).set('hours', 0).set('minutes', 0).set('seconds', 0)
// const formatEnd = value =>
//   moment(value).set('hour', 23).set('minutes', 59).set('seconds', 0)

export default {
  props: {
    value: {
      type: Array,
      default: null,
      required: false
    }
  },
  methods: {
    clearValues() {
      this.dateRange = []
      this.$emit('input', [])
    },
    onChangeValue(selectedDates, dateStr, instance) {
      this.$emit(
        'input',
        selectedDates.map(formatStart).map(val => val.toJSON())
      )
    }
  },
  name: 'DateRangePicker',
  components: {
    flatPickr
  },
  data() {
    const that = this
    return {
      dateRange: this.value,
      dateRangeConfig: {
        mode: 'range',
        altInput: true,
        animate: true,

        onChange: async (selectedDates, dateStr, instance) => {
          that.onChangeValue(selectedDates, dateStr, instance)
        },

        dateFormat: 'd/m/Y',
        altFormat: 'd/m/Y',
        locale: Portuguese
      }
    }
  }
}
</script>

<style></style>
