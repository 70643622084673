'use strict'
// Thi store depends from some plugins like CASL in Vue instance

import virtualSealsLoadAPI from '@/features/virtual-seals-load/virtual-seals-load-api'
import {
  setState,
  filterElements,
  mutate,
  doAsyncBusyTask
} from '@/helpers/store-helpers'

const busyMutateName = 'setBusy'
// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    isLoadBusy: false,
    meterError: undefined,
    filter: '',
    commitmentTerms: []
  },
  actions: {
    async fetchVirtualSealsLoad({ state, commit }) {
      try {
        commit('setBusy', true)
        const commitmentList = await virtualSealsLoadAPI().list({
          params: {
            page: 1,
            limit: 1000
          }
        })
        setState(commit, 'commitmentTerms', commitmentList.data)
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    },
    async updateVirtualSealsLoad({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = virtualSealsLoadAPI().update(payload)
          commit('updateCommitmentInList', entry)
          return entry
        }
      )
    },
    async getVirtualSealsLoad({ state, commit }, id) {
      try {
        setState(commit, 'currentTermLoaded', null)
        commit('setBusy', true)
        const commitment = await virtualSealsLoadAPI().show(id)
        setState(commit, 'currentTermLoaded', commitment)
        return commitment
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    },
    async createVirtualSealsLoad({ state, commit }, payload) {
      try {
        commit('setBusy', true)
        const createdLoad = await virtualSealsLoadAPI().store(payload)
        commit('addToCommitments', createdLoad)
      } catch (e) {
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    },
    async deleteVirtualSealsLoad({ state, commit }, item) {
      try {
        commit('setBusy', true)
        const result = await virtualSealsLoadAPI().delete(item.id)
        commit('removeCommitmentTermInList', result.id)
      } catch (error) {
        setState(commit, 'meterError', error.toString())
        throw error
      } finally {
        commit('setBusy', false)
      }
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    removeCommitmentTermInList(state, id) {
      state.commitmentTerms = state.commitmentTerms.filter(
        commitmentTerm => commitmentTerm.id !== id
      )
    },
    addToCommitments(state, load) {
      state.commitmentTerms.push(load)
    },
    setBusy(state, val = false) {
      state.isLoadBusy = val
    },
    filterTerms(state, filter = '') {
      state.filter = filter
    },
    updateCommitmentInList(state, newPayload) {
      if (state.commitmentTerms) {
        state.commitmentTerms = state.commitmentTerms.map(oldPayload => {
          if (oldPayload.id !== newPayload.id) {
            return oldPayload
          }
          return newPayload
        })
      }
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalItems(state) {
      return state.commitmentTerms.length || 0
    },
    selectedMeter(state) {
      return state.selected
    },
    virtualSealsFields(state) {
      return [
        {
          sortable: true,
          label: 'Nº do Selo Virtual',
          key: 'number'
        },
        {
          sortable: true,
          label: 'Medidor Associado',
          key: 'meterSerials'
        }
      ]
    },
    filteredCommitmentTerms(state) {
      return filterElements(state.commitmentTerms, state.filter)
    }
  }
}
