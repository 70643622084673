import HttpClient from '@/services/http-client'

class ManualInspectionService extends HttpClient {
  async needManualInspection(serviceId, noteItemId) {
    return this.rest.get(
      `/v1/services/${serviceId}/note-items/${noteItemId}/need-manual-inspections`
    )
  }

  /**
   * Atualmente endpoint só necessita de acesso ao noteItemId
   */
  async approveAllInspections(serviceId, noteItemId) {
    return (
      await this.http.post(
        `/v1/services/${serviceId}/note-items/${noteItemId}/approve-manual-inspections`
      )
    ).data
  }

  async repproveAllInspections(serviceId, noteItemId) {
    return (
      await this.http.delete(
        `/v1/services/${serviceId}/note-items/${noteItemId}/approve-manual-inspections`
      )
    ).data
  }

  async getManualInspections(serviceId, noteItemId) {
    return (
      await this.http.get(
        `/v1/services/${serviceId}/note-items/${noteItemId}/manual-inspections`
      )
    ).data
  }

  async switchManualInspections(
    serviceId,
    noteItemId,
    inspectionId,
    serialNumber,
    inspectionTypeId,
    approvedValue = undefined
  ) {
    return this.rest.patch(
      `/v1/services/${serviceId}/note-items/${noteItemId}/switch-manual-inspections/${inspectionId}`,
      null,
      {
        params: {
          serial_number: serialNumber,
          inspection_type_id: inspectionTypeId,
          approved: approvedValue
        }
      }
    )
  }

  async listEloTypes() {
    return (await this.http.get('/v1/elos')).data
  }

  async listInspectionTypes() {
    return (await this.http.get('/v1/inspection-types')).data
  }
}

export default () => {
  return new ManualInspectionService()
}
