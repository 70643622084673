'use strict'

import serviceApi from '@/features/services/av-service-api'
import { filterElements } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    service_list: [],
    services: [],
    filter: ''
  },
  actions: {
    async createService({ state, commit }, payload) {
      try {
        commit('isBusy')
        const created = await serviceApi().store(payload)
        commit('addService', created)
        return created
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async updateService({ state, commit }, payload) {
      commit('isBusy')
      try {
        const updated = await serviceApi().update(payload)
        commit('updateListRef', updated)
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async changeServiceStatus({ state, commit }, payload) {
      commit('isBusy')
      try {
        const updated = await serviceApi().changeServiceStatus(payload)
        commit('updateListRef', updated)
      } catch (e) {
        console.error(e)
      } finally {
        commit('isBusy', false)
      }
    },
    async fetchServicesList({ state, commit }, id) {
      try {
        commit('isBusy')
        const services = await serviceApi().list({ typeId: id })
        commit('setServicesList', services.data)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('isBusy', false)
      }
    },
    async getServiceById({ state, commit }, id) {
      const payload = await serviceApi().get(id)
      return payload
    },
    async deleteService({ state, commit }, id) {
      const result = await serviceApi().delete(id)
      if (result) await commit('removeFromList', id)
    }
  },
  getters: {
    totalServices(state) {
      return (state.services || []).length
    },
    errorMessage(state) {
      return state.errMessage
    },
    filteredServices(state) {
      // filter our data
      return filterElements(state.service_list, state.filter)
    },
    hasNewServices(state) {
      let check
      try {
        if (this.isFirstTime) {
          check = undefined
        } else {
          check = state.services.length > 3
        }
      } catch (e) {
        return false
      }
      return check
    }
  },
  mutations: {
    isBusy(state, val = true) {
      state.isBusy = val
    },
    setServicesList(state, service) {
      state.service_list = service
    },
    setFilter(state, filter) {
      console.log('new filter', filter)
      state.filter = filter.toLowerCase()
    },
    removeFromList(state, payload) {
      state.service_list = state.service_list.filter((value) => value.id !== payload)
    },
    addService(state, data) {
      state.service_list.push(data)
    },
    setInitial(state, service) {
      state.initialService = service
    },
    setFinal(state, service) {
      state.finalService = service
    },
    updateListRef(state, update) {
      if (state.service_list) {
        state.service_list = state.service_list.map((val) => {
          if (val.id === update.id) {
            return update
          }
          return val
        })
      }
    },
    updateServices(state, services) {
      state.service_list = services || []
    },
    resetServicesState(state, _) {
      state.initialService = null
      state.finalService = null
    }
  }
}
