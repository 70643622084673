var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "switcher switcher-sm mr-0",
    attrs: {
      "disabled": _vm.disabled
    }
  }, [_c('input', {
    staticClass: "switcher-input",
    attrs: {
      "id": _vm.id,
      "type": "checkbox",
      "disabled": _vm.disabled
    },
    domProps: {
      "checked": _vm.isChecked
    },
    on: {
      "change": _vm.change
    }
  }), _vm._m(0), _c('span', {
    staticClass: "switcher-label"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "switcher-indicator"
  }, [_c('span', {
    staticClass: "switcher-yes"
  }), _c('span', {
    staticClass: "switcher-no"
  })]);
}]

export { render, staticRenderFns }