var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', [_c('b-row', {
    staticClass: "row-md-8"
  }, [_c('flat-pickr', {
    attrs: {
      "config": _vm.dateRangeConfig,
      "placeholder": !_vm.isIEMode ? 'Selecione o Intervalo' : null,
      "size": "sm"
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }