<template>
  <div class="mw-100">
    <b-row class="px-2 py-auto">
      <Multiselector
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
        track-by="id"
        label="number"
        data-testid="test-table-picker"
        :custom-label="formatExibitionText"
        :hide-selected="false"
        :taggable="false"
        :options="testTableList"
        :value="value"
        :disabled="disabled"
        @search-change="onSearchModel"
        @select="onSelectModel"
        @remove="onRemoveModel"
        deselect-label="Remover seleção"
        selectLabel="Escolher"
        selectedLabel="Selecionado"
        tag-placeholder="Escolha uma opção"
        placeholder="Escolha uma opção"
      />
    </b-row>
  </div>
</template>

<script>
import testTableMixin from '@/features/test-table/store/mixin'
export default {
  name: 'TestTablePicker',
  mixins: [testTableMixin],
  props: {
    value: {
      type: Object,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    emitOnMount: {
      default: true
    }
  },
  components: {},
  async mounted() {
    await this.listTestTable({ page: 1, perPage: 1000 })
    if (this.emitOnMount) {
      // eslint-disable-next-line vue/no-mutating-props
      this.onSelectModel(this.value)
    }
  },
  data() {
    return {}
  },
  methods: {
    onSearchModel(value) {
      this.searchTestTable(value, this.filters)
    },
    onSelectModel(modelSelected, elementId) {
      // eslint-disable-next-line vue/no-mutating-props
      this.value = modelSelected
      this.$emit('input', modelSelected)
      this.$emit('select', modelSelected)
    },
    onRemoveModel(arg1, arg2) {
      this.$emit('remove', arg1)
    },
    formatExibitionText(T) {
      return `${T?.model ?? 'NI'} serie:${T?.serial ?? 'NI'} fab.: ${T?.manufactor?.name ?? 'NI'} - #${T?.id}`
    },
    async searchTestTable(search, filters = []) {
      await this.listTestTable({ text: search })
    }
  }
}
</script>
