import HttpClient from '@/services/http-client'
class SealsAPI extends HttpClient {
  // Retorna selos criado pelo usuário atual ou que ele seja responsável
  async list({ page, limit, q }) {
    return (
      await this.http.get('/v1/seals', {
        params: {
          page: page ?? 1,
          limit: limit ?? null,
          q: q ?? null
        }
      })
    ).data
  }

  async delete(sealID) {
    return (await this.http.delete('/v1/seals/' + sealID)).data
  }

  async setDamaged(sealID, value = false) {
    return (
      await this.http.patch('/v1/seals/' + sealID, {
        is_damaged: value
      })
    ).data
  }
}
export default () => new SealsAPI()
