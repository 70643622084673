'use strict'
// Thi store depends from some plugins like CASL in Vue instance
import meterModelsApi from '@/features/meter-models/meter-models-api'
import { filterElements, setState, mutate } from '@/helpers/store-helpers'
import manualInspectionsApi from '@/features/manual-inspections/manual-inspections-api'
import energyDirectionsApi from '@/features/energy-directions/energy-directions-api'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    meterModelBusy: false,
    models: [],
    manufactors: [],
    meterToEdit: null,
    connection_types: [],
    inspection_types: [],
    energy_directions: [],
    rated_voltages: [],
    elo_types: [{ id: null, name: 'Selecione o Tipo' }],
    rated_currents: [],
    meter_classes: [],
    meter_types: [],
    meterModelFilter: '',
    meterModelTotalRows: 0,
    meterModelCurrentPage: 1,
    meterModelPerPage: 10,
    meterModelLastPage: 0,
    meterModelList: [],
    meterError: undefined
  },
  actions: {
    async listModels({ state, commit }, payload = {}) {
      const {
        page = 1,
        perPage = 20,
        text = state?.meterModelFilter ?? '',
        notIncludeIds = null
      } = payload
      await commit('isBusy', true)
      try {
        setState(commit, 'meterModelFilter', text)
        const data = await meterModelsApi().list({
          page,
          perPage,
          text,
          notIncludeIds
        })

        setState(commit, 'meterModelTotalRows', data.total)
        setState(commit, 'meterModelCurrentPage', data.page)
        setState(commit, 'meterModelPerPage', data.perPage)
        setState(commit, 'meterModelLastPage', data.lastPage)

        setState(commit, 'models', data.data ?? [])
        setState(commit, 'meterModelList', data.data ?? [])
      } catch (e) {
        console.error(e)
        commit('mutate', { key: 'meterError', value: e.toString() })
      } finally {
        commit('isBusy', false)
      }
    },
    async loadFields({ state, commit }) {
      try {
        commit('isBusy', true)

        let response = await meterModelsApi().fetchRatedVoltages()
        setState(commit, 'rated_voltages', response)

        response = await manualInspectionsApi().listEloTypes()
        response.unshift({ id: null, name: 'Selecione o Elo' })
        setState(commit, 'elo_types', response)

        response = await meterModelsApi().fetchRatedCurrents()
        setState(commit, 'rated_currents', response)

        response = await meterModelsApi().fetchConnectionTypes()
        setState(commit, 'connection_types', response)

        response = await manualInspectionsApi().listInspectionTypes()
        setState(commit, 'inspection_types', response)

        response = await meterModelsApi().fetchMeterClasses()
        setState(commit, 'meter_classes', response)

        response = await meterModelsApi().fetchMeterTypes()
        setState(commit, 'meter_types', response)

        response = await energyDirectionsApi().list()
        setState(commit, 'energy_directions', response.data)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('isBusy', false)
      }
    },
    async createVoltage({ state, commit }, voltage) {
      try {
        commit('isBusy', true)
        const newRatedVoltage = await meterModelsApi().createVoltage(voltage)
        commit('isBusy', false)

        commit('addRatedVoltage', newRatedVoltage)
        return newRatedVoltage
      } catch (error) {
        commit('showError', error.message)
        console.log(error)
      } finally {
        commit('isBusy', false)
      }
    },
    async createCurrent({ state, commit }, voltage) {
      try {
        commit('isBusy', true)
        const newCurrent = await meterModelsApi().createCurrent(voltage)
        commit('isBusy', false)

        commit('addRatedCurrents', newCurrent)
        return newCurrent
      } catch (error) {
        commit('showError', error.message)
        throw error
      } finally {
        commit('isBusy', false)
      }
    },
    async createModel({ state, commit, dispatch }, meterModel) {
      try {
        commit('isBusy', true)
        const data = await meterModelsApi().createMeter(meterModel)
        commit('addModel', data)
        dispatch('listModels')
      } catch (error) {
        commit('showError', error.message)
        commit('isBusy', false)
        throw error
      }
      commit('isBusy', false)
    },
    async updateModel({ state, commit, dispatch }, meterModel) {
      try {
        commit('isBusy', true)
        const data = await meterModelsApi().updateModel(meterModel)
        commit('addModel', data)
        dispatch('listModels')
      } catch (error) {
        commit('isBusy', false)
        throw error
      }
      commit('isBusy', false)
    },
    async deleteMeterModel({ state, commit }, meterModel) {
      try {
        commit('isBusy', true)
        const data = await meterModelsApi().delete(meterModel.id)
        commit('removeModel', data)
      } catch (error) {
        commit('showError', error.message)
        throw error
      } finally {
        commit('isBusy', false)
      }
    },
    async openMeterEditor({ state, commit }, meter) {
      try {
        commit('isBusy', true)
        setState(commit, 'meterToEdit', meter)
      } catch (error) {
        commit('showError', error)
        throw error
      } finally {
        commit('isBusy', false)
      }
    },
    async getMeterModel({ state, commit }, id) {
      try {
        commit('isBusy', true)
        const meter = await meterModelsApi().show(id)
        setState(commit, 'meterToEdit', meter)
        return meter
      } catch (error) {
        commit('showError', error)
        throw error
      } finally {
        commit('isBusy', false)
      }
    },
    async asyncFilterModels({ state, commit, dispatch }, opts = {}) {
      const { text = null, perPage = 20 } = opts
      try {
        await dispatch('listModels', {
          text,
          perPage
        })
        commit('isBusy')
        setState(commit, 'meterModelFilter', text)
      } catch (error) {
        commit('showError', error)
        throw error
      } finally {
        commit('isBusy', false)
      }
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    isBusy(state, val = false) {
      state.meterModelBusy = val
    },
    addModel(state, model) {
      state.models.push(model)
    },
    removeModel(state, model) {
      state.models = state.models.filter(met => met.id !== model.id)
    },
    filterModels(state, filter) {
      state.meterModelFilter = filter.toLowerCase()
    },
    addRatedVoltage(state, value) {
      state.rated_voltages.push(value)
    },
    addRatedCurrents(state, value) {
      state.rated_currents.push(value)
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalItems(state) {
      return state.models.length || 0
    },
    connectionTypes(state) {
      return [
        { id: null, name: 'Selecione um Tipo de Ligação' },
        ...state.connection_types
      ]
    },
    selectedMeter(state) {
      return state.selected
    },
    sheetFields(state) {
      return {}
    },
    meterClassesOptions(state) {
      return [{ id: null, name: 'Selecione a Classe' }, ...state.meter_classes]
    },
    meterTypesOptions(state) {
      return [{ id: null, name: 'Selecione um Tipo' }, ...state.meter_types]
    },
    filteredModels(state) {
      return filterElements(state.models, state.meterModelFilter)
    }
  }
}
