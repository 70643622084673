const { isNil } = require('lodash')
const moment = require('moment')
const dateFn = function (format = 'DD/MM/YYYY HH:mm') {
  /**
   * @param {string} val
   */
  return function (dateObject) {
    let formatted = null
    if (dateObject) {
      // incomming date need to be on ISO JSON format
      if (moment(dateObject).isValid()) {
        formatted = moment(dateObject).format(format)
      } else if (moment(dateObject, 'DD/MM/YYYY HH:mm').isValid()) {
        formatted = moment(dateObject, 'DD/MM/YYYY HH:mm').format(format)
      }
    }
    // create --/--/---- placeholder
    return formatted || '--'
  }
}
module.exports = {
  /**
   * @param {string} format - The format to use for the date on table
   */
  dateFn,
  dateOnlyFn: (format = 'DD/MM/YYYY') => dateFn(format),
  ndInvalidValue(val) {
    return isNil(val) ? 'N/D' : val
  },
  extractorFileFn: (val) => {
    switch (val) {
      case 'ce-inspection-per-responsible':
        return 'Inspeções por Responsável'
      case 'ce-meters-zip':
        return 'Zip de PDFs'
      case 'ce-inspection-entry-note':
        return 'Excel de Inspeções por Nota (Finalizados)'
      case 'ce-meters-entry-note':
        return 'Excel por Nota Fiscal'
      case 'ce-meters-pdf':
        return 'PDF de Medidores Filtrados'
      default:
        return val
    }
  }
}
