var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BusyOverlay', {
    attrs: {
      "show": _vm.isBusy
    }
  }, [_c('b-select', _vm._g(_vm._b({
    attrs: {
      "options": _vm.typeOptions
    }
  }, 'b-select', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }