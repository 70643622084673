'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import virtualSealService from '@/features/virtual-seals/virtual-seals-api'
import { debounce } from 'lodash'

const busyMutateName = 'setVirtualSealsBusy'

export default {
  namespaced: true,
  state: {
    virtualSealList: [],
    virtualSealFilter: '',
    virtualSealIsBusy: false,
    virtualSealTotalRows: 0,
    virtualSealCurrentPage: 1,
    virtualSealPerPage: 10,
    virtualSealLastPage: 0
  },
  actions: {
    debounceListVirtualSeals: debounce(({ dispatch, commit }, payload) => {
      dispatch('listVirtualSeals', payload)
    }, 1000),
    async listVirtualSeals({ state, commit }, payload = {}) {
      const {
        page = 1,
        perPage = 100,
        text = '',
        compromise_number = null,
        serial_number = null,
        virtual_seal_number = null,
        sealing_date_range = null
      } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await virtualSealService().list(
            page,
            perPage,
            text,
            compromise_number,
            serial_number,
            virtual_seal_number,
            sealing_date_range
          )
          setState(commit, 'virtualSealTotalRows', payload.total)
          setState(commit, 'virtualSealCurrentPage', payload.page)
          setState(commit, 'virtualSealPerPage', payload.perPage)
          setState(commit, 'virtualSealLastPage', payload.lastPage)

          commit('setVirtualSealsList', payload.data)
        }
      )
    },
    async showVirtualSeal({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return virtualSealService().show(id)
        }
      )
    },
    async createVirtualSeal({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = virtualSealService().create(payload)
          commit('unshiftVirtualSeal', entry)
        }
      )
    },
    async updateVirtualSeal({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = virtualSealService().update(payload)
          commit('updateVirtualSealInList', entry)
          return entry
        }
      )
    },
    async deleteVirtualSeal({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await virtualSealService().delete(id)
          if (result) await commit('removeVirtualSealInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredVirtualSeals(state) {
      return filterElements(state.virtualSealList, state.filter)
    }
  },
  mutations: {
    mutate,
    setVirtualSealsList(state, payload) {
      state.virtualSealList = payload
    },
    setVirtualSealsFilter(state, payload) {
      state.virtualSealList = payload
    },
    setVirtualSealsBusy(state, value = false) {
      state.virtualSealIsBusy = value
    },
    unshiftVirtualSeal(state, payload) {
      state.virtualSealList.unshift(payload)
    },
    removeVirtualSealInList(state, payload) {
      state.virtualSealList = state.virtualSealList.filter(
        virtualSeal => virtualSeal.id !== payload
      )
    },
    updateVirtualSealInList(state, payload) {
      if (state.virtualSealList) {
        state.virtualSealList = state.virtualSealList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
