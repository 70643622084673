var render = function () {
  var _this$serviceMeterSer,
    _this$serviceMeterSer2,
    _this$serviceMeterSer3,
    _this$serviceMeterSer4,
    _this$serviceMeterSer5,
    _this$serviceMeterSer6,
    _this$serviceMeterSer7,
    _this$serviceMeterSer8,
    _this = this,
    _this$serviceMeterSer12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "px-0 pb-0 print-card-border",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-col', {
    staticClass: "print-preview",
    attrs: {
      "px-0": "",
      "pb-0": "",
      "v-b-toggle": 'report-preview-' + (this === null || this === void 0 ? void 0 : (_this$serviceMeterSer = this.serviceMeterSerial) === null || _this$serviceMeterSer === void 0 ? void 0 : _this$serviceMeterSer.id)
    }
  }, [_c('b', [_vm._v(" Prévia do Laudo "), _c('a', {
    attrs: {
      "href": '/expert-mode-inspection/' + (this === null || this === void 0 ? void 0 : (_this$serviceMeterSer2 = this.serviceMeterSerial) === null || _this$serviceMeterSer2 === void 0 ? void 0 : _this$serviceMeterSer2.id),
      "target": "_blank"
    }
  }, [_vm._v(" (" + _vm._s(this.serviceMeterSerial.serial_number) + "): ")])]), _c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "size": "xs",
      "variant": "primary",
      "title": "Atualizar Valores do Laudo"
    },
    on: {
      "click": _vm.reloadLastPreview
    }
  }, [_c('i', {
    staticClass: "ion ion-md-refresh"
  })]), this !== null && this !== void 0 && (_this$serviceMeterSer3 = this.serviceMeterSerial) !== null && _this$serviceMeterSer3 !== void 0 && (_this$serviceMeterSer4 = _this$serviceMeterSer3.service) !== null && _this$serviceMeterSer4 !== void 0 && (_this$serviceMeterSer5 = _this$serviceMeterSer4.fiscalNote) !== null && _this$serviceMeterSer5 !== void 0 && _this$serviceMeterSer5.nf_number ? _c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "size": "xs",
      "variant": "outline-secondary",
      "title": 'Filtrar pela nota desse medidor:' + (this === null || this === void 0 ? void 0 : (_this$serviceMeterSer6 = this.serviceMeterSerial) === null || _this$serviceMeterSer6 === void 0 ? void 0 : (_this$serviceMeterSer7 = _this$serviceMeterSer6.service) === null || _this$serviceMeterSer7 === void 0 ? void 0 : (_this$serviceMeterSer8 = _this$serviceMeterSer7.fiscalNote) === null || _this$serviceMeterSer8 === void 0 ? void 0 : _this$serviceMeterSer8.nf_number)
    },
    on: {
      "click": function click() {
        var _this$serviceMeterSer9, _this$serviceMeterSer10, _this$serviceMeterSer11;
        return _this.$emit('nfFilter', _this === null || _this === void 0 ? void 0 : (_this$serviceMeterSer9 = _this.serviceMeterSerial) === null || _this$serviceMeterSer9 === void 0 ? void 0 : (_this$serviceMeterSer10 = _this$serviceMeterSer9.service) === null || _this$serviceMeterSer10 === void 0 ? void 0 : (_this$serviceMeterSer11 = _this$serviceMeterSer10.fiscalNote) === null || _this$serviceMeterSer11 === void 0 ? void 0 : _this$serviceMeterSer11.nf_number);
      }
    }
  }, [_c('i', {
    staticClass: "ion ion-md-funnel"
  })]) : _vm._e(), _c('b-btn', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "size": "xs",
      "variant": "primary",
      "title": "Imprimir este laudo"
    },
    on: {
      "click": _vm.print
    }
  }, [_c('i', {
    staticClass: "ion ion-md-document"
  })])], 1), _c('hr', {
    staticClass: "p-0 m-0 mb-2 print-separator pagebreak"
  }), _c('b-col', {
    staticClass: "m-0 p-0",
    attrs: {
      "id": 'report-preview-' + (this === null || this === void 0 ? void 0 : (_this$serviceMeterSer12 = this.serviceMeterSerial) === null || _this$serviceMeterSer12 === void 0 ? void 0 : _this$serviceMeterSer12.id)
    }
  }, [_c('BusyOverlay', {
    attrs: {
      "show": _vm.previewBusy
    }
  }, [_c('lazy-component', {
    staticClass: "print-lazy"
  }, [_vm.reportPreview ? _c('iframe', {
    ref: "iframePreview",
    attrs: {
      "frameBorder": "0",
      "srcdoc": _vm.reportPreview,
      "scaling": "no",
      "height": "1280px",
      "width": "100%"
    },
    on: {
      "onload": _vm.iframeLoaded
    }
  }) : _c('b-row', [_c('b-col', {
    staticClass: "align-center m-auto",
    staticStyle: {
      "color": "red"
    }
  }, [_c('span', [_vm._v(" Prévia indisponível ")])])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }