import HttpClient from '@/services/http-client'

class TestSamplesService extends HttpClient {
  async show(id) {
    return this.rest.get('/v1/test-samples/' + id)
  }

  async list({
    page = 1,
    limit = 1000,
    text = null,
    fiscal_note_item_id = null
  }) {
    return this.rest.get('/v1/test-samples', {
      params: {
        page,
        limit,
        fiscal_note_item_id,
        q: text
      }
    })
  }

  async create(payload) {
    return this.rest.post('/v1/test-samples', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/test-samples/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/test-samples/' + id)
  }
}

export default () => new TestSamplesService()
