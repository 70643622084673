<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
    <!-- Sidenav toggle -->
    <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <a
      class="nav-item nav-link px-0 ml-2 ml-lg-0"
      href="javascript:void(0)"
      title="Abrir Busca (Ctrl + K)"
      b-v-tooltip.hover
      @click="toggleSearchModal"
    >
      <i class="ion ion-md-book text-large align-middle" />
    </a>

    <b-modal id="modals-fill-in" size="large" modal-class="modal-fill-in" ref="searchModal" hide-header hide-footer>
      <p class="text-white text-large font-weight-light mb-3">
        Buscar um Módulo Especifico
      </p>
      <b-input-group size="lg" class="mb-3">
        <b-input
          ref="textFilter"
          placeholder="Digite o nome do módulo"
          class="bg-white border-0"
          v-model="filterText"
          @keyup.enter="openFirstEntry"
        />
        <b-input-group-btn slot="right">
          <b-btn variant="primary">Buscar</b-btn>
        </b-input-group-btn>
      </b-input-group>
      <div class="text-center text-right text-white opacity-60">
        Ao selecionar irá abrir na aba atual (salve suas alterações antes !)
      </div>
      <b-card>
        <b-list-group>
          <template v-for="entry in filteredItens">
            <template v-if="entry.divider"> </template>
            <template v-else-if="isSearchEntryActive(entry.to)">
              <b-list-group-item :key="entry.id" class="mb-1" active>
                <div class="d-flex justify-content-between w-100">
                  <h5 class="mb-1">
                    {{ entry.name }}
                  </h5>
                  <a tabindex="-1">Página Atual</a>
                </div>
                <small>{{ entry.to }}</small>
              </b-list-group-item>
            </template>
            <template v-else-if="entry?.can">
              <Can :I="entry.can" :key="entry.id">
                <b-list-group-item :href="entry.to" :key="entry.id" class="mb-1">
                  <div class="d-flex justify-content-between w-100">
                    <h5 class="mb-1">
                      {{ entry.name }}
                    </h5>
                    <a href="#" tabindex="-1">Abrir Módulo</a>
                  </div>
                  <small>{{ entry.to }}</small>
                </b-list-group-item>
              </Can>
              <Can not :I="entry.can" :key="entry.id">
                <b-list-group-item href="javascript:void(0)" :key="entry.id" disabled class="mb-1">
                  <div class="d-flex justify-content-between w-100">
                    <h5 class="mb-1">
                      {{ entry.name }}
                    </h5>
                    <a disabled>Bloqueado</a>
                  </div>
                  <small>(Sem permissão para acessar)</small>
                </b-list-group-item>
              </Can>
            </template>
            <template v-else>
              <b-list-group-item :href="entry.to" :key="entry.id" class="mb-1">
                <div class="d-flex justify-content-between w-100">
                  <h5 class="mb-1">
                    {{ entry.name }}
                  </h5>
                  <a href="#" tabindex="-1">Abrir Módulo</a>
                </div>
                <small>{{ entry.to }}</small>
              </b-list-group-item></template
            >
          </template>
        </b-list-group>
      </b-card>
    </b-modal>

    <!-- Navbar toggle -->
    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <b-navbar-nav class="align-items-lg-center"> </b-navbar-nav>
    </b-collapse>
    <!-- Divider -->

    <b-nav-item-dropdown :right="true" class="demo-navbar-user" style="z-index: 1">
      <template slot="button-content">
        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">Bem Vindo, {{ userName }}</span>
        </span>
      </template>
      <a @click="goToProfile">
        <b-dd-item><i class="ion ion-md-settings text-lightest"></i> &nbsp; Minha Conta</b-dd-item>
      </a>
      <b-dd-divider />
      <b-dd-item @click="doLogout"> <i class="ion ion-ios-log-out text-danger"></i> &nbsp; Sair </b-dd-item>
      <b-dd-item @click="reloadWindow">
        <b-btn v-if="hasNewVersion" size="xs" variant="success">
          Atualizar Versão: {{ $store.getters.appVersion }} para {{ nextVersion }}
        </b-btn>
        <b-btn v-else size="xs" variant="secondary">Versão Atual: {{ $store.getters.appVersion }}</b-btn>
      </b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar>
</template>

<style scoped>
li:last-child {
  list-style-type: none;
}
</style>

<script>
import preventFakeLogin from '../mixins/prevent-fake-login'
import menuEntries from '../menu-entries'
import authMixin from '@/features/authentication/store/mixin'
import { filterElements } from '@/helpers/store-helpers'
import HttpClient from '@/services/http-client'

export default {
  mixins: [preventFakeLogin, authMixin],
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      menuEntries: [],
      hasNewVersion: false,
      nextVersion: null,
      filterText: null
    }
  },
  created() {
    this.fetchUserInfo() // loads user info whe login
    this.menuEntries = this.flattenArray(menuEntries)
  },
  async mounted() {
    this?.layoutHelpers?.init()
    this?.layoutHelpers?.update()
    this?.layoutHelpers?.setAutoUpdate(true)

    window.addEventListener('keydown', this.handleKeydown)
    const that = this
    setTimeout(async () => {
      if (that.$router.currentRoute.fullPath === '/') {
        try {
          const health = await new HttpClient().rest.get('/health')
          if (health?.version !== that?.$store?.getters?.appVersion) {
            this.hasNewVersion = true
            this.nextVersion = health.version
            that.showToastUpdate(health.version)
          } else {
            this.hasNewVersion = false
          }
        } catch (error) {
          that.$bvToast.toast('Essa falha pode não impactar o uso (' + error.toString() + ')', {
            title: 'Falha ao checar versão',
            autoHideDelay: 2000,
            appendToast: false,
            toastClass: 'bs4-toast bg-warning'
          })
        }
      }
    }, 10000)
  },
  beforeDestroy() {
    this?.layoutHelpers?.destroy()
    window.removeEventListener('keydown', this.handleKeydown)
  },
  computed: {
    filteredItens(state) {
      return filterElements(this.menuEntries, this.filterText)
    }
  },
  methods: {
    showToastUpdate(version) {
      // Use a shorter name for this.$createElement
      const h = this.$createElement
      // Increment the toast count
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'], on: { click: this.reloadWindow } }, [
        h('b-spinner', { props: { type: 'grow', small: true } }),
        'Clique ',
        h('strong', 'aqui'),
        ' para atualizar a versão. ',
        h('b-spinner', { props: { type: 'grow', small: true } }),
        h('p', { class: 'm-0' }, ['(Ou utilize Ctrl + F5)'])
      ])
      // Create the title
      const vNodesTitle = h('div', { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] }, [
        h('strong', { class: 'mr-2' }, 'Atualização disponível !'),
        h('small', { class: 'ml-auto text-italics' }, 'v' + version)
      ])
      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        appendToast: false,
        autoHideDelay: 10000,
        variant: 'success',
        toastClass: 'bs4-toast bg-success'
      })
    },
    reloadWindow() {
      window.location.reload(true)
    },
    toggleSidenav() {
      this?.layoutHelpers?.toggleCollapsed()
    },
    openFirstEntry(event) {
      if (this?.filteredItens[0]?.to) {
        this.$router.push(this.filteredItens[0].to)
      }
    },
    handleKeydown(event) {
      if (event.ctrlKey && event.key === 'k') {
        event.preventDefault()
        this.toggleSearchModal()
      }
    },
    toggleSearchModal() {
      this.$refs.searchModal.toggle()
      const t = this
      setTimeout(() => {
        t.$refs.textFilter.focus()
        t.filterText = null
      }, 100)
    },
    flattenArray(array) {
      let result = []
      for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i].children)) {
          result = result.concat(this.flattenArray(array[i].children))
        } else {
          result.push(array[i])
        }
      }
      return result
    },
    openSearch() {
      this?.layoutHelpers?.toggleCollapsed()
    },

    isSearchEntryActive(url) {
      return this.$route.path.endsWith(url) === true
    },
    goToProfile() {
      this.$router.push('/usuarios/' + this.userInfo.id)
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg
    }
  }
}
</script>
