import HttpClient from '@/services/http-client'
import ValidationException from '@/features/exceptions/ValidationException'

class UserAPI extends HttpClient {
  async permissions() {
    return this.rest.get('/v1/me/permissions')
  }

  async getUserInfo() {
    return this.rest.get('/v1/me')
  }

  async list(page = 1, limit = 1000, text = null, filters = null) {
    return this.rest.get('/v1/users', {
      params: {
        page,
        limit,
        q: text,
        filters
      },
      cache: {
        maxAge: 10000,
        ignoreCache: false,
        exclude: { query: false }
      }
    })
  }

  async get(id) {
    return this.rest.get('/v1/users/' + id)
  }

  async update(payload) {
    return this.rest.patch('/v1/users/' + payload.user.id, payload)
  }

  async store(payload) {
    try {
      return this.rest.post('/v1/users/', payload)
    } catch (error) {
      // adiciona o id do selo a mensagem de erro
      if (error.response.status === 400) {
        throw new ValidationException(
          'Alguns campos estão inválidos',
          error.response.data
        )
      }
    }
  }

  async delete(id) {
    return this.rest.delete('/v1/users/' + id)
  }
}
export default () => new UserAPI()
