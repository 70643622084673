export default {
  methods: {
    showErrorMessage: function (error) {
      return this.$swal(
        'Falha !',
        'Houve uma falha por favor contato o administrador do sistema, código do erro [' +
          (error?.code ?? 'Unknown') +
          '].',
        'error'
      )
    },
    showDoneModal: async function ({ title }) {
      await this.$swal(title ?? 'Feito !', '', 'success')
    },
    showPromptModal({
      title = 'Tem certeza?',
      text = 'Essa ação não poderá ser revertida!',
      html,
      confirmButtonText = 'Continuar',
      cancelButtonText = 'Cancelar',
      icon,
      onAccept = () => {
        this.$swal('Feito !', '', 'success')
      },
      onDecline = () => {},
      onError = () => {}
    }) {
      return this.$swal({
        title: title,
        text: text ?? null,
        html: html ?? null,
        icon: icon ?? 'warning',
        showCancelButton: true,
        confirmButtonColor: '#26B4FF',
        denyButtonColor: '$secondary',
        cancelButtonColor: '#d9534f',
        confirmButtonText,
        cancelButtonText
      }).then(result => {
        if (result.isConfirmed) {
          try {
            onAccept()
          } catch (error) {
            this.showErrorMessage(error)
            onError(error)
            throw error
          }
        } else {
          onDecline()
        }
      })
    },
    showDeleteModal: function ({
      title = 'Tem certeza?',
      text = 'Você irá apagar o item, e isso não poderá ser desfeito!',
      icon = 'warning',
      onAccept = () => {},
      onDecline = () => {},
      onError = () => {}
    }) {
      return this.$swal({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        confirmButtonColor: '#26B4FF',
        denyButtonColor: '#8897AA',
        cancelButtonColor: '#d9534f',
        allowOutsideClick: false,
        denyButtonText: 'Não',
        cancelButtonText: 'Apagar'
      }).then(async result => {
        try {
          if (result.isDismissed) {
            await onAccept()
            return this.$swal('Apagado!', 'Foi apagado com sucesso.', 'success')
          } else {
            return onDecline()
          }
        } catch (error) {
          await this.showErrorMessage(error)
          await onError(error)
          throw error
        }
      })
    },
    async confirmSomething(args) {
      return this.$swal(
        Object.assign(
          {
            title: 'Tem certeza?',
            text: 'Você não poderá desfazer essa ação!',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            showDenyButton: false,
            confirmButtonColor: '#26B4FF',
            denyButtonColor: '#d9534f',
            // cancelButtonColor: '',
            confirmButtonText: '<i class="ion ion-md-add"></i> Sim, Criar!',
            cancelButtonText: 'Cancelar'
          },
          args
        )
      )
    },
    async showLostChanges(args) {
      return this.$swal(
        Object.assign(
          {
            title: 'Cancelar Edição?',
            text: 'Ao cancelar perderá todas informações digitadas!',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            showDenyButton: false,
            cancelButtonColor: '$secondary',
            confirmButtonColor: '#d9534f',
            // cancelButtonColor: '',
            confirmButtonText:
              '<i class="ion ion-md-close"></i> Perder Alterações!',
            cancelButtonText: 'Cancelar'
          },
          args
        )
      )
    }
  }
}
