const calcCheckDigit = require('../math/find-check-digit')
const onlyNumbers = require('../../../utils/extractNumbers')

function generateSealsInterval({ from, to, sumDigit = 4 }) {
  from = onlyNumbers(from)
  to = onlyNumbers(to)

  const generated = []
  for (let currentSeal = from; currentSeal <= to; currentSeal++) {
    const digitCode = calcCheckDigit(currentSeal, sumDigit)
    generated.push(currentSeal + '' + digitCode)
  }
  return generated
}

module.exports = generateSealsInterval
