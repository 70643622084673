<template>
  <div class="mw-100">
    <b-row class="px-2 py-auto">
      <Multiselector
        :id="'settings-model-multiselect'"
        :disabled="disabled"
        track-by="id"
        label="name"
        :custom-label="formatExibitionText"
        :hide-selected="false"
        :taggable="false"
        :allowEmpty="false"
        :options="modelsFound"
        :value="value"
        @search-change="onSearchModel"
        @select="onSelectModel"
        @remove="onRemoveModel"
        deselect-label="Remover seleção"
        selectLabel="Escolher"
        class="mw-100"
        selectedLabel="Selecionado"
        tag-placeholder="Escolha uma opção"
        placeholder="Escolha uma opção"
      />
    </b-row>
  </div>
</template>

<script>
import settingsApi from '@/features/settings/settings-api'

export default {
  name: 'user-picker',
  mixins: [],
  props: {
    disabled: {
      type: [Number, Boolean, Object, null, undefined],
      default: false,
      required: false
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
      required: false
    },
    filters: {
      type: Array,
      default: null,
      required: false
    },
    setting: {
      default: null,
      required: true
    }
  },
  components: {},
  async mounted() {
    await this.searchModels('', this.filters)
  },
  data() {
    return {
      modelsFound: [],
      selectedEntry: null
    }
  },
  methods: {
    onSearchModel(value) {
      this.searchModels(value, this.filters)
    },
    onSelectModel(modelSelected, elementId) {
      this.$emit('input', { value: modelSelected })
      this.$emit('select', { value: modelSelected })
    },
    onRemoveModel(arg1, arg2) {
      this.$emit('remove', { value: null })
      console.log('onRemoveModel', arg1, arg2)
    },
    formatExibitionText(entry) {
      return (
        entry[this.setting.model_id_field] +
        ' - ' +
        entry[this.setting.model_name_field]
      )
    },
    async searchModels(search, filters = []) {
      const foundModels = await settingsApi().searchModels(this.setting.id, {
        search,
        filters
      })
      this.modelsFound = foundModels.data
    }
  }
}
</script>
