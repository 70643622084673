var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sidenav', {
    class: _vm.curClasses,
    attrs: {
      "orientation": _vm.orientation
    }
  }, [_c('div', {
    staticClass: "app-brand py-3"
  }, [_c('img', {
    staticClass: "app-brand-logo ui-w-40",
    attrs: {
      "src": "".concat(_vm.$store.getters.appUrl, "static/icone-3c.png"),
      "alt": "Logo da 3C Services"
    },
    on: {
      "click": _vm.toggleSidenav
    }
  }), _c('a', {
    staticClass: "app-brand-text sidenav-text text-big ml-2",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v(" 3C Ômega"), _c('br'), _c('Can', {
    attrs: {
      "I": "create_users"
    }
  }, [_c('span', {
    staticClass: "text-tiny font-weight-semibold opacity-50"
  }, [_vm._v("Administrativo")])]), _c('Can', {
    attrs: {
      "not": "",
      "I": "create_users"
    }
  }, [_c('span', {
    staticClass: "text-tiny font-weight-semibold opacity-50"
  }, [_vm._v("Operação")])])], 1)]), _c('div', {
    staticClass: "sidenav-divider my-0"
  }), _c('LeftMenuTree', {
    attrs: {
      "tree": _vm.menuEntries,
      "orientation": _vm.orientation
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }