import HttpClient from '@/services/http-client'

class MeterSerialsApi extends HttpClient {
  async list() {
    return this.rest.get('/v1/meter-serials')
  }

  async get(id) {
    return this.rest.get('/v1/meter-serials/' + id)
  }

  async delete(id) {
    return this.rest.delete('/v1/meter-serials/' + id)
  }

  async update(id, payload) {
    return this.rest.patch('/v1/meter-serials/' + id, payload)
  }

  async listFromServiceItem(id, stateslug) {
    return this.rest.get('/v1/service-items/' + id + '/meter-serials')
  }

  async uploadReport(
    selectedFile,
    serviceID,
    onUploadProgress = progressEvent => {}
  ) {
    const config = {
      timeout: 60000
    }

    if (onUploadProgress) config.onUploadProgress = onUploadProgress

    const fd = new FormData()
    fd.append('file', selectedFile.file, selectedFile.file.name)

    return this.rest.post(
      `/v1/service-items/${serviceID}/table-reports`,
      fd,
      config
    )
  }
}
export default () => new MeterSerialsApi()
