import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  mounted() {},
  methods: {
    createMeter() {
      window.open(this.$router.resolve('/meter-models/novo').href, '_blank')
    },
    ...mapActions('meterModels', [
      'listModels',
      'loadFields',
      'createModel',
      'updateModel',
      'openMeterEditor',
      'deleteMeterModel',
      'createVoltage',
      'createCurrent',
      'getMeterModel',
      'asyncFilterModels'
    ]),
    ...mapMutations('meterModels', ['cleanErrors', 'markFoundError', 'filterModels'])
  },
  computed: {
    ...mapState('meterModels', [
      'models',
      'meterModelBusy',
      'inspection_types',
      'rated_voltages',
      'rated_currents',
      'energy_directions',
      'elo_types',
      'connection_types',
      'meterToEdit',
      'meterModelList',
      'meterModelFilter',
      'meterModelPerPage',
      'meterModelCurrentPage',
      'meterModelTotalRows',
      'meterModelLastPage'
    ]),
    ...mapGetters('meterModels', [
      'meterClassesOptions',
      'meterTypesOptions',
      'filteredModels',
      'totalItems',
      'connectionTypes',
      'sheetFields'
    ])
  }
}
