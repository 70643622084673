<template>
  <span :id="id">
    <div v-if="permission && path">
      <component v-if="text" :is="componentParentName" :I="permission">
        <b-link :title="text" v-b-tooltip.hover="true" @click="openLink">{{ formattedText }}</b-link>
      </component>
      <span v-else> N/I</span>
      <component v-if="text" :is="componentParentName" not :I="permission">
        <span :title="text + ' (sem permissão)'" v-b-tooltip.hover="true">{{ formattedText }}</span>
      </component>
      <span v-else> N/I</span>
    </div>
    <div v-else>
      <span :title="text" v-b-tooltip.hover="true">{{ formattedText }}</span>
    </div>
  </span>
</template>

<script>
export default {
  name: 'long-string-link',
  props: {
    id: {
      default: undefined
    },
    // eslint-disable-next-line vue/no-unused-properties
    text: {
      type: [String],
      default: null,
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    path: {
      type: [String],
      default: null,
      required: false
    },
    permission: {
      type: [String],
      default: null,
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    length: {
      type: [Number],
      required: false,
      default: () => 15
    }
  },
  computed: {
    formattedText() {
      return this.text?.substring(0, this.length) + (this.text?.length > this.length ? '...' : '')
    },
    componentParentName() {
      return this.permission ? 'Can' : 'div'
    }
  },
  methods: {
    async openLink() {
      if (this.path && this.text) {
        this.$router.push(this.path)
      }
    }
  }
}
</script>

<style></style>
