'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import testSamplesService from '@/features/test-samples/test-samples-api'

const busyMutateName = 'setTestSamplesBusy'

export default {
  namespaced: true,
  state: {
    testSamplesList: [],
    testSamplesFilter: '',
    testSamplesIsBusy: false,
    testSamplesTotalRows: 0,
    testSamplesCurrentPage: 1,
    testSamplesPerPage: 10,
    testSamplesLastPage: 0
  },
  actions: {
    async listTestSamples({ state, commit }, options = {}) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await testSamplesService().list(options)
          setState(commit, 'testSamplesTotalRows', payload.total)
          setState(commit, 'testSamplesCurrentPage', payload.page)
          setState(commit, 'testSamplesPerPage', payload.perPage)
          setState(commit, 'testSamplesLastPage', payload.lastPage)

          commit('setTestSamplesList', payload.data)
        }
      )
    },
    async showTestSamples({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return testSamplesService().show(id)
        }
      )
    },
    async createTestSamples({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = testSamplesService().create(payload)
          commit('unshiftTestSamples', entry)
        }
      )
    },
    async updateTestSamples({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = testSamplesService().update(payload)
          commit('updateTestSamplesInList', entry)
          return entry
        }
      )
    },
    async deleteTestSamples({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await testSamplesService().delete(id)
          if (result) await commit('removeTestSamplesInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredTestSamples(state) {
      return filterElements(state.testSamplesList, state.filter)
    }
  },
  mutations: {
    mutate,
    setTestSamplesList(state, payload) {
      state.testSamplesList = payload
    },
    setTestSamplesFilter(state, payload) {
      state.testSamplesList = payload
    },
    setTestSamplesBusy(state, value = false) {
      state.testSamplesIsBusy = value
    },
    unshiftTestSamples(state, payload) {
      state.testSamplesList.unshift(payload)
    },
    removeTestSamplesInList(state, payload) {
      state.testSamplesList = state.testSamplesList.filter(
        testSamples => testSamples.id !== payload
      )
    },
    updateTestSamplesInList(state, payload) {
      if (state.testSamplesList) {
        state.testSamplesList = state.testSamplesList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
