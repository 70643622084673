import 'sweetalert2/dist/sweetalert2.min.css'
import VueSweetalert2 from 'vue-sweetalert2'
// custom modals
import errorAlertModal from '@/mixins/error-modal-mixin'
import infoAlertModal from '@/mixins/info-modal-mixin'
import successAlertModal from '@/mixins/success-modal-mixin'
import promptModal from '@/mixins/prompt-modal-mixin'

export default Vue => {
  Vue.use(VueSweetalert2)
  Vue.mixin(errorAlertModal)
  Vue.mixin(infoAlertModal)
  Vue.mixin(successAlertModal)
  Vue.mixin(promptModal)
}
