/**
 * Este arquivo tem por finalidade fazer o tratamento dos erros
 * para  exibi-los adequadamente para o usuário.
 */

import nProgress from 'nprogress'
const router = require('@/router')

function debugWarn(...args) {
  if (process.env.NODE_ENV === 'development') {
    console.warn(...args)
  }
}

function shouldIgnoreError(err) {
  const IS_UNDER_400_STATUS = err?.response?.status >= 400 && err?.response?.status < 500
  const errString = err?.toString() ?? ''

  if (
    errString.includes('layoutHelpers') ||
    errString.includes('not a function') ||
    errString.match(/jwt must be provided/im)
  ) {
    return false
  }

  if (errString.includes('ER_ROW_IS_REFERENCED_2')) {
    return true
  }
  if (IS_UNDER_400_STATUS && (Array.isArray(err?.response?.data) || err?.response?.data?.message)) {
    return true
  }

  return false
}

function fixMessageDescription(message) {
  if (!message) {
    return 'Sem detalhes do erro'
  }
  if (/:\s/gim.test(message)) {
    return message.split(':')[1]
  }
  return message
}

function normalizeErrorName(errorName) {
  if (!errorName) {
    return 'Sem detalhes do erro'
  }
  if (/Domain/gim.test(errorName)) {
    return 'Regra de Negócio'
  }
  return errorName
}

function fixMessageCode(message) {
  if (!message) {
    return 'Sem detalhes do erro'
  }
  if (/:\s/gim.test(message)) {
    return message.split(':')[0]
  }
  return message
}

function normalizeErrorStatus(error) {
  return `${normalizeErrorName(error.name)} : ${fixMessageCode(error.message)}`
}

function getIconFromExceptionName(data) {
  if (Array.isArray(data)) {
    return 'warning'
  } else if (data?.message && data?.status === 403) {
    return 'warning'
  } else if (data?.error?.name === 'DomainException') {
    return 'warning'
  }
  return 'error'
}
function getTitleFromExceptionName(data) {
  if (Array.isArray(data)) {
    return 'Validação:'
  } else if (data?.message && data?.status === 403) {
    return 'Falha ao realizar ação'
  } else if (data?.error?.name && data?.error?.name?.includes('Exception')) {
    return 'Exceção'
  }
  debugWarn('getTitleFromExceptionName(data)', data)
  return 'Houve uma falha inesperada'
}

function getHtmlFromError(data) {
  if (Array.isArray(data)) {
    return (
      'Não foi possível completar ação, detalhes:</br> </br> <b>' +
      data.map((val) => fixMessageDescription(val.message)).join('</br>') +
      '</b>'
    )
  }
  if (data?.message && data?.status === 403) {
    return data?.message?.split(': ')[1].trim()
  } else if (data?.message) {
    return `Detalhes do erro:
</br>
<small>
<p>${data.message}</p>
</small>`
  } else if (data?.error?.message && data?.error?.name && data?.error?.status) {
    return `<p>
${fixMessageDescription(data.error.message)}
</br>
<small>
${normalizeErrorStatus(data.error)}
</small>
</p>`
  } else {
    return `Ocorreu uma falha inesperada, consulte o administrador do sistema, detalhes do erro:
</br>
<p>${JSON.stringify(data)}</p>`
  }
}

/**
 * Description
 * @param {PromiseRejectionEvent} error
 */
function extractErrorInfo(eventOrError) {
  let statusCode = null
  const message = null
  let httpPayload = null

  statusCode =
    eventOrError?.reason?.response?.status ??
    eventOrError?.xhr?.status ?? // error
    eventOrError?.response?.status ?? // error
    eventOrError?.request?.status // vueerrorhandler
  //
  debugWarn(eventOrError?.response?.status ? 'eventOrError?.response?.status' : '')
  //
  debugWarn(eventOrError?.request?.status ? 'eventOrError?.request?.status' : '')
  //
  debugWarn(eventOrError?.xhr?.status ? 'eventOrError?.xhr?.status' : '')
  //
  debugWarn(eventOrError?.reason?.response?.status ? 'eventOrError?.reason?.response?.status' : '')
  //
  debugWarn('status', statusCode)

  httpPayload = eventOrError?.reason?.response?.data ?? eventOrError?.response?.data

  if (eventOrError?.xhr?.response) {
    httpPayload = JSON.parse(eventOrError?.xhr?.response)
  }

  const isNotFoundResponse = statusCode === 404
  const isUnauthorized = statusCode === 401
  const isUserFriendly = statusCode >= 400 && statusCode <= 499
  const shouldIgnore = shouldIgnoreError(eventOrError)

  const title = getTitleFromExceptionName(httpPayload)
  const html = getHtmlFromError(httpPayload)
  const icon = getIconFromExceptionName(httpPayload)
  debugWarn('sweetalertpayload', JSON.stringify({ title, html, icon }))
  return {
    statusCode,
    message,
    httpPayload,
    isNotFoundResponse,
    isUserFriendly,
    isUnauthorized,
    shouldIgnore,
    sweetAlert: { title, html, icon }
  }
}

export default (Vue) => {
  function showAlert(...args) {
    return Vue.prototype.$swal(...args)
  }
  function doUnauthorizedNavigation(err) {
    if (!err?.request?.url?.endsWith('/me') && err?.request?.url?.endsWith('/permissions')) {
      if (router.default.currentRoute.path !== '/login') {
        router.default.push('/login')
      }
    }
  }
  const showResourceNotFoundDialog = function () {
    // first stop the progress
    showAlert({
      title: 'Ops',
      html: 'Não foi possível completar a ação:</br> O recurso requisitado não foi encontrado.',
      icon: 'error'
    })
  }

  window.onerror = function (msg, url, line, col, error) {
    // first stop the progress
    nProgress.done()
    const errorInfo = extractErrorInfo(error)
    if (errorInfo.isUserFriendly) {
      showAlert(errorInfo.sweetAlert)
      return
    }

    console.error('window.onerror =>', error)
  }

  window.addEventListener('unhandledrejection', function (event) {
    // first stop the progress
    nProgress.done()
    // handle error here
    // event.promise contains the promise object
    // event.reason contains the reason for the rejection

    const errorInfo = extractErrorInfo(event)
    if (errorInfo.isUnauthorized) {
      doUnauthorizedNavigation(event)
      return
    }
    if (errorInfo.isNotFoundResponse) {
      showResourceNotFoundDialog()
      return
    } else if (errorInfo.isUserFriendly) {
      showAlert(errorInfo.sweetAlert)
      return
    }
    console.error("window.addEventListener('unhandledrejection')", event)
  })
  Vue.config.errorHandler = function (err, vm, info) {
    nProgress.done()
    const errorInfo = extractErrorInfo(err)

    // erros que são considerados toleráveis só são logados no console

    if (!errorInfo.isUserFriendly) {
      console.warn('-- NON UI ERROR (Vue.config.errorHandler) --')
      console.warn(err)
      return
    }

    if (errorInfo.isUnauthorized) {
      doUnauthorizedNavigation(err)
      return
    }
    if (errorInfo.isNotFoundResponse) {
      showResourceNotFoundDialog()
      return
    }
    if (errorInfo.isUserFriendly) {
      showAlert(errorInfo.sweetAlert)
      return
    }
    nProgress.done()
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    console.error('[Vue.config.errorHandler]', err)
  }
  return Vue
}
