import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('reportPreviews', [
      'listReportPreviews',
      'fetchReportPreviewHTML',
      'deleteReportPreview',
      'createReportPreview',
      'updateReportPreview'
    ]),
    ...mapMutations('reportPreviews', [
      'cleanReportPreviewsErrors',
      'setReportPreviewsList',
      'setReportPreviewsFilter'
    ])
  },
  computed: {
    ...mapState('reportPreviews', [
      'reportPreviewIsBusy',
      'reportPreviewList',
      'reportPreviewFilter',
      'reportPreviewPerPage',
      'reportPreviewCurrentPage',
      'reportPreviewTotalRows',
      'reportPreviewLastPage'
    ]),
    ...mapGetters('reportPreviews', ['filteredReportPreviews'])
  }
}
