import { saveAs } from 'file-saver'
import HttpClient from '@/services/http-client'

class StoredFilesApi extends HttpClient {
  async fetchFileBase64(file) {
    if (!file?.id) return null
    return this.rest.get(`/v1/stored-files/${file?.id}?as_base64=true`)
  }

  async downloadFile(file) {
    const response = await this.fetchFileBase64(file)
    const byteCharacters = window.atob(response)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {
      type: file.content_type
    })
    await saveAs(blob, file.original_name)
  }

  async downloadStoredFileNewWindow(file) {
    window.open('/api/v1/stored-files/' + file.id + '?original_name=' + file.original_name)
  }

  async store({ filename = null, base64 = null, blob = null }) {
    // eslint-disable-next-line no-unreachable
    if (blob) {
      const fd = new FormData()
      fd.append('file', blob, filename)
      console.log('Uploaded File', fd)
      return this.rest.post('/v1/stored-files', fd)
    } else if (base64 && filename) {
      return this.rest.post(
        '/v1/stored-files',
        { filename, base64 },
        {
          timeout: 3600000
        }
      )
    }
  }
}

export default function () {
  return new StoredFilesApi()
}
