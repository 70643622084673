var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('multiselect', _vm._g(_vm._b({
    staticClass: "multiselect-primary",
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(props) {
        return [_vm._t("option", null, {
          "props": props.option
        })];
      }
    }, {
      key: "singleLabel",
      fn: function fn(props) {
        return [_vm._t("singleLabel", null, {
          "props": props.option
        })];
      }
    }], null, true)
  }, 'multiselect', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Nada encontrado. Considere alterar o texto de busca.")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }