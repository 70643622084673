'use strict'

import fiscalNoteItemApi from '@/features/service-items/service-items-api'
import { setState, mutate, isBusy } from '@/helpers/store-helpers'

export default {
  namespaced: true,
  state: {
    serviceSummary: null,
    isBusyService: false
  },
  actions: {
    async getServiceSummary({ commit, dispatch }, { id }) {
      setState(commit, 'isBusyService', true)
      try {
        console.log('Service loaded fiscal note item', id)
        const data = await fiscalNoteItemApi().get(id)
        setState(commit, 'serviceSummary', data ?? null)
      } catch (e) {
        setState(commit, 'isBusyService', false)
        throw e
      } finally {
        setState(commit, 'isBusyService', false)
      }
    },
    async refreshService({ commit, dispatch, state }) {
      setState(commit, 'isBusyService', true)
      try {
        await dispatch('getServiceSummary', {
          id: state?.serviceSummary?.id ?? null
        })
      } catch (e) {
        setState(commit, 'isBusyService', false)
        throw e
      } finally {
        setState(commit, 'isBusyService', false)
      }
    }
  },
  getters: {},
  mutations: {
    mutate,
    isBusy,
    setServiceSummary(state, serviceSummary) {
      state.serviceSummary = serviceSummary
    }
  }
}
