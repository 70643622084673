var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mw-100"
  }, [_c('b-row', {
    staticClass: "px-2 py-auto"
  }, [_c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "number",
      "data-testid": "test-table-picker",
      "custom-label": _vm.formatExibitionText,
      "hide-selected": false,
      "taggable": false,
      "options": _vm.testTableList,
      "value": _vm.value,
      "disabled": _vm.disabled,
      "deselect-label": "Remover seleção",
      "selectLabel": "Escolher",
      "selectedLabel": "Selecionado",
      "tag-placeholder": "Escolha uma opção",
      "placeholder": "Escolha uma opção"
    },
    on: {
      "search-change": _vm.onSearchModel,
      "select": _vm.onSelectModel,
      "remove": _vm.onRemoveModel
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }