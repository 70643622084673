<template>
  <span>
    <PermissiveBtn size="xs" variant="danger" title="Apagar Entrada" titleBlocked="Sem permissão para apagar" :can="can"
      :disabled="disabled" @click="onClick" v-b-tooltip.hover v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
      Apagar
    </PermissiveBtn>
  </span>
</template>

<style></style>

<script>
export default {
  name: "DeleteBtn",
  props: {
    can: {
      default: null,
    },
    showSuccessAlert: { default: false },
    // eslint-disable-next-line vue/no-unused-properties
    disabled: {
      default: false,
    },
  },
  methods: {
    async onClick(event) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$listeners.hasOwnProperty("askDelete")) {
        const result = await this.$swal({
          title: "Tem certeza?",
          text: "Você irá apagar, e não poderá ser desfeito!",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          showDenyButton: false,
          cancelButtonColor: "#8897AA",
          confirmButtonColor: "#d9534f",
          // cancelButtonColor: '',
          confirmButtonText: '<i class="ion ion-md-trash"></i> Apagar!',
          cancelButtonText: "Cancelar",
        });
        if (result.isConfirmed) {
          await this.$emit("askDelete", event);
          if (this.showSuccessAlert) {
            await this.$swal("Feito!", "Apagado com sucesso", "success");
          }
        }
      }
      return this.$emit("click", event);
    },
  },
};
</script>
