import moment from 'moment'
import HttpClient from '@/services/http-client'

import { saveAs } from 'file-saver'

class MeterSerialReportExporter extends HttpClient {
  async downloadReportFromSerial(
    fiscalNoteItemId,
    meterSerial,
    reportTypeSlug
  ) {
    const fileName =
      'relatorio_' +
      meterSerial.serial_number +
      '_' +
      moment().format('DD-MM-YYYY_hh:mm')
    const result = await this.http.get(
      `/v1/service-items/${fiscalNoteItemId}/meter-serial/${meterSerial.id}/generate-report`,
      {
        params: {
          reportTypeSlug
        }
      }
    )
    const byteCharacters = window.atob(result.data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    await saveAs(blob, fileName + '.xlsx')
  }
}

export default () => new MeterSerialReportExporter()
