var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "sidenav",
    class: 'sidenav-' + _vm.orientation
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }