<template>
  <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
    <template v-for="menuItem in tree">
      <template v-if="menuItem?.children?.length">
        <sidenav-menu
          :icon="'sidenav-icon ' + menuItem.icon"
          :open="isGroupOpen(menuItem)"
          :key="menuItem.id"
        >
          <template slot="link-text">{{ menuItem.name }}</template>
          <LeftMenuTree :tree="menuItem.children" :key="menuItem.id">
          </LeftMenuTree>
        </sidenav-menu>
      </template>
      <template v-else-if="menuItem?.divider">
        <sidenav-divider class="mt-0" :key="menuItem.id"></sidenav-divider>
      </template>
      <template v-else>
        <template v-if="menuItem.can">
          <!-- With permission restrictions -->
          <Can :I="menuItem.can" :key="menuItem.id">
            <sidenav-router-link
              :id="menuItem.to"
              :icon="menuItem.icon"
              :to="menuItem.to ?? '#'"
              :active="isMenuActive(menuItem.to)"
              :exact="true"
            >
              {{ menuItem.name }}<br />
            </sidenav-router-link>
          </Can>
          <!-- Exibe botão mesmo quando não houver permissões para saber que a seção existe -->
          <Can not :I="menuItem.can" :key="menuItem.id">
            <sidenav-router-link
              v-b-tooltip.top
              title="Você não possui permissão para visualizar essa seção"
              :id="menuItem.to"
              :icon="menuItem.icon"
              to=""
              disabled
              :exact="true"
            >
              {{ menuItem.name }}<br />
            </sidenav-router-link>
          </Can>
        </template>
        <template v-else>
          <sidenav-router-link
            :id="menuItem.to"
            :icon="menuItem.icon"
            :to="menuItem.to ?? '#'"
            :exact="true"
            :key="menuItem.id"
          >
            {{ menuItem.name }} <br />
          </sidenav-router-link>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from '@/vendor/libs/sidenav'
import LeftMenuTree from './LeftMenuTree.vue'

export default {
  name: 'LeftMenuTree',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    /* eslint-enable vue/no-unused-components */
    LeftMenuTree
  },
  props: {
    tree: {
      default: []
    },
    orientation: { default: 'vertical' }
  },
  data: function () {
    return {}
  },
  methods: {
    isMenuActive(url) {
      return this.$route.path.endsWith(url) === true
    },
    isGroupOpen(group) {
      for (const { to } of group.children) {
        if (this.$route.path.includes(to ?? 'null')) return true
      }
      return this.$route.path.endsWith(group?.to ?? 'null') === true
    }
  }
}
</script>
