/**
 * Coalescing operator from ES2019
 *
 * `const result = variableA ?? whenNull`
 * @param {*} variableA
 * @param {*} whenNull
 * @returns
 */
export function coaOp(variableA, whenNull = null) {
  if (variableA !== null) {
    return variableA
  }
  return whenNull
}
// FIXME: Remover esses operadores de todo o código
/**
 * Optional Operator from ES2019  const result = variableA?.id
 * @param {*} variableA
 * @param {*} propName - name of property to access and check null
 * @param {*} whenNull
 * @returns
 */
export function optOp(variableA, propName = 'id', defaultValue = null) {
  if (variableA !== null && variableA !== undefined && variableA[propName]) {
    return variableA[propName]
  }
  return defaultValue
}
