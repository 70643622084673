export function getPagesModules() {
  const loadedPages = []
  const scriptFiles = require.context('./pages', true, /\.(js)$/i)

  // Para cada script de rota encontrada faça o mapeamento
  for (const key of scriptFiles.keys()) {
    // const name = key.match(/(\w+)\.js/im)[1]
    // const object = {
    //   name: name,
    //   default: scriptFiles(key).default
    // }
    loadedPages.push(scriptFiles(key).default)
  }

  console.log('[LOADED-PAGE-ROUTES]', loadedPages.length)
  return loadedPages
}
