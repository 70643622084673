module.exports = [
  {
    name: 'Inicio',
    icon: 'ion ion-md-desktop',
    to: '/'
  },
  {
    name: 'Extratores',
    icon: 'ion ion-md-copy',
    children: [
      {
        name: 'Relatórios de Amostragem',
        to: '/relatorios-de-amostragem',
        can: 'read_ipem_report',
        icon: 'ion ion-md-copy'
      },
      {
        name: 'Relatórios IPEM',
        to: '/relatorios-ipem',
        can: 'read_ipem_report',
        icon: 'ion ion-ios-document'
      },
      {
        name: 'Extrator Modo Perito',
        to: '/expert-mode-extractor',
        can: 'read_expert_mode',
        icon: 'ion ion-ios-document'
      }
    ]
  },
  {
    name: 'Selos / TRS / Cargas Virtuais',
    icon: 'ion ion-md-barcode',
    children: [
      {
        can: 'read_trs',
        icon: 'ion ion-md-copy',
        name: "TRS's",
        to: '/trss'
      },
      {
        can: 'read_seals',
        icon: 'ion ion-md-barcode',
        name: 'Selos',
        to: '/selos'
      },
      {
        can: 'read_calibration_certificates',
        icon: 'ion ion-md-cash',
        name: 'Carga de Selos Virtuais',
        to: '/carga-de-selos-virtuais'
      },
      {
        can: 'read_virtual_seals',
        icon: 'ion ion-md-barcode',
        name: 'Selos Virtuais',
        to: '/selos-virtuais'
      }
    ]
  },
  {
    name: 'Certificados / Calibração Mesas',
    icon: 'ion ion-ios-copy ',
    children: [
      {
        can: 'read_calibration_certificates',
        icon: 'ion ion-md-document',
        name: 'Certificados de Calibração',
        to: '/certificados-de-calibracao'
      },
      {
        can: 'read_temperature_certificates',
        icon: 'ion ion-md-thermometer',
        name: 'Certificados de Temperatura',
        to: '/certificados-de-temperatura'
      },
      {
        can: 'read_temperature_certificates',
        icon: 'ion ion-md-speedometer',
        name: 'Bancadas de Aferições',
        to: '/test-tables'
      }
    ]
  },
  {
    name: 'Modelos / Fabricantes / Anomalias',
    icon: 'ion ion-md-speedometer',
    children: [
      {
        can: 'read_meter_models',
        to: '/meter-models',
        name: 'Modelos de Medidores',
        icon: 'ion ion-md-speedometer'
      },
      {
        can: 'read_meter_models',
        to: '/error-thresholds',
        name: 'Margens de Tolerância',
        icon: 'ion ion-md-calculator'
      },

      {
        can: 'read_manufactors',
        icon: 'ion ion-md-business',
        name: 'Fabricantes',
        to: '/manufactors'
      },
      {
        can: 'read_regulatory_ordinances',
        icon: 'ion ion-md-book',
        name: 'Portarias',
        to: '/regulatory-ordinances'
      },

      { divider: true },

      {
        can: 'read_meter_pieces',
        icon: 'ion ion-ios-build',
        name: 'Peças de Medidores',
        to: '/meter-pieces'
      },
      {
        can: 'read_anomaly_types',
        icon: 'ion ion-ios-create',
        name: 'Tipos de Anomalias',
        to: '/anomaly-types'
      },

      {
        can: 'read_anomalies',
        icon: 'ion ion-md-clock',
        name: 'Anomalias de Medidores',
        to: '/anomalias'
      }
    ]
  },
  {
    name: 'Administrativo',
    icon: 'ion ion-md-settings',
    children: [
      {
        can: 'read_clients',
        icon: 'ion ion-md-business',
        name: 'Empresas Clientes',
        to: '/clientes'
      },
      {
        can: 'read_users',
        icon: 'ion ion-ios-contact',
        name: 'Usuários',
        to: '/usuarios'
      },
      {
        can: 'read_roles',
        icon: 'ion ion-ios-lock',
        name: 'Cargos com Permissões',
        to: '/cargos'
      },
      {
        can: 'read_sys_settings',
        icon: 'ion ion-md-settings',
        name: 'Configurações do Sistema',
        to: '/configuracoes'
      }
    ]
  }
]
