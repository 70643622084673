var render = function () {
  var _vm$breadCrumbSegment;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-breadcrumb', _vm._g(_vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm$breadCrumbSegment = _vm.breadCrumbSegments) === null || _vm$breadCrumbSegment === void 0 ? void 0 : _vm$breadCrumbSegment.length,
      expression: "breadCrumbSegments?.length"
    }],
    attrs: {
      "items": _vm.breadCrumbSegments
    }
  }, 'b-breadcrumb', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }