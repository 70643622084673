'use strict'
import diacritics from 'diacritics'
import { isNil } from 'lodash'

/**
 *
 * DESIGN NOTES
 * This need to be used side by side with `mutate` mutation inside store in store-helpers
 * @param {*} commit
 * @param {*} key
 * @param {*} value
 */

export function setState(commit, key, value) {
  return commit('mutate', { key, value })
}

/**
 *   ```
 *   // needed be used in stores in order setState works
 *   mutate(state, { key, value }) {
 *     state[key] = value
 *   }
 *   ```
 * @param {*} commit
 * @param {*} key
 * @param {*} value
 */
export function mutate(state, { root, key, value }) {
  if (root) {
    state[root][key] = value
    return state[root][key]
  } else {
    state[key] = value
    return state[key]
  }
}

export function isBusy(state, val = true) {
  state.isBusy = val
  return val
}
const transformObjectInText = function (object) {
  if (object) {
    return Object.keys(object)
      .map((key) => (typeof object[key] === 'object' ? transformObjectInText(object[key]) : String(object[key])))
      .join('|')
  } else return ''
}

export function filterElements(array, filterText, options = { cleanDiacritics: false }) {
  // filter our data
  if (array && array.length > 0) {
    if (!filterText) {
      return array
    }
    filterText = filterText.toLowerCase()
    const filteredEntries = array.filter((object) => {
      // Concat data
      if (object) {
        if (options.cleanDiacritics) {
          const joinedRow = transformObjectInText(object)
          return diacritics.remove(joinedRow).toLowerCase().indexOf(diacritics.remove(filterText)) !== -1 || !filterText
        } else {
          const joinedRow = transformObjectInText(object)
          return joinedRow.toLowerCase().indexOf(filterText) !== -1 || !filterText
        }
      } else {
        return null
      }
    })
    // update the rows
    return filteredEntries
  } else {
    return []
  }
}

// TODO Criar padrão para reaproveitar código de filtor em diferentes
// propriedades do state com generate function
// export function removeModelFromArr(propName, state)(modelId)

// const lista = [{ id: 1 }, { id: 2 }]

// function filterByPropMutation(nomeDaProp, nomeArray) {
//   const funcaoGerada = (state, objetoBase) => {~
//     throw !objetoBase new ObjetoBaseInvalidException
//     return lista.filter(
//       entrada => entrada[nomeDaProp] === objetoBase[nomeDaProp]
//     )
//   }

//   return funcaoGerada
// }
// console.log(funcaoGera('id')(lista, { id: 2 }))
// // ->> [ { id: 1 } ]

export function calculatePageCount(totalQuantity, perPage) {
  try {
    return parseInt((totalQuantity + perPage - 1) / perPage)
  } catch (e) {
    console.warn(e)
    return 1
  }
}

export async function doAsyncBusyTask({ commit = null, state = null, busyMutateName = 'setIsBusy' }, customFunc) {
  if (!commit) {
    throw new Error('You need pass commit function to doAsyncBusyTask')
  }
  let finalValue = null
  try {
    commit(busyMutateName, true)
    finalValue = await customFunc({ commit, state, busyMutateName })
    commit(busyMutateName, false)
  } catch (error) {
    commit(busyMutateName, false)
    throw error
  } finally {
    commit(busyMutateName, false)
  }
  return finalValue
}

export function determineChangeType(before, after) {
  let result = { type: null, old: before, new: after }
  if (isNil(before) && after?.id) {
    result = { type: 'create', old: before, new: after }
  } else if (!isNil(before?.id) && !isNil(after?.id) && before.id !== after.id) {
    result = { type: 'change', old: before, new: after }
  } else if (!isNil(before) && isNil(after)) {
    result = { type: 'delete', old: before, new: after }
  } else {
    result = { type: null, old: before, new: after }
  }

  return result
}
