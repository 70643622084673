<template>
  <b-card no-body class="px-0 pb-0 print-card-border">
    <b-col
      px-0
      pb-0
      class="print-preview"
      :v-b-toggle="'report-preview-' + this?.serviceMeterSerial?.id"
    >
      <b>
        Prévia do Laudo
        <a
          :href="'/expert-mode-inspection/' + this?.serviceMeterSerial?.id"
          target="_blank"
        >
          ({{ this.serviceMeterSerial.serial_number }}):
        </a>
      </b>
      <b-btn
        size="xs"
        @click="reloadLastPreview"
        variant="primary"
        v-b-tooltip.top
        title="Atualizar Valores do Laudo"
      >
        <i class="ion ion-md-refresh"></i>
      </b-btn>
      <b-btn
        v-if="this?.serviceMeterSerial?.service?.fiscalNote?.nf_number"
        size="xs"
        @click="
          () =>
            this.$emit(
              'nfFilter',
              this?.serviceMeterSerial?.service?.fiscalNote?.nf_number
            )
        "
        variant="outline-secondary"
        v-b-tooltip.top
        :title="
          'Filtrar pela nota desse medidor:' +
          this?.serviceMeterSerial?.service?.fiscalNote?.nf_number
        "
      >
        <i class="ion ion-md-funnel"></i>
      </b-btn>
      <b-btn
        size="xs"
        @click="print"
        variant="primary"
        v-b-tooltip.top
        title="Imprimir este laudo"
      >
        <i class="ion ion-md-document"></i>
      </b-btn>
    </b-col>
    <hr class="p-0 m-0 mb-2 print-separator pagebreak" />
    <b-col :id="'report-preview-' + this?.serviceMeterSerial?.id" class="m-0 p-0">
      <BusyOverlay :show="previewBusy">
        <lazy-component class="print-lazy">
          <iframe
            ref="iframePreview"
            frameBorder="0"
            v-if="reportPreview"
            :srcdoc="reportPreview"
            scaling="no"
            height="1280px"
            width="100%"
            @onload="iframeLoaded"
          ></iframe>
          <b-row v-else>
            <b-col style="color: red" class="align-center m-auto">
              <span> Prévia indisponível </span>
            </b-col>
          </b-row>
        </lazy-component>
      </BusyOverlay>
    </b-col>
  </b-card>
</template>
<style lang="scss">
@media print {
  .print-lazy {
    overflow: visible !important;
  }
  .print-preview {
    display: none !important;
    visibility: hidden !important;
  }
  .pagebreak {
    page-break-before: always;
  }
  .print-separator {
    display: none;
    visibility: hidden;
  }
  .print-card-border {
    border: 0px;
  }
}
</style>
<script>
import reportPreviewsMixin from "@/features/report-previews/store/mixin";

export default {
  props: {
    serviceMeterSerial: {
      default: null,
    },
    show_signature: {
      default: false,
    },
  },
  mixins: [reportPreviewsMixin],
  data() {
    return {
      previewBusy: false,
      reportPreview: null,
      lastPreview: null,
    };
  },
  async mounted() {
    await this.loadReportHTML();
  },
  watch: {
    show_signature(newVal) {
      this.reloadLastPreview();
    },
  },
  methods: {
    print() {
      const iframe = this.$refs.iframePreview;
      const iframeWindow = iframe.contentWindow;
      iframeWindow.print();
    },
    async cleanHTMLData() {
      this.previewBusy = true;
      this.reportPreview =
        '<span style="color:red"> DADOS VAZIOS (recarregue ou clique no botão atualizar acima) <span>';
      this.previewBusy = false;
    },
    async reloadLastPreview() {
      this.loadReportHTML(this.lastPreview);
    },
    async loadReportHTML(previewData) {
      const that = this;
      try {
        this.previewBusy = true;
        const {
          service_id = this.serviceMeterSerial.service_id,
          service_meter_serial_id = this.serviceMeterSerial.id,
          show_signature = this.show_signature,
          force_refresh = this.force_refresh,
        } = previewData ?? {};
        this.reportPreview = null;
        this.lastPreview = previewData;
        const htmlPreview = await that.fetchReportPreviewHTML({
          service_id,
          service_meter_serial_id,
          show_signature,
          force_refresh,
          show_report_date: false,
        });
        this.reportPreview = htmlPreview;
        this.previewBusy = false;
      } catch (err) {
        if (err?.response?.status === 422) {
          this.reportPreview = `<span style="color:red"> ${
            err?.response?.data?.error?.message ??
            err?.response?.data?.message ??
            "Falha durante geração da prévia contate o administrador do sistema."
          } <span>`;
          this.previewBusy = false;
        } else {
          throw err;
        }
      }
    },
    iframeLoaded() {
      const iFrameID = this.$refs.iframePreview;
      if (iFrameID) {
        // here you can make the height, I delete it first, then I make it again
        iFrameID.height = "";
        iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
      }
    },
  },
};
</script>
