'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import anomalyTypeService from '@/features/anomaly-types/anomaly-type-service'

const busyMutateName = 'setAnomalyTypesBusy'

export default {
  namespaced: true,
  state: {
    anomalyTypeIsBusy: false,
    anomalyTypeErr: undefined,
    anomalyTypeList: [],
    anomalyTypeFilter: '',
    anomalyTypeCurrentPage: 1,
    anomalyTypeTotalRows: 0,
    anomalyTypeLastPage: 1,
    anomalyTypePerPage: 20
  },
  actions: {
    async listAnomalyTypes({ state, commit }, payload) {
      const { page = 1, perPage = 100, text = '' } = payload || {}

      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await anomalyTypeService().list({
            page,
            perPage,
            text
          })
          setState(commit, 'anomalyTypeFilter', text)
          setState(commit, 'anomalyTypeTotalRows', payload.total)
          setState(commit, 'anomalyTypeCurrentPage', payload.page)
          setState(commit, 'anomalyTypePerPage', payload.perPage)
          setState(commit, 'anomalyTypeLastPage', payload.lastPage)
          setState(commit, 'anomalyTypeList', payload.data)
        }
      )
    },
    async showAnomalyType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return anomalyTypeService().show(id)
        }
      )
    },
    async createAnomalyType({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = anomalyTypeService().create(payload)
          commit('insertAnomalyTypeInList', entry)
        }
      )
    },
    async updateAnomalyType({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = anomalyTypeService().update(payload)
          commit('updateAnomalyTypeInList', entry)
          return entry
        }
      )
    },
    async deleteAnomalyType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await anomalyTypeService().delete(id)
          if (result) await commit('removeAnomalyTypeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    anomalyTypeErr(state) {
      return state.anomalyTypeErr
    },
    filteredAnomalyTypes(state) {
      return filterElements(state.anomalyTypeList, state.filter)
    }
  },
  mutations: {
    mutate,
    cleanErrors(state, value) {
      state.anomalyTypeErr = undefined
    },
    setAnomalyTypesList(state, payload) {
      state.anomalyTypeList = payload
    },
    setAnomalyTypesFilter(state, payload) {
      state.anomalyTypeList = payload
    },
    setAnomalyTypesBusy(state, value = false) {
      state.anomalyTypeIsBusy = value
    },
    insertAnomalyTypeInList(state, payload) {
      state.anomalyTypeList.unshift(payload)
    },
    removeAnomalyTypeInList(state, payload) {
      state.anomalyTypeList = state.anomalyTypeList.filter(
        anomalyType => anomalyType.id !== payload
      )
    },
    updateAnomalyTypeInList(state, payload) {
      if (state.anomalyTypeList) {
        state.anomalyTypeList = state.anomalyTypeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
