var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.value && _vm.value.model ? _c('div', {}, [_c('div', {
    staticClass: "media flex-wrap"
  }, [_c('div', {
    staticClass: "d-none d-sm-block ui-w-140"
  }, [_vm.value.image ? _c('a', {
    staticClass: "d-block ui-rect-67 ui-bg-cover meter-miniature",
    style: {
      'background-image': "url('".concat(_vm.value.image, "')")
    }
  }) : _c('a', {
    staticClass: "d-block ui-rect-67 ui-bg-cover meter-miniature",
    style: {
      'background-image': "url('".concat(_vm.$store.getters.noImageUrl, "')")
    }
  })]), _c('div', {
    staticClass: "media-body ml-sm-4"
  }, [_c('h5', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "float-right font-weight-semibold ml-3"
  }, [_vm._v(" #" + _vm._s(_vm.value.id) + " ")]), _c('a', {
    staticClass: "text-body"
  }, [_vm._v(" " + _vm._s(_vm.value.name ? _vm.value.name : 'Sem Nome'))])]), _vm.value.manufactor ? _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Fabricante:")]), _c('span', [_vm._v(_vm._s(_vm.value.manufactor.name))])])]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v("Modelo:")]), _c('span', [_vm._v(_vm._s(_vm.value.model))])])]), _vm.value.class ? _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Classe:")]), _c('span', [_vm._v(_vm._s(_vm.value.formatted_class_name))])])]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Corrente Maxima:")]), _c('span', [_vm._v(_vm._s(_vm.value.max_current))])])]), _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Contante (K):")]), _c('span', [_vm._v(_vm._s(_vm.value.constant))])])])]), _c('div', {
    staticClass: "media-body ml-sm-4"
  }, [_vm._m(0), _vm.value.meterType ? _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Tipo Medidor:")]), _c('span', [_vm._v(_vm._s(_vm.value.meterType.name))])])]) : _vm._e(), _vm.value.ordinance ? _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Portaria:")]), _c('span', [_vm._v(_vm._s(_vm.value.ordinance.name))])])]) : _vm._e(), _vm.value.connectionType ? _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Tipo de Ligação:")]), _c('span', [_vm._v(_vm._s(_vm.value.connectionType.name))])])]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap align-items-center mb-2"
  }, [_c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Qtd. de Fios:")]), _c('span', [_vm._v(_vm._s(_vm.value.wires))])]), _c('br')]), _vm.value.ratedVoltages ? _c('div', {
    staticClass: "mt-2"
  }, [_vm._m(1), _vm._l(_vm.value.ratedVoltages, function (voltage) {
    return _c('span', {
      key: voltage.id,
      staticClass: "badge badge-outline-default text-muted font-weight-normal mr-1"
    }, [_vm._v(_vm._s(voltage.name))]);
  })], 2) : _vm._e(), _vm.value.ratedCurrents ? _c('div', {
    staticClass: "mt-2"
  }, [_vm._m(2), _vm._l(_vm.value.ratedCurrents, function (current) {
    return _c('span', {
      key: current.id,
      staticClass: "badge badge-outline-default text-muted font-weight-normal mr-1"
    }, [_vm._v(_vm._s(current.name))]);
  })], 2) : _vm._e()])])]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "float-right font-weight-semibold ml-3"
  }), _c('a', {
    staticClass: "text-body"
  }, [_c('br')])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Tensões Nominais: ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-muted small"
  }, [_c('b', [_vm._v(" Correntes Nominais: ")])]);
}]

export { render, staticRenderFns }