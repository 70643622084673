var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.can ? [_c('Can', {
    attrs: {
      "I": _vm.can
    }
  }, [_c('b-btn', _vm._g(_vm._b({
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.bottom",
      modifiers: {
        "bottom": true
      }
    }],
    attrs: {
      "variant": "primary rounded-pill",
      "disabled": _vm.disabled,
      "title": _vm.activeTitle
    },
    on: {
      "click": _vm.click
    }
  }, 'b-btn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2)], 1), _c('Can', {
    attrs: {
      "not": "",
      "I": _vm.can
    }
  }, [_c('b-btn', _vm._g(_vm._b({
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.bottom",
      modifiers: {
        "bottom": true
      }
    }],
    attrs: {
      "variant": "primary rounded-pill",
      "disabled": true,
      "title": _vm.disableTitle
    },
    on: {
      "click": _vm.click
    }
  }, 'b-btn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2)], 1)] : [_c('b-btn', _vm._g(_vm._b({
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.bottom",
      modifiers: {
        "bottom": true
      }
    }],
    attrs: {
      "variant": "primary rounded-pill",
      "disabled": _vm.disabled,
      "title": _vm.activeTitle
    },
    on: {
      "click": _vm.click
    }
  }, 'b-btn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }