import HttpClient from '@/services/http-client'

class TemperatureCertificatesService extends HttpClient {
  async list(args) {
    const { page, perPage, onlyActive = false } = args
    return (
      await this.http.get('/v1/temperature-certificates', {
        params: {
          page,
          limit: perPage,
          onlyActive
        }
      })
    ).data
  }

  async get(certId) {
    return this.rest.get('/v1/temperature-certificates/' + certId ?? null)
  }

  async update(bodyData) {
    return this.rest.patch(
      '/v1/temperature-certificates/' + bodyData.id ?? null,
      bodyData
    )
  }

  async uploadTempCertificate({
    selectedFile,
    password,
    expirationAt,
    newStandards,
    onUpload
  }) {
    const fd = new FormData()
    if (selectedFile) {
      fd.append('certificate', selectedFile.file, selectedFile.file.name)
    }
    fd.append('password', password)
    fd.append('expires_at', expirationAt)
    fd.append('standards', JSON.stringify(newStandards))
    return (
      await this.http.post('/v1/temperature-certificates', fd, {
        timeout: 3600000,
        onUploadProgress: onUpload || null
      })
    ).data
  }

  async store(payload) {
    return this.rest.post('/v1/temperature-certificates', payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/temperature-certificates/' + id)
  }
}

export default () => new TemperatureCertificatesService()
