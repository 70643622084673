'use strict'

import reportTypesApi from '@/features/report-emission/report-emission-api'
import reporterExportAPI from '@/features/meter-serials/meter-serial-report-exporter-api'

import { debounce } from 'lodash'

import { setState, mutate } from '@/helpers/store-helpers'
// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    emissionBusy: false,
    selectedReportType: null,
    reportTypes: []
    // table
  },
  actions: {
    debounceAction: debounce(({ dispatch }, { name, value }) => {
      dispatch(name, value)
    }, 1000),
    async fetchReportTypes({ state, commit }, serviceItemId) {
      try {
        setState(commit, 'emissionBusy', true)
        const reportTypes = await reportTypesApi().index()

        if (reportTypes) {
          setState(commit, 'reportTypes', reportTypes)
          commit('setSelectedReport', reportTypes[0])
        }
        setState(commit, 'emissionBusy', false)
      } catch (error) {
        setState(commit, 'emissionBusy', false)
        throw error
      }
    },
    async downloadReport({ state, commit }, { noteItem, meterSerial }) {
      try {
        setState(commit, 'emissionBusy', true)
        await reporterExportAPI().downloadReportFromSerial(noteItem.id, meterSerial, state.selectedReportType.slug)
        setState(commit, 'emissionBusy', false)
      } catch (error) {
        setState(commit, 'emissionBusy', false)
        throw error
      }
    }
  },
  mutations: {
    // set state need this
    mutate,
    setSelectedReport(state, value) {
      state.selectedReportType = value
    }
  },
  getters: {}
}
