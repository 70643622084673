'use strict'
// Thi store depends from some plugins like CASL in Vue instance

import userApi from '@/features/users-editor/user-api'
import { setState, mutate, filterElements } from '@/helpers/store-helpers'
import storedFilesApi from '@/features/file-download/file-download-api'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    usersBusy: false,
    user_list: [],
    currentUsersPage: 1,
    lastUsersPage: 1,
    totalUsersRows: 0,
    perPageUsers: 10,
    users: []
  },
  actions: {
    async createUser({ state, commit }, payload) {
      commit('isBusy')
      try {
        const created = await userApi().store(payload)
        commit('insertUser', created)
        commit('isBusy', false)
        return created
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async updateUser({ state, commit }, payload) {
      commit('isBusy')
      try {
        const updated = await userApi().update(payload)
        commit('updateListRef', updated)
        commit('isBusy', false)
        return updated
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async filterUsers({ state, commit }, { page, perPage, text }) {
      commit('isBusy')
      try {
        const usersUpdated = await userApi().list(page, perPage, text)
        setState(commit, 'user_list', usersUpdated.data)
        setState(commit, 'lastUsersPage', usersUpdated.lastPage)
        setState(commit, 'currentUsersPage', usersUpdated.page)
        setState(commit, 'perPageUsers', usersUpdated.perPage)
        setState(commit, 'totalUsersRows', usersUpdated.total)
        commit('isBusy', false)
      } catch (e) {
        commit('isBusy', false)
        console.error(e)
        throw e
      }
    },
    async fetchUsersList({ state, commit }, { page, perPage, query, filters }) {
      commit('isBusy')
      try {
        const usersUpdated = await userApi().list(page, perPage, query, filters)
        setState(commit, 'user_list', usersUpdated.data)
        setState(commit, 'lastUsersPage', usersUpdated.lastPage)
        setState(commit, 'currentUsersPage', usersUpdated.page)
        setState(commit, 'perPageUsers', usersUpdated.perPage)
        setState(commit, 'totalUsersRows', usersUpdated.total)
        commit('isBusy', false)
        return usersUpdated
      } catch (e) {
        commit('isBusy', false)
        console.error(e)
        throw e
      }
    },
    async uploadUserSignature({ state, commit }, { blob, fileName }) {
      commit('isBusy', true)
      try {
        const imageData = await storedFilesApi().store({
          blob,
          filename: fileName
        })
        setState(commit, 'signature_model', imageData)
        commit('isBusy', false)
        return imageData
      } catch (error) {
        commit('isBusy', false)
        throw error
      }
    },
    async getUser({ state, commit }, id) {
      return userApi().get(id)
    },
    async deleteUser({ state, commit }, id) {
      const result = await userApi().delete(id)
      if (result) await commit('removeUser', id)
    }
  },
  getters: {
    totalUsers(state) {
      return (state.users || []).length
    },
    errorMessage(state) {
      return state.errMessage
    },
    filteredUsers(state) {
      return filterElements(state.user_list, state.filter)
    },

    hasNewUsers(state) {
      let check
      try {
        if (this.isFirstTime) {
          check = undefined
        } else {
          check = state.users.length > 3
        }
      } catch (e) {
        return false
      }
      return check
    }
  },
  mutations: {
    mutate,
    isBusy(state, val = true) {
      state.usersBusy = val
    },
    updateTextFilter(state, filter) {
      state.filter = filter.toLowerCase()
    },
    removeUser(state, payload) {
      state.user_list = state.user_list.filter(value => value.id !== payload)
    },
    insertUser(state, data) {
      state.user_list.push(data)
    },
    setInitial(state, user) {
      state.initialUser = user
    },
    setFinal(state, user) {
      state.finalUser = user
    },
    updateListRef(state, update) {
      if (state.user_list) {
        state.user_list = state.user_list.map(val => {
          if (val.id === update.id) {
            return update
          }
          return val
        })
      }
    },
    updateUsers(state, users) {
      state.user_list = users || []
    },
    resetUsersState(state, _) {
      state.initialUser = null
      state.finalUser = null
    }
  }
}
