<template>
  <CanPillBtn
    :id="id"
    :can="can"
    @click="click"
    activeTitle="Criar entrada !"
    disableTitle="Sem permissão de criação !"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <slot></slot>
  </CanPillBtn>
</template>

<style></style>

<script>
export default {
  name: 'EditBtn',
  props: {
    can: {
      default: null
    },
    click: {
      default: null
    },
    id: {
      default: 'create-btn'
    }
  }
}
</script>
