var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    staticClass: "layout-navbar align-items-lg-center container-p-x",
    attrs: {
      "toggleable": "lg",
      "variant": _vm.getLayoutNavbarBg()
    }
  }, [_vm.sidenavToggle ? _c('b-navbar-nav', {
    staticClass: "align-items-lg-center mr-auto mr-lg-4"
  }, [_c('a', {
    staticClass: "nav-item nav-link px-0 ml-2 ml-lg-0",
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.toggleSidenav
    }
  }, [_c('i', {
    staticClass: "ion ion-md-menu text-large align-middle"
  })])]) : _vm._e(), _c('a', {
    staticClass: "nav-item nav-link px-0 ml-2 ml-lg-0",
    attrs: {
      "href": "javascript:void(0)",
      "title": "Abrir Busca (Ctrl + K)",
      "b-v-tooltip.hover": ""
    },
    on: {
      "click": _vm.toggleSearchModal
    }
  }, [_c('i', {
    staticClass: "ion ion-md-book text-large align-middle"
  })]), _c('b-modal', {
    ref: "searchModal",
    attrs: {
      "id": "modals-fill-in",
      "size": "large",
      "modal-class": "modal-fill-in",
      "hide-header": "",
      "hide-footer": ""
    }
  }, [_c('p', {
    staticClass: "text-white text-large font-weight-light mb-3"
  }, [_vm._v(" Buscar um Módulo Especifico ")]), _c('b-input-group', {
    staticClass: "mb-3",
    attrs: {
      "size": "lg"
    }
  }, [_c('b-input', {
    ref: "textFilter",
    staticClass: "bg-white border-0",
    attrs: {
      "placeholder": "Digite o nome do módulo"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.openFirstEntry.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filterText,
      callback: function callback($$v) {
        _vm.filterText = $$v;
      },
      expression: "filterText"
    }
  }), _c('b-input-group-btn', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('b-btn', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v("Buscar")])], 1)], 1), _c('div', {
    staticClass: "text-center text-right text-white opacity-60"
  }, [_vm._v(" Ao selecionar irá abrir na aba atual (salve suas alterações antes !) ")]), _c('b-card', [_c('b-list-group', [_vm._l(_vm.filteredItens, function (entry) {
    return [entry.divider ? void 0 : _vm.isSearchEntryActive(entry.to) ? [_c('b-list-group-item', {
      key: entry.id,
      staticClass: "mb-1",
      attrs: {
        "active": ""
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between w-100"
    }, [_c('h5', {
      staticClass: "mb-1"
    }, [_vm._v(" " + _vm._s(entry.name) + " ")]), _c('a', {
      attrs: {
        "tabindex": "-1"
      }
    }, [_vm._v("Página Atual")])]), _c('small', [_vm._v(_vm._s(entry.to))])])] : entry !== null && entry !== void 0 && entry.can ? [_c('Can', {
      key: entry.id,
      attrs: {
        "I": entry.can
      }
    }, [_c('b-list-group-item', {
      key: entry.id,
      staticClass: "mb-1",
      attrs: {
        "href": entry.to
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between w-100"
    }, [_c('h5', {
      staticClass: "mb-1"
    }, [_vm._v(" " + _vm._s(entry.name) + " ")]), _c('a', {
      attrs: {
        "href": "#",
        "tabindex": "-1"
      }
    }, [_vm._v("Abrir Módulo")])]), _c('small', [_vm._v(_vm._s(entry.to))])])], 1), _c('Can', {
      key: entry.id,
      attrs: {
        "not": "",
        "I": entry.can
      }
    }, [_c('b-list-group-item', {
      key: entry.id,
      staticClass: "mb-1",
      attrs: {
        "href": "javascript:void(0)",
        "disabled": ""
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between w-100"
    }, [_c('h5', {
      staticClass: "mb-1"
    }, [_vm._v(" " + _vm._s(entry.name) + " ")]), _c('a', {
      attrs: {
        "disabled": ""
      }
    }, [_vm._v("Bloqueado")])]), _c('small', [_vm._v("(Sem permissão para acessar)")])])], 1)] : [_c('b-list-group-item', {
      key: entry.id,
      staticClass: "mb-1",
      attrs: {
        "href": entry.to
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between w-100"
    }, [_c('h5', {
      staticClass: "mb-1"
    }, [_vm._v(" " + _vm._s(entry.name) + " ")]), _c('a', {
      attrs: {
        "href": "#",
        "tabindex": "-1"
      }
    }, [_vm._v("Abrir Módulo")])]), _c('small', [_vm._v(_vm._s(entry.to))])])]];
  })], 2)], 1)], 1), _c('b-navbar-toggle', {
    attrs: {
      "target": "app-layout-navbar"
    }
  }), _c('b-collapse', {
    attrs: {
      "is-nav": "",
      "id": "app-layout-navbar"
    }
  }, [_c('b-navbar-nav', {
    staticClass: "align-items-lg-center"
  })], 1), _c('b-nav-item-dropdown', {
    staticClass: "demo-navbar-user",
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "right": true
    }
  }, [_c('template', {
    slot: "button-content"
  }, [_c('span', {
    staticClass: "d-inline-flex flex-lg-row-reverse align-items-center align-middle"
  }, [_c('span', {
    staticClass: "px-1 mr-lg-2 ml-2 ml-lg-0"
  }, [_vm._v("Bem Vindo, " + _vm._s(_vm.userName))])])]), _c('a', {
    on: {
      "click": _vm.goToProfile
    }
  }, [_c('b-dd-item', [_c('i', {
    staticClass: "ion ion-md-settings text-lightest"
  }), _vm._v("   Minha Conta")])], 1), _c('b-dd-divider'), _c('b-dd-item', {
    on: {
      "click": _vm.doLogout
    }
  }, [_c('i', {
    staticClass: "ion ion-ios-log-out text-danger"
  }), _vm._v("   Sair ")]), _c('b-dd-item', {
    on: {
      "click": _vm.reloadWindow
    }
  }, [_vm.hasNewVersion ? _c('b-btn', {
    attrs: {
      "size": "xs",
      "variant": "success"
    }
  }, [_vm._v(" Atualizar Versão: " + _vm._s(_vm.$store.getters.appVersion) + " para " + _vm._s(_vm.nextVersion) + " ")]) : _c('b-btn', {
    attrs: {
      "size": "xs",
      "variant": "secondary"
    }
  }, [_vm._v("Versão Atual: " + _vm._s(_vm.$store.getters.appVersion))])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }