import HttpClient from '@/services/http-client'

class VirtualSealService extends HttpClient {
  async show(id) {
    return this.rest.get('/v1/virtual-seals/' + id)
  }

  async list(
    page = 1,
    limit = 1000,
    text = null,
    compromise_number = null,
    serial_number = null,
    virtual_seal_number = null,
    sealing_date_range = null
  ) {
    return this.rest.get('/v1/virtual-seals', {
      params: {
        page,
        limit,
        q: text,
        compromise_number,
        serial_number,
        virtual_seal_number,
        sealing_date_range
      }
    })
  }

  async create(payload) {
    return this.rest.post('/v1/virtual-seals', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/virtual-seals/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/virtual-seals/' + id)
  }
}

export default () => new VirtualSealService()
