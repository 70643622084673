'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import regulatoryOrdinanceService from '@/features/regulatory-ordinances/regulatory-ordinances-api'

const busyMutateName = 'setRegulatoryOrdinancesBusy'

export default {
  namespaced: true,
  state: {
    regulatoryOrdinanceList: [],
    regulatoryOrdinanceFilter: '',
    regulatoryOrdinanceIsBusy: false,
    regulatoryOrdinanceTotalRows: 0,
    regulatoryOrdinanceCurrentPage: 1,
    regulatoryOrdinancePerPage: 10,
    regulatoryOrdinanceLastPage: 0
  },
  actions: {
    async listRegulatoryOrdinances({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await regulatoryOrdinanceService().list(
            page,
            perPage,
            text
          )
          setState(commit, 'regulatoryOrdinanceTotalRows', payload.total)
          setState(commit, 'regulatoryOrdinanceCurrentPage', payload.page)
          setState(commit, 'regulatoryOrdinancePerPage', payload.perPage)
          setState(commit, 'regulatoryOrdinanceLastPage', payload.lastPage)

          commit('setRegulatoryOrdinancesList', payload.data)
        }
      )
    },
    async fetchRegulatoryOrdinance({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          // eslint-disable-next-line no-return-await
          return await regulatoryOrdinanceService().show(id)
        }
      )
    },
    async createRegulatoryOrdinance({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const data = await regulatoryOrdinanceService().create(payload)
          commit('addToOrdinances', data)
          return data
        }
      )
    },
    async updateRegulatoryOrdinance({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const returned = regulatoryOrdinanceService().update(payload)
          commit('updateRegulatoryOrdinanceInList', returned)
          return returned
        }
      )
    },
    async deleteRegulatoryOrdinance({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await regulatoryOrdinanceService().delete(id)
          if (result) await commit('removeRegulatoryOrdinanceInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredRegulatoryOrdinances(state) {
      return filterElements(state.regulatoryOrdinanceList, state.filter)
    }
  },
  mutations: {
    mutate,
    setRegulatoryOrdinancesList(state, payload) {
      state.regulatoryOrdinanceList = payload
    },
    setRegulatoryOrdinancesFilter(state, payload) {
      state.regulatoryOrdinanceList = payload
    },
    setRegulatoryOrdinancesBusy(state, value = false) {
      state.regulatoryOrdinanceIsBusy = value
    },
    addToOrdinances(state, newOrdinance) {
      state.regulatoryOrdinanceList = state.regulatoryOrdinanceList.filter(
        actual => actual.id !== newOrdinance.id
      )
      state.regulatoryOrdinanceList.unshift(newOrdinance)
    },
    removeRegulatoryOrdinanceInList(state, payload) {
      state.regulatoryOrdinanceList = state.regulatoryOrdinanceList.filter(
        regulatoryOrdinance => regulatoryOrdinance.id !== payload
      )
    },
    updateRegulatoryOrdinanceInList(state, payload) {
      if (state.regulatoryOrdinanceList) {
        state.regulatoryOrdinanceList = state.regulatoryOrdinanceList.map(
          val => {
            if (val.id === payload.id) {
              return payload
            }
            return val
          }
        )
      }
    }
  }
}
