<template>
  <div v-if="can">
    <Can :I="can">
      <router-link :to="path">
        <b-button
          v-b-tooltip.top
          :title="buttonTxt + ' ' + name"
          ref="stampbutton"
          size="md"
          variant="primary rounded-pill"
        >
          <i class="ion ion-md-add"></i>&nbsp; {{ buttonTxt + ' ' + name }}
        </b-button>
      </router-link>
    </Can>
    <Can not :I="can">
      <b-button
        v-b-tooltip.top
        :title="'Você não tem permissão para '+buttonTxt + ' ' + name"
        ref="stampbutton"
        size="md"
        variant="primary rounded-pill"
        disabled
      >
        <i class="ion ion-md-add"></i>&nbsp; {{ buttonTxt + ' ' + name }}
      </b-button>
    </Can>
  </div>
  <div v-else>
    <router-link :to="path">
      <b-button
        v-b-tooltip.top
        :title="buttonTxt + ' ' + name"
        ref="stampbutton"
        size="md"
        variant="primary rounded-pill"
      >
        <i class="ion ion-md-add"></i>&nbsp; {{ buttonTxt + ' ' + name }}
      </b-button>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    path: { default: null },
    can: { default: null },
    name: { default: '' }
  },
  data() {
    return {
      buttonTxt: 'Cadastrar'
    }
  }
}
</script>

<style></style>
