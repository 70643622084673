var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BusyOverlay', {
    attrs: {
      "show": _vm.isBusy
    }
  }, [_c('small', {
    staticClass: "disabled",
    on: {
      "click": _vm.refreshBranches
    }
  }, [_vm._v(" Exibindo de Filiais: " + _vm._s(this.branchString) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }