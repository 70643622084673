var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mw-100"
  }, [_c('b-row', {
    staticClass: "px-2 py-auto"
  }, [_c('Multiselector', {
    staticClass: "mw-100",
    attrs: {
      "id": 'settings-model-multiselect',
      "disabled": _vm.disabled,
      "track-by": "id",
      "label": "name",
      "custom-label": _vm.formatExibitionText,
      "hide-selected": false,
      "taggable": false,
      "allowEmpty": false,
      "options": _vm.modelsFound,
      "value": _vm.value,
      "deselect-label": "Remover seleção",
      "selectLabel": "Escolher",
      "selectedLabel": "Selecionado",
      "tag-placeholder": "Escolha uma opção",
      "placeholder": "Escolha uma opção"
    },
    on: {
      "search-change": _vm.onSearchModel,
      "select": _vm.onSelectModel,
      "remove": _vm.onRemoveModel
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }