import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('breadCrumbs', ['updateBreadCrumb']),
    ...mapMutations('breadCrumbs', ['setBreadCrumbPath'])
  },
  computed: {
    ...mapState('breadCrumbs', ['breadCrumbSegments']),
    ...mapGetters('breadCrumbs', ['breadCrumbPath'])
  }
}
