<template>
  <b-overlay
    id="overlay-background"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <slot></slot>
  </b-overlay>
</template>

<script>
export default {
  name: 'BusyOverlay',
  props: {},
  methods: {}
}
</script>

<style></style>
