'use strict'
// Thi store depends from some plugins like CASL in Vue instance

import samplingReportApi from '@/features/sampling-reports/sampling-reports-api'
import { setState, filterElements, mutate } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    isLoadBusy: false,
    meterError: undefined,
    filter: '',
    samplingList: [],
    generateAndSendEmail: false,

    samplingListPreview: [],
    isPreviewBusy: false
  },
  actions: {
    async fetchPreview({ state, commit }, payload) {
      try {
        setState(commit, 'isPreviewBusy', true)
        const reportsPreview = await samplingReportApi().preview(payload, {
          params: {
            page: 1,
            limit: 1000
          }
        })
        setState(commit, 'samplingListPreview', reportsPreview)
        setState(commit, 'isPreviewBusy', false)
        return reportsPreview
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        setState(commit, 'isPreviewBusy', false)
        throw e
      } finally {
        setState(commit, 'isPreviewBusy', false)
      }
    },
    async fetchFiscalNotePreview({ state, commit }, id) {
      const reportsPreview = await samplingReportApi().previewFiscalNote(id)
      return reportsPreview
    },
    async fetchSamplingReport({ state, commit }, params) {
      const { page = 1, perPage = 10 } = params ?? {}
      try {
        commit('setBusy', true)
        const samplePaginate = await samplingReportApi().list({
          params: {
            page: page,
            limit: perPage
          }
        })
        setState(commit, 'samplingList', samplePaginate.data)
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    },
    async createSamplingReport({ state, commit }, payload) {
      try {
        setState(commit, 'currentTermLoaded', null)
        commit('setBusy', true)
        const samplingReport = await samplingReportApi().store(payload)

        await samplingReportApi().renderPDF(samplingReport.id)
        setState(commit, 'currentTermLoaded', samplingReport)
        return samplingReport
      } catch (e) {
        console.error(e)
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    },
    async deleteSamplingReport({ state, commit }, payload) {
      try {
        commit('setBusy', true)
        const createdLoad = await samplingReportApi().delete(payload.id)
        if (createdLoad) await commit('removeFromSamplingList', payload.id)
      } catch (e) {
        setState(commit, 'meterError', e.toString())
        throw e
      } finally {
        commit('setBusy', false)
      }
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    addToSamplingReport(state, load) {
      state.samplingList.push(load)
    },
    removeFromSamplingList(state, id) {
      const reports = JSON.stringify(state.samplingList.filter((repo) => repo.id !== id))
      state.samplingList = JSON.parse(reports)
    },
    setBusy(state, val = false) {
      state.isLoadBusy = val
    },
    filterTerms(state, filter = '') {
      state.filter = filter
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalItems(state) {
      if (state.samplingList) return state.samplingList.length || 0
      return 0
    },
    selectedMeter(state) {
      return state.selected
    },
    filteredSamplingList(state) {
      return filterElements(state.samplingList, state.filter)
    }
  }
}
