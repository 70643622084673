import Vue from 'vue'

class AuthAPI {
  /**
   *
   * @param {Object} tokenInfo Information about token like `refreshToken`, `type` and `token`
   */
  // eslint-disable-next-line camelcase
  async login({ username, password, branch_id }) {
    return (
      await Vue.prototype.$http.post('/v1/auth/login', {
        username: username,
        password: password,
        branch_id: branch_id
      })
    ).data
  }
}
export default () => {
  return new AuthAPI()
}
