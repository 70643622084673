'use strict'

// Thi store depends from some plugins like CASL in Vue instance
import settingsApi from '@/features/settings/settings-api'
import { setState, filterElements, mutate } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    isBusy: false,
    filter: '',
    settings: [],
    currentSettingsBranch: null
  },
  actions: {
    async listSettings({ state, commit }, payload) {
      try {
        const branchId =
          payload && payload.branch && payload.branch.id
            ? payload.branch.id
            : null
        commit('isBusy', true)
        const settingsList = await settingsApi().list({
          page: 1,
          limit: 1000,
          branchId
        })
        setState(commit, 'currentSettingsBranch', settingsList.currentBranch)
        setState(commit, 'settings', settingsList.data)
      } catch (e) {
        commit('isBusy')
        throw e
      } finally {
        commit('isBusy')
      }
    },
    async updateSettingValue({ state, commit }, { id, payload }) {
      commit('isBusy', true)
      try {
        console.log('updateSettingValue', id, payload)
        const updated = await settingsApi().update(id, payload)
        commit('updateEntry', updated)
        commit('isBusy')
      } catch (e) {
        commit('isBusy')
        throw e
      } finally {
        commit('isBusy')
      }
    },
    async deleteSettings({ state, commit }, payload) {
      try {
        commit('isBusy', true)
        const createdLoad = await settingsApi().store(payload)
        commit('addToSettings', createdLoad)
      } catch (e) {
        commit('isBusy')
        throw e
      } finally {
        commit('isBusy')
      }
    },
    async resetSettings({ state, commit }, id) {
      try {
        commit('isBusy', true)
        const updated = await settingsApi().reset(id)
        commit('updateEntry', updated)
      } catch (e) {
        commit('isBusy')
        throw e
      } finally {
        commit('isBusy')
      }
    }
  },
  mutations: {
    mutate,
    isBusy(state, val = false) {
      state.isBusy = val
    },
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    addToSettings(state, load) {
      state.settings.push(load)
    },
    updateEntry(state, newSetting) {
      const newListing = state.settings.map(sett => {
        if (sett.id === newSetting.id) {
          return newSetting
        }
        return sett
      })
      state.settings = newListing
    },
    setSettingsFilter(state, filter = '') {
      state.filter = filter
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    settingsFilter(state) {
      return state.filter
    },
    totalSettings(state) {
      return state.settings.length || 0
    },
    filteredSettings(state) {
      return filterElements(state.settings, state.filter)
    }
  }
}
