'use strict'

import { DateTime } from 'luxon'
import calibrationCertificatesApi from '@/features/calibration-certificates/calibration-certificates-api'
import { filterElements, setState, mutate } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    isUploading: false,
    calibrationCertIsBusy: false,
    models: [],
    manufactors: [],
    tableTypes: [],
    calibrationCertificates: [],
    _certificatePassword: '',
    expiresAt: null,
    filter: '',
    uploadCertificate: null,
    uploadProgress: 0,
    uploaded: false,
    rated_voltages: [],
    meterError: undefined
  },
  actions: {
    async listCalibrationCertificates({ state, commit }, args) {
      const { text = null, onlyActive = false, page = 1, perPage = 50 } = args ?? {}
      await commit('isBusy', true)
      try {
        const calibrationCertificates = await calibrationCertificatesApi().list({ text, onlyActive, page, perPage })
        commit('setCertificateList', calibrationCertificates.data)
      } catch (e) {
        console.warn(e)
        throw e
      } finally {
        commit('isBusy', false)
      }
    },
    async fetchCalibrationCertificate({ state, commit }, id) {
      let calCertificate = null
      await commit('isBusy', true)
      try {
        calCertificate = await calibrationCertificatesApi().get(id)
      } catch (e) {
        console.warn(e)
        throw e
      } finally {
        commit('isBusy', false)
      }
      return calCertificate
    },
    async createCalibrationCertificate({ state, commit }, payload) {
      await commit('isUploading', true)
      try {
        const data = await calibrationCertificatesApi().store(payload, (event) => {
          setState(commit, 'uploadProgress', (event.loaded / event.total) * 100)
          if (event.loaded / event.total === 1) {
            setState(commit, 'uploadProgress', 100)
          }
        })

        commit('isUploading', false)
        commit('updateCertificates', data)
        return data
      } catch (error) {
        setState(commit, 'meterError', error.toString())
        commit('isUploading', false)
        throw error
      }
    },
    async updateCalibrationCertificate({ state, commit }, payload) {
      await commit('isUploading', true)
      try {
        const data = await calibrationCertificatesApi().update(payload, (event) => {
          setState(commit, 'uploadProgress', (event.loaded / event.total) * 100)
          if (event.loaded / event.total === 1) {
            setState(commit, 'uploadProgress', 100)
          }
        })

        commit('isUploading', false)
        commit('updateCertificates', data)
        return data
      } catch (error) {
        setState(commit, 'meterError', error.toString())
        commit('isUploading', false)
        throw error
      }
    },
    async deleteCalibrationCertificate({ state, commit }, id) {
      try {
        commit('isUploading', true)
        const data = await calibrationCertificatesApi().delete(id)

        commit('isUploading', false)
        commit('removeModel', data)
        return data
      } catch (error) {
        setState(commit, 'meterError', error.toString())
        commit('isUploading', false)
        throw error
      }
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    isBusy(state, val = false) {
      state.calibrationCertIsBusy = val
    },
    isUploading(state, val = false) {
      state.isUploading = val
    },
    setCertificateList(state, data) {
      state.calibrationCertificates = data
    },
    updateCertificates(state, data) {
      state.calibrationCertificates.splice(0, 0, data)
    },
    setCertificatePassword(state, data) {
      state._certificatePassword = data
    },
    setUploadCertificate(state, data) {
      state.uploadCertificate = data
    },
    addModel(state, model) {
      state.calibrationCertificates.push(model)
    },
    removeModel(state, model) {
      state.calibrationCertificates = state.calibrationCertificates.filter((met) => met.id !== model.id)
    },
    filterCertificates(state, filter) {
      state.filter = filter.toLowerCase()
    },
    resetFormData(state, filter) {
      state.expiresAt = DateTime.local()
        .plus({ year: 1 })
        .toISODate()
      state._certificatePassword = null
      state.uploadCertificate = null
      state.uploadProgress = 0
      state.isUploading = false
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalItems(state) {
      return state.calibrationCertificates.length || 0
    },
    uploadProgress(state) {
      if (!state.isUploading) return null
      return state.uploadProgress
    },
    selectedTable(state) {
      return state._selectedTable
    },
    certificatePassword(state) {
      return state._certificatePassword
    },
    validCertificatePassword(state) {
      if (state._certificatePassword && state._certificatePassword !== '') {
        return state._certificatePassword.length > 0
      }
      return undefined
    },
    filteredCertificates(state) {
      // filter our data
      const t = state.filter
      return filterElements(state.calibrationCertificates, t)
    }
  }
}
