import { ndInvalidValue } from '@/helpers/table-formatter'
import _, { debounce, isArray, isNil } from 'lodash'
import serviceHelpers from './service-helpers'

export default {
  mixins: [serviceHelpers],
  computed: {
    params() {
      return this.$route.params
    }
  },
  methods: {
    debounceAction: debounce(({ dispatch }, { name, value }) => {
      dispatch(name, value)
    }, 1000),
    isCreationRoute() {
      return (
        this.$router.currentRoute.fullPath.endsWith('novo') ||
        this.$router.currentRoute.fullPath.endsWith('nova') ||
        this.$router.currentRoute.fullPath.endsWith('new')
      )
    },
    viewUserProfile: function ({ id }) {
      this.$router.push('/usuario/' + id)
    },
    getOwnAttrs() {
      return _.omitBy(this.$attrs, v => v === undefined)
    },
    getOwnProps() {
      return _.omitBy(this.$props, v => v === undefined)
    },
    ndInvalidValue: ndInvalidValue,
    /**
     * Formata texto de exibição de um modelo de medidor
     */
    formatMetterInfo(metter) {
      function _Val(val) {
        return isNil(val) ? '--' : val.length < 1 ? '--' : val
      }
      function _Arr(array) {
        return isArray(array) ? array.map(val => val.name) : '--'
      }

      return `${_Val(metter?.model)} ${_Arr(metter?.ratedVoltages)} da ${_Val(
        metter?.manufactor?.name
      )} kd/ke: ${_Val(metter?.constant)} ${_Arr(metter?.ratedVoltages)} ${
        _Val(metter?.meterType?.name).substring(0, 5) + '.'
      } ${_Val(metter?.connectionType?.name).substring(0, 4)} ${
        isNil(metter?.active_tolerance) ? '' : 'Atv'
      }${isNil(metter?.reactive_tolerance) ? '' : ',Reat'} Cl:${
        metter?.lab_ce_report_class
      } #${metter?.id}`
    }
  }
}
