import HttpClient from '@/services/http-client'

class ExpertModeExtractorService extends HttpClient {
  async listExtractorFiles({ page = 1, limit = 20 }) {
    return this.rest.get('/v1/extractor-files/', {
      params: {
        page,
        limit
      }
    })
  }

  async show(id) {
    return this.rest.get('/v1/expert-mode-extractor/' + id)
  }

  async isExpertModeBusy() {
    try {
      return this.rest.get('/v1/expert-mode-extractor/is-busy')
    } catch (error) {
      console.warn('Falha ao checar extrator ocupado', error)
    }
  }

  async getReportPreview(
    paramsUsed = {
      page: 1,
      limit: 1000,
      text: undefined,
      startAt: undefined,
      endAt: undefined,
      responsibleId: undefined,
      meterSerial: undefined,
      selectedStatus: undefined,
      nfNumber: undefined,
      nfFilter: undefined
    }
  ) {
    return this.rest.get('/v1/expert-mode-extractor', {
      params: paramsUsed,
      cache: {
        ttl: 1000 * 1 // seconds.
      }
    })
  }

  async create(payload) {
    return this.rest.post('/v1/expert-mode-extractor', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/expert-mode-extractor/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/expert-mode-extractor/' + id)
  }

  async downloadGeneratedNewWindow(filePath) {
    const protocol = location.protocol
    const slashes = protocol.concat('//')
    const host = slashes.concat(window.location.host)
    const urlWithParams = new URL(host + '/api/v1/expert-mode-extractor/download')
    urlWithParams.searchParams.append('filePath', filePath)
    window.open(urlWithParams.href, '_blank')
  }

  async startDownloadGeneration(paramsUsed) {
    return this.rest.post('/v1/expert-mode-extractor/start-download', paramsUsed)
  }

  async downloadReportNewWindow(paramsUsed) {
    console.warn('Params used', paramsUsed)
    const protocol = location.protocol
    const slashes = protocol.concat('//')
    const host = slashes.concat(window.location.host)
    const urlWithParams = new URL(host + '/api/v1/expert-mode-extractor/generate-download')
    console.log('downloading url ->:', urlWithParams)
    for (const key in paramsUsed) {
      if (Object.hasOwnProperty.call(paramsUsed, key)) {
        const keyValue = paramsUsed[key]
        if (keyValue !== undefined) {
          urlWithParams.searchParams.append(key, keyValue)
        }
      }
    }
    console.warn('DOWNLOAD LINK', urlWithParams.href)
    if (!paramsUsed.noOpen) {
      window.open(urlWithParams.href, '_blank')
    }
    return urlWithParams.href
  }
}

export default () => new ExpertModeExtractorService()
