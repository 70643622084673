import HttpClient from '@/services/http-client'
import ValidationException from '@/features/exceptions/ValidationException'

class SealingMetersService extends HttpClient {
  async list(serviceItemID) {
    return this.rest.get('/v1/service-items/' + serviceItemID + '/sealings')
  }

  async getAvailableSealBags(serviceItemID) {
    return this.rest.get('/v1/available-seals-bags', {
      params: {
        service_item_id: serviceItemID
      }
    })
  }

  async get(id) {
    return this.rest.get('/v1/service-items/' + id)
  }

  async store(payload) {
    try {
      return this.rest.post('/v1/service-items', payload)
    } catch (error) {
      // adiciona o id do selo a mensagem de erro
      if (error.response.status === 400) {
        throw new ValidationException(
          'Alguns campos estão inválidos',
          error.response.data
        )
      }
    }
  }

  async update(payload) {
    return this.rest.patch('/v1/service-items/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/service-items/' + id)
  }

  async stampMeter({ serviceItemID, meterSerial, seals }) {
    return this.rest.post('/v1/service-items/' + serviceItemID + '/sealings', {
      meter_serial: meterSerial,
      seals: seals
    })
  }

  async isValidSeal(serviceItemID, seal) {
    return this.rest.get(
      '/v1/service-items/' + serviceItemID + '/can-stamp-seal',
      {
        params: {
          seal: seal
        }
      }
    )
  }

  async isValidSerial(serviceItemId, serialNumber) {
    return this.rest.get(
      `/v1/service-items/${serviceItemId}/can-stamp-serial`,
      {
        params: {
          serial: serialNumber
        }
      }
    )
  }

  async dissociateSeal(code) {
    return this.rest.post(
      '/v1/seals/dissociate',
      {},
      {
        params: {
          code: code
        }
      }
    )
  }

  async setDamagedSeal(code) {
    return (
      await this.http.post(
        '/v1/seals/damaged',
        {},
        {
          params: {
            code: code
          }
        }
      )
    ).data
  }

  async dissociateVirtualSeal(associationId) {
    return this.rest.delete('/v1/meter-serial-virtual-seals/' + associationId)
  }

  async associateVirtualSeal(serial) {
    return this.rest.post('/v1/meter-serial-virtual-seals', serial)
  }

  async stampNoteItemVirtualSeals(serviceItemId) {
    return (
      await this.http.post(
        '/v1/service-items/' + serviceItemId + '/stamp-virtual-seals'
      )
    ).data
  }
}
export default () => new SealingMetersService()
