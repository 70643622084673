<template>
  <b-input :id="id" v-bind="{ ...$props, ...$attrs }" v-on="$listeners" class="form-control" type="number"
    ><slot></slot>
  </b-input>
</template>

<script>
export default {
  name: 'IntegerInput',
  props: {
    id: {
      default: undefined
    }
  },
  methods: {}
}
</script>
