var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-input-group', {
    staticClass: "mb-3"
  }, [_c('b-input', {
    staticClass: "form-control",
    attrs: {
      "id": _vm.id,
      "type": "text",
      "state": _vm.isValid,
      "size": _vm.size,
      "value": _vm.dateTextInput,
      "placeholder": "01/01/1891",
      "disabled": _vm.disabled
    },
    on: {
      "input": _vm.onTypeText
    }
  }), _c('b-input-group-append', [_c('b-form-datepicker', {
    attrs: {
      "button-only": "",
      "right": "",
      "id": "date-input",
      "size": _vm.size,
      "max": _vm.maxDate,
      "min": _vm.minDate,
      "value-as-date": true,
      "input-class": "form-control",
      "placeholder": "01/01/1891",
      "disabled": _vm.disabled
    },
    on: {
      "input": _vm.userSelectedPicker
    },
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }