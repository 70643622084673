<template>
  <div class="mw-100">
    <Multiselector
      :id="id"
      v-if="!clearBtn"
      track-by="id"
      :custom-label="formattedLabel"
      :hide-selected="false"
      :taggable="false"
      :allowEmpty="true"
      :options="userList"
      :value="value"
      :disabled="disabled"
      @open="onOpenPicker"
      @search-change="asyncFindUser"
      @select="onSelectModel"
      @remove="onRemoveModel"
      :loading="loadingUser"
      deselect-label=""
      selectLabel=""
      selectedLabel="Em uso"
      tag-placeholder="Selecione uma opção"
      placeholder="Selecione uma opção"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    >
      <span slot="noResult">Sem Signatários.</span>
    </Multiselector>
    <b-row class="px-2 py-auto" v-else>
      <b-col class="col-11 mr-0 pr-0">
        <Multiselector
          :id="id"
          track-by="id"
          :custom-label="formattedLabel"
          :hide-selected="false"
          :taggable="false"
          :allowEmpty="true"
          :options="userList"
          :value="value"
          :disabled="disabled"
          @open="onOpenPicker"
          @search-change="asyncFindUser"
          @select="onSelectModel"
          @remove="onRemoveModel"
          :loading="loadingUser"
          deselect-label=""
          selectLabel=""
          selectedLabel="Em uso"
          tag-placeholder="Selecione uma opção"
          placeholder="Selecione uma opção"
          v-bind="{ ...$props, ...$attrs }"
          v-on="$listeners"
        >
          <span slot="noResult">Sem Signatários.</span>
        </Multiselector>
      </b-col>

      <b-row class="col-1 ml-0 pl-0">
        <b-btn size="xs" class="btn-outline-secondary" v-b-tooltip.right title="Remover Signatário" @click="clearValue"
          ><span class="ion ion-md-close"></span
        ></b-btn>
      </b-row>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'TrialSignerPicker',
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    emitOnMount: {
      default: true
    },
    // eslint-disable-next-line vue/no-unused-properties
    id: {
      default: undefined
    },
    clearBtn: {
      default: true
    }
  },
  data() {
    return {
      selectedEntry: null,
      userQuery: null,
      loadingUser: false,
      userFilter: null,
      userTotalRows: null,
      userCurrentPage: null,
      userPerPage: null,
      userLastPage: null,
      userList: []
    }
  },
  components: {},
  async mounted() {
    this.$on('value', this.fetchSelectionUser)
    if (this.emitOnMount) {
      this.$emit('input', this.value)
    }
  },
  methods: {
    ...mapActions('usersEditor', ['fetchUsersList', 'getUser']),
    clearValue() {
      this.$emit('onRemoveUser', this.value)
      // eslint-disable-next-line vue/no-mutating-props
      this.value = null
      this.$emit('input', null)
    },
    async refreshUsers() {
      const payload = await this.fetchUsersList({
        page: 1,
        perPage: 1000,
        query: this.userQuery,
        filters: [
          {
            type: 'related',
            relation: 'roles',
            field: 'name',
            value: 'Técnico'
          }
        ]
      })

      this.userTotalRows = payload.total
      this.userCurrentPage = payload.page
      this.userPerPage = payload.perPage
      this.userLastPage = payload.lastPage
      this.userList = payload.data ?? []
    },
    async onSelectModel(value) {
      this.$emit('input', value)
      this.$emit('onSelectUser', value)
    },
    onRemoveModel(value, arg2) {
      this.$emit('remove', value, arg2)
      this.$emit('onRemoveUser', value, arg2)
    },
    formattedLabel(user) {
      if (user) {
        return `${user?.employee?.registration ?? 'NI'} - ${user?.name ?? 'NI'} func:${user?.employee?.lab_function ??
          'NI'} - #${user?.id}`
      } else 'Nenhum Signatário Selecionado'
    },
    asyncFindUser(text) {
      this.userQuery = text
      this.findUser()
      this.userQuery = ''
    },
    findUser: debounce(function() {
      this.loadingUser = true
      this.refreshUsers().then((response) => {
        this.user = response
        this.loadingUser = false
      })
    }, 500),
    async fetchSelectionUser() {
      if (this?.value?.id) {
        const currentSigner = await this.getUser(this.value.id)
        // eslint-disable-next-line vue/no-mutating-props
        this.value = currentSigner
      }
    },
    async onOpenPicker() {
      await this.refreshUsers()
      await this.fetchSelectionUser()
    }
  }
}
</script>
