'use strict'

import _ from 'lodash'
import moment from 'moment'
import { filterElements, mutate, setState, isBusy, doAsyncBusyTask } from '@/helpers/store-helpers'
import atMeterSerialApi from '@/features/services/at-meter-serial-api'
import serviceMeterSerialApi from '@/features/services/service-meter-serial-api'

const emptyMeter = {
  uc_number: null,
  process_type: null,
  os_number: null,
  afferition_date: null,
  regional: null,
  process: null,
  client_name: null,
  client_address: null,
  client_class: null,
  toi: null,
  toi_date: null,
  toi_agency: null,
  location: null,
  serial_number: null,
  factory_number: null,
  meter_model_id: null,
  meterModel: null,
  casing_number: null,
  container_number: null
}

function normalizeMeterPayload(meterData) {
  const nobject = _.merge(JSON.parse(JSON.stringify(meterData)), {})
  console.log(meterData)
  if (meterData.trial_signer_id) {
    nobject.trial_signer_id = meterData.trial_signer_id.id
  }
  if (meterData.final_trial_signer_id) {
    nobject.final_trial_signer_id = meterData.final_trial_signer_id.id
  }
  if (meterData.meter_model_id) {
    nobject.meter_model_id = meterData.meter_model_id.id
  }
  return nobject
}

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    isBusy: false,
    activeService: null,
    meters_list: [],
    meter_filter: '',
    service_id: null,
    importedMetersData: [],
    atMetersFilter: '',
    atMetersIsBusy: false,
    atMetersTotalRows: 0,
    atMetersCurrentPage: 1,
    atMetersPerPage: 10,
    atMetersLastPage: 0,
    // user meter model props
    currentMeter: _.clone(emptyMeter)
  },
  actions: {
    async listATMeters({ state, commit }, payload) {
      const {
        page = 1,
        perPage = 100,
        service_id = null,
        reserved_number = null,
        meter_serial = null,
        toi = null,
        uc = null,
        status = undefined
      } = payload
      await commit('isBusy')
      try {
        commit('updateActiveService', {
          service_id
        })
        try {
          const serviceInfo = await atMeterSerialApi().service(service_id)
          setState(commit, 'activeService', serviceInfo)
        } catch (e) {
          console.warn(e)
        }

        const response = await atMeterSerialApi().list({
          page,
          perPage,
          service_id,
          meter_serial,
          reserved_number,
          toi,
          uc,
          status
        })
        setState(commit, 'atMetersTotalRows', response.total)
        setState(commit, 'atMetersCurrentPage', response.page)
        setState(commit, 'atMetersPerPage', response.perPage)
        setState(commit, 'atMetersLastPage', response.lastPage)
        if (Array.isArray(response)) {
          setState(commit, 'meters_list', response)
        } else if (response.data && Array.isArray(response.data)) {
          setState(commit, 'meters_list', response.data)
        } else setState(commit, 'meters_list', [])
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async storeServiceMeter({ state, commit }, serviceId) {
      await commit('isBusy')
      try {
        const serviceMeter = normalizeMeterPayload(state.currentMeter)
        let createdOrUpdated
        if (serviceMeter.id) {
          createdOrUpdated = await atMeterSerialApi().update({
            payload: serviceMeter,
            serviceId
          })
          commit('updateMeterOnList', createdOrUpdated)
        } else {
          createdOrUpdated = await atMeterSerialApi().store({
            payload: serviceMeter,
            serviceId
          })
          commit('addToList', createdOrUpdated)
        }
      } catch (e) {
        commit('isBusy', false)
        commit('isBusy', false)
        throw e
      }
    },
    async deleteServiceMeter({ state, commit }, { serviceId, item }) {
      await commit('isBusy')
      try {
        const deletedMeter = await atMeterSerialApi().delete(item.id, {
          serviceId
        })
        commit('removeFromList', deletedMeter)
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async loadServiceMeter({ state, commit }, serviceMeter) {
      await commit('isBusy')
      try {
        const payloadData = await serviceMeterSerialApi().get(serviceMeter.id, serviceMeter.service_id)
        commit('setCurrentMeter', payloadData)
        console.log('Fetched Meter', payloadData)
        return payloadData
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async addImportedFile({ state, commit }, { file, xhr, formData }) {
      await commit('isBusy')
      try {
        commit('addToImported', {
          file: file,
          xhr,
          headers: xhr.headers
            .filter((val) => !!val)
            .map((map) => {
              return {
                sortable: true,
                key: map.key,
                label: map.label
              }
            }),
          ignored_rows: xhr.ignored_rows,
          rows: xhr.rows,
          formData
        })
      } catch (e) {
        commit('isBusy', false)
        throw e
      }
    },
    async processMeterImport({ state, commit }, opts) {
      const { service_id } = opts ?? {}
      return doAsyncBusyTask({ commit, state, busyMutateName: 'isBusy' }, async ({ commit, state }) => {
        const result = await atMeterSerialApi().store({
          payload: state.importedMetersData.map((val) => val.xhr),
          serviceId: service_id
        })
        commit('resetImportedFiles')
        return result
      })
    },
    async removeAllImported({ state, commit }, { serviceId, meter }) {
      return commit('resetImportedFiles')
    },
    async setClientAccompanied({ state, commit }, { serviceId, meter }) {
      await commit('isBusy')
      try {
        const payload = normalizeMeterPayload(meter)
        payload.client_accompanied = !payload.client_accompanied
        let createdOrUpdated
        if (payload.id) {
          createdOrUpdated = await atMeterSerialApi().update({
            payload,
            serviceId
          })
          commit('updateMeterOnList', createdOrUpdated)
        }
      } catch (e) {
        commit('isBusy', false)
        commit('isBusy', false)
        throw e
      }
    }
  },
  getters: {
    totalMeters(state) {
      return (state.meters || []).length
    },
    errorMessage(state) {
      return state.errMessage
    },
    filteredMeters(state) {
      // filter our data
      return filterElements(state.meters_list, state.meter_filter)
    }
  },
  mutations: {
    mutate,
    isBusy,
    filterMeters(state, filter) {
      state.meter_filter = filter.toLowerCase()
    },
    updateActiveService(state, serviceData) {
      const { service_id = null } = serviceData ?? {}
      if (service_id) state.service_id = service_id
    },
    addToImported(state, payload) {
      state.importedMetersData.push(payload)
    },
    removeImported(state, { file, error, xhr }) {
      state.importedMetersData = state.importedMetersData.filter((val) => {
        if (val.file.upload.uuid === file.upload.uuid) {
          return false
        }
        return true
      })
    },
    resetImportedFiles(state, payload) {
      state.importedMetersData = []
    },
    deleteImported(state, payload) {
      state.importedMetersData.splice(state.importedMetersData.length, payload)
    },
    removeFromList(state, payload) {
      state.meters_list.forEach((meter, idx) => {
        if (meter.id === payload.id) {
          state.meters_list.splice(idx, 1)
        }
      })
    },
    addToList(state, payload) {
      state.meters_list.push(payload)
    },
    updateMeterOnList(state, serviceMeterSerial) {
      if (state?.meters_list?.length) {
        let indexFound = null
        for (const index in state.meters_list) {
          if (state.meters_list[index]?.id === serviceMeterSerial?.id) {
            indexFound = index
          }
        }
        if (indexFound) {
          state.meters_list.splice(indexFound, 1, serviceMeterSerial)
        } else {
          state.meters_list.push(serviceMeterSerial)
        }
      }
    },
    setCurrentMeter(state, newMeter = null) {
      if (newMeter) {
        const fMeter = _.clone(newMeter)
        if (fMeter.meterModel) {
          fMeter.meter_model_id = fMeter.meterModel
        }
        if (fMeter.service) {
          fMeter.service_id = fMeter.service.id
        }
        if (fMeter.afferition_date) {
          fMeter.afferition_date = moment(newMeter.afferition_date).toDate()
        }
        state.currentMeter = fMeter
      } else {
        state.currentMeter = _.clone(emptyMeter)
      }
    }
  }
}
