<template>
  <b-select
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    :options="options"
  />
</template>

<script>
export default {
  name: 'meter-group-selector',
  data() {},
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      required: false,
      default: 2
    },
    // eslint-disable-next-line vue/no-unused-properties
    size: {
      type: [String],
      default: 'md',
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    options: {
      type: [Array],
      required: false,
      default: () => [
        { text: 'A', value: 1 },
        { text: 'B', value: 2 },
        { text: 'Nenhum', value: null }
      ]
    }
  }
}
</script>

<style></style>
