import HttpClient from '@/services/http-client'

class TemperaturesApi extends HttpClient {
  async listMetersTemperatures({ noteItemId, serviceId }) {
    return this.rest.get('/v1/temperatures', {
      params: {
        noteItemId,
        serviceId
      }
    })
  }

  async updateWholeTemperatures({ serviceId, noteItemId, payload }) {
    return this.rest.post('/v1/temperatures/all-temperatures', payload, {
      params: {
        noteItemId,
        serviceId
      }
    })
  }

  async updateTemperature({ id, noteItemId, serviceId, payload }) {
    return this.rest.put(`/v1/temperatures/${id}`, payload, {
      params: {
        noteItemId,
        serviceId
      }
    })
  }
}

export default () => new TemperaturesApi()
