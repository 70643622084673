<template>
  <multiselect
    class="multiselect-primary"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <span slot="noResult"
      >Nada encontrado. Considere alterar o texto de busca.</span
    >
    <template slot="option" slot-scope="props">
      <slot name="option" v-bind:props="props.option"> </slot>
    </template>
    <template slot="singleLabel" slot-scope="props">
      <slot name="singleLabel" v-bind:props="props.option"> </slot>
    </template>
  </multiselect>
</template>

<!-- Multiselect-->
<style src="@/vendor/styles/pages/tickets.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style
  src="@/vendor/libs/vue-multiselect/vue-multiselect.scss"
  lang="scss"
></style>

<style scoped>
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 0.7rem !important;
}
.light-style .multiselect,
.light-style .multiselect__input,
.light-style .multiselect__single,
.light-style .multiselect__placeholder {
  font-size: 0.7rem !important;
}
</style>
<script>
import { Multiselect } from 'vue-multiselect'

export default {
  name: 'mutiselector',
  async mounted() {},
  components: {
    multiselect: Multiselect
  },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    // eslint-disable-next-line vue/no-unused-properties
    header: Array,
    // eslint-disable-next-line vue/no-unused-properties
    multiple: { default: false },
    // eslint-disable-next-line vue/no-unused-properties
    selectedLabel: { default: 'Já Selecionado' },
    // eslint-disable-next-line vue/no-unused-properties
    selectLabel: { default: 'Selecionar' },
    // eslint-disable-next-line vue/no-unused-properties
    deselectLabel: { default: 'Desfazer seleção' },
    // eslint-disable-next-line vue/no-unused-properties
    'tag-placeholder': { default: 'Selecione uma opção' },
    // eslint-disable-next-line vue/no-unused-properties
    placeholder: { default: 'Informe uma opção' },
    // eslint-disable-next-line vue/no-unused-properties
    'track-by': { default: 'id' },
    // eslint-disable-next-line vue/no-unused-properties
    label: { default: 'name' }
  },
  mixins: [],
  methods: {}
}
</script>
