'use strict'

import moment from 'moment'
import tempApi from '@/features/temperature-certificates/temperature-certificates-api'
import {
  filterElements,
  setState,
  mutate,
  doAsyncBusyTask
} from '@/helpers/store-helpers'

const busyMutateName = 'setTempCertificateBusy'
// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    isUploading: false,
    tempCertificateIsBusy: false,
    models: [],
    manufactors: [],
    tableTypes: [],
    tempCertificates: [],
    _certificatePassword: '',
    expiresAt: null,
    filter: '',
    tempCertificate: null,
    uploadProgress: 0,
    uploaded: false,
    rated_voltages: [],
    meterError: undefined
  },
  actions: {
    async validateTempPDF({ state, commit }, certificate) {
      await commit('isBusy', true)
      try {
        const validated = await tempApi().validateCertificate({
          selectedFile: certificate,
          password: state.password
        })
        commit(
          'setExpirationDate',
          moment(validated.parsed_data.calibrated_at).add(1, 'years').toDate()
        )
        certificate.data = validated
        console.warn(certificate)
        commit('setTempCertificate', certificate)
      } catch (e) {
        console.warn(e)
        throw e
      } finally {
        commit('isBusy', false)
      }
    },
    async listTempCertificates({ state, commit }, args) {
      const { page = 1, perPage = 1000, onlyActive = false } = args ?? {}
      await commit('isBusy', true)
      try {
        const tempCertificates = await tempApi().list({
          page,
          perPage,
          onlyActive
        })
        commit('setCertificateList', tempCertificates.data)
      } catch (e) {
        console.warn(e)
        throw e
      } finally {
        commit('isBusy', false)
      }
    },
    async fetchTempCertificate({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const found = await tempApi().get(id)
          return found
        }
      )
    },
    async createTemperatureCertificate({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const found = await tempApi().store(payload)
          return found
        }
      )
    },
    async updateTemperatureCertificate({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const found = await tempApi().update(payload)
          return found
        }
      )
    },
    async sendTempCertificate({ state, commit }, params) {
      const { newStandards = [] } = params ?? {}
      await commit('isUploading', true)
      try {
        const data = await tempApi().uploadTempCertificate({
          newStandards,
          selectedFile: state.tempCertificate,
          password: state._certificatePassword,
          expirationAt: state.expiresAt,
          onUpload: event => {
            setState(
              commit,
              'uploadProgress',
              (event.loaded / event.total) * 100
            )
            if (event.loaded / event.total === 1) {
              setState(commit, 'uploadProgress', 100)
            }
          }
        })

        commit('isUploading', false)
        commit('updateCertificates', data)
      } catch (error) {
        setState(commit, 'meterError', error.toString())
        commit('isUploading', false)
        throw error
      } finally {
        commit('isUploading', false)
      }
    },
    async deleteTemperatureCertificate({ state, commit }, payload) {
      try {
        commit('setTempCertificateBusy', true)
        const data = await tempApi().delete(payload.id)
        commit('removeCertificate', data)
      } catch (error) {
        commit('showError', error.message)
        throw error
      } finally {
        commit('isBusy', false)
      }
    }
  },
  mutations: {
    mutate,
    setTempCertificateBusy(state, value = false) {
      state.tempCertificateIsBusy = value
    },
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    isBusy(state, val = false) {
      state.busy = val
    },
    isUploading(state, val = false) {
      state.isUploading = val
    },
    setCertificateList(state, data) {
      state.tempCertificates = data
    },
    updateCertificates(state, data) {
      state.tempCertificates.splice(0, 0, data)
    },
    setExpirationDate(state, date) {
      state.expiresAt = date
    },
    setCertificatePassword(state, data) {
      state._certificatePassword = data
    },
    addModel(state, model) {
      state.tempCertificates.push(model)
    },
    removeCertificate(state, cert) {
      state.tempCertificates = state.tempCertificates.filter(
        met => met.id !== cert.id
      )
    },
    filterCertificates(state, filter) {
      state.filter = filter.toLowerCase()
    },
    resetFormData(state, filter) {
      state.expiresAt = null
      state._certificatePassword = null
      state.tempCertificate = null
      state.uploadProgress = 0
      state.isUploading = false
    },
    setTempCertificate(state, data) {
      state.tempCertificate = data
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalItems(state) {
      return state.tempCertificates.length || 0
    },
    uploadProgress(state) {
      if (!state.isUploading) return null
      return state.uploadProgress
    },
    selectedTable(state) {
      return state._selectedTable
    },
    certificatePassword(state) {
      return state._certificatePassword
    },
    validCertificatePassword(state) {
      if (state._certificatePassword && state._certificatePassword !== '') {
        return state._certificatePassword.length > 0
      }
      return undefined
    },
    filteredCertificates(state) {
      // filter our data
      const t = state.filter
      return filterElements(state.tempCertificates, t)
    }
  }
}
