<template>
  <div>
    <div v-if="value && value.model" class="">
      <div class="media flex-wrap">
        <div class="d-none d-sm-block ui-w-140">
          <a
            v-if="value.image"
            class="d-block ui-rect-67 ui-bg-cover meter-miniature"
            :style="{
              'background-image': `url('${value.image}')`
            }"
          ></a>
          <a
            v-else
            class="d-block ui-rect-67 ui-bg-cover meter-miniature"
            :style="{
              'background-image': `url('${$store.getters.noImageUrl}')`
            }"
          ></a>
        </div>
        <div class="media-body ml-sm-4">
          <h5 class="mb-2">
            <div class="float-right font-weight-semibold ml-3">
              #{{ value.id }}
            </div>
            <a class="text-body"> {{ value.name ? value.name : 'Sem Nome' }}</a>
          </h5>
          <div
            v-if="value.manufactor"
            class="d-flex flex-wrap align-items-center mb-2"
          >
            <div class="text-muted small">
              <b> Fabricante:</b>
              <span>{{ value.manufactor.name }}</span>
            </div>
          </div>
          <div class="d-flex flex-wrap align-items-center mb-2">
            <div class="text-muted small">
              <b>Modelo:</b>
              <span>{{ value.model }}</span>
            </div>
          </div>
          <div
            v-if="value.class"
            class="d-flex flex-wrap align-items-center mb-2"
          >
            <div class="text-muted small">
              <b> Classe:</b>
              <span>{{ value.formatted_class_name }}</span>
            </div>
          </div>

          <div class="d-flex flex-wrap align-items-center mb-2">
            <div class="text-muted small">
              <b> Corrente Maxima:</b>
              <span>{{ value.max_current }}</span>
            </div>
          </div>

          <div class="d-flex flex-wrap align-items-center mb-2">
            <div class="text-muted small">
              <b> Contante (K):</b>
              <span>{{ value.constant }}</span>
            </div>
          </div>
        </div>
        <div class="media-body ml-sm-4">
          <h5 class="mb-2">
            <div class="float-right font-weight-semibold ml-3"></div>
            <a class="text-body"><br /></a>
          </h5>
          <div
            v-if="value.meterType"
            class="d-flex flex-wrap align-items-center mb-2"
          >
            <div class="text-muted small">
              <b> Tipo Medidor:</b>
              <span>{{ value.meterType.name }}</span>
            </div>
          </div>
          <div
            v-if="value.ordinance"
            class="d-flex flex-wrap align-items-center mb-2"
          >
            <div class="text-muted small">
              <b> Portaria:</b>
              <span>{{ value.ordinance.name }}</span>
            </div>
          </div>
          <div
            v-if="value.connectionType"
            class="d-flex flex-wrap align-items-center mb-2"
          >
            <div class="text-muted small">
              <b> Tipo de Ligação:</b>
              <span>{{ value.connectionType.name }}</span>
            </div>
          </div>
          <div class="d-flex flex-wrap align-items-center mb-2">
            <div class="text-muted small">
              <b> Qtd. de Fios:</b>
              <span>{{ value.wires }}</span>
            </div>
            <br />
          </div>
          <div v-if="value.ratedVoltages" class="mt-2">
            <div class="text-muted small">
              <b> Tensões Nominais: </b>
            </div>
            <span
              v-for="voltage in value.ratedVoltages"
              :key="voltage.id"
              class="badge badge-outline-default text-muted font-weight-normal mr-1"
              >{{ voltage.name }}</span
            >
          </div>
          <div v-if="value.ratedCurrents" class="mt-2">
            <div class="text-muted small">
              <b> Correntes Nominais: </b>
            </div>
            <span
              v-for="current in value.ratedCurrents"
              :key="current.id"
              class="badge badge-outline-default text-muted font-weight-normal mr-1"
              >{{ current.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.meter-miniature {
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 250px;
  min-width: 150px;
}
</style>

<script>
export default {
  name: 'meter-info-small',
  props: {
    value: Object
  },
  mounted() {}
}
</script>
