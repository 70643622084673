'use strict'

import { filterElements } from '@/helpers/store-helpers'
// Thi store depends from some plugins like CASL in Vue instance

import trsApi from '@/features/trs-editor/service'
import trank4 from '@/helpers/seals-toolkit/providers/trank-4/index'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    trs_list: [],
    seals: []
  },
  actions: {
    async createTRS({ state, commit }, payload) {
      payload.id = state.trs_list.length + 1

      const created = await trsApi().store(payload)
      commit('insertTRS', created)
    },
    async fetchTRSList({ state, commit }, payload = {}) {
      const trs = await trsApi().list(payload ?? {})
      commit('setTRSList', trs)
      return trs
    },
    async deleteTRS({ state, commit }, payload) {
      const result = await trsApi().delete(payload.id)
      if (result) await commit('removeTrs', payload.id)
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    filteredTRS(state) {
      // filter our data
      return filterElements(state.trs_list, state.filter)
    },

    hasNewSeals(state) {
      let check
      try {
        if (this.isFirstTime) {
          check = undefined
        } else {
          check = state.seals.length > 3
        }
      } catch (e) {
        return false
      }
      return check
    }
  },
  mutations: {
    setTRSList(state, trs) {
      state.trs_list = trs.data
    },
    removeTrs(state, id) {
      state.trs_list = state.trs_list.filter(value => value.id !== id)
    },
    insertTRS(state, data) {
      state.trs_list.push(data)
    },
    addExampleSeal(state, seal) {
      state.sealsExamples.push(seal)
      state.foundedDigit = trank4.findCheckDigit(state.sealsExamples)
    },
    setInitial(state, seal) {
      state.initialSeal = seal
    },
    setFinal(state, seal) {
      state.finalSeal = seal
    },
    updateSeals(state, seals) {
      state.seals = seals || []
    },
    resetSealsState(state, _) {
      state.sealsExamples = []
      state.initialSeal = null
      state.finalSeal = null
    }
  }
}
