export default {
  methods: {
    showInfoModal: function (...args) {
      return this.$swal(
        Object.assign(...args, {
          icon: 'info',
          cancelButtonText: 'Cancelar'
        })
      )
    }
  }
}
