var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-btn', _vm._g(_vm._b({
    attrs: {
      "variant": "default",
      "title": "Limpar"
    }
  }, 'b-btn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('span', {
    staticClass: "ion ion-md-close"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }