import HttpClient from '@/services/http-client'

class MeterService extends HttpClient {
  async uploadImage(selectedFile) {
    const fd = new FormData()
    fd.append('image', selectedFile.file, selectedFile.file.name)
    return this.rest.post('/v1/meter-models/image', fd)
  }

  async createMeter(payload) {
    console.log('Creating', payload)
    return this.rest.post('/v1/meter-models', payload)
  }

  async updateModel(payload) {
    console.log('Updating', payload)
    return this.rest.patch('/v1/meter-models/' + payload.id, payload)
  }

  async delete(payload) {
    console.log('Deleting', payload)
    return this.rest.delete('/v1/meter-models/' + payload)
  }

  async createManufactor(payload) {
    return this.rest.post('/v1/manufactors', payload)
  }

  async createOrdinance(payload) {
    return this.rest.post('/v1/regulatory-ordinances', payload)
  }

  async list(opts) {
    const { page, perPage, text = null, notIncludeIds = null } = opts || {}
    return this.rest.get('/v1/meter-models', {
      params: {
        page,
        perPage,
        notIncludeIds,
        text
      }
    })
  }

  async show(id) {
    return this.rest.get('/v1/meter-models/' + id)
  }

  async fetchOrdinances() {
    return this.rest.get('/v1/regulatory-ordinances')
  }

  async fetchRatedVoltages() {
    return this.rest.get('/v1/rated-voltages')
  }

  async fetchRatedCurrents() {
    return this.rest.get('/v1/rated-currents')
  }

  async fetchConnectionTypes() {
    return this.rest.get('/v1/connection-types')
  }

  async fetchMeterTypes() {
    return this.rest.get('/v1/meter-types')
  }

  async fetchMeterClasses() {
    return this.rest.get('/v1/meter-classes')
  }

  async fetchManufactors() {
    return this.rest.get('/v1/manufactors')
  }

  async createVoltage(voltage) {
    return this.rest.post('/v1/rated-voltages', { voltage: voltage })
  }

  async createCurrent(current) {
    return this.rest.post('/v1/rated-currents', { current: current })
  }
}

export default () => new MeterService()
