module.exports = function (Vue) {
  Vue.directive('uppercase', {
    bind(el, _, vnode) {
      el.addEventListener('input', e => {
        e.target.value = e.target.value.toUpperCase()
        vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
      })
    }
  })

  Vue.directive('fiscal-number', {
    bind(el, _, vnode) {
      el.addEventListener('input', e => {
        e.target.value = e.target.value
          .replace(RegExp('[^0-9]+', 'gmi'), '')
          .toUpperCase()
        vnode.componentInstance.$emit(
          'input',
          e.target.value.replace(RegExp('[^0-9]+', 'gmi'), '').toUpperCase()
        )
      })
    }
  })

  Vue.directive('upper-product-code', {
    bind(el, _, vnode) {
      el.addEventListener('input', e => {
        e.target.value = e.target.value
          .replace(RegExp('[ ]+', 'gmi'), '')
          .toUpperCase()
        vnode.componentInstance.$emit(
          'input',
          e.target.value.replace(RegExp('[ ]+', 'gmi')).toUpperCase()
        )
      })
    }
  })
}
