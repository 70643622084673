<template>
  <b-row class="px-2 py-auto">
    <b-col class="mw-100">
      <Multiselector
        track-by="id"
        label="number"
        :custom-label="ordinanceText"
        :hide-selected="false"
        :taggable="true"
        :allowEmpty="true"
        :options="regulatoryOrdinanceList ?? []"
        :value="value"
        :disabled="disabled"
        @search-change="onSearchModel"
        @tag="onTag"
        @select="onSelectModel"
        @remove="onRemoveModel"
        selectLabel="Escolher"
        class="mw-100"
        selectedLabel="Selecionado"
        tag-placeholder="Escolha uma opção"
        placeholder="Escolha uma opção"
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
      />
    </b-col>

    <b-row>
      <b-btn-group class="btn-group mr-1">
        <Can I="create_regulatory_ordinances">
          <b-btn
            id="create-ordinance"
            slot="append"
            size="sm"
            variant="primary icon-btn"
            v-b-tooltip.hover
            title="Cadastrar Nova"
            @click="
              () => {
                // eslint-disable-next-line vue/no-mutating-props
                this.value = null
                this.$refs?.ordinanceModal?.show()
              }
            "
            ><span class="ion ion-md-add-circle"></span
          ></b-btn>
        </Can>
        <Can I="update_regulatory_ordinances">
          <b-btn
            id="update-ordinance"
            size="sm"
            :disabled="!value?.id"
            variant="primary icon-btn bg-periscope"
            @click="
              () => {
                this.$refs?.ordinanceModal?.show()
                this.$refs?.ordinanceEditor?.fetch()
              }
            "
            v-b-tooltip.hover
            title="Editar Portaria"
            ><span class="ion ion-ios-create"></span
          ></b-btn>
        </Can>
        <!-- Modal template -->
      </b-btn-group>
      <b-modal ref="ordinanceModal" size="lg" modal-class="modal-top" hide-footer>
        <OrdinanceEditor
          ref="ordinanceEditor"
          :entity-id="value?.id"
          @onGoBack="
            (ordinance) => {
              this.$refs?.ordinanceModal?.hide()
              this.listRegulatoryOrdinances({})
              // eslint-disable-next-line vue/no-mutating-props
              this.value = ordinance
              this.onSelectModel(ordinance)
            }
          "
        />
      </b-modal>
    </b-row>
  </b-row>
</template>

<script>
import regulatoryOrdinancePicker from '@/features/regulatory-ordinances/store/mixin'
export default {
  name: 'regulatory-ordinance-picker',
  mixins: [regulatoryOrdinancePicker],
  props: {
    value: {
      type: Object,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    emitOnMount: {
      default: false
    }
  },
  components: {},
  async mounted() {
    await this.listRegulatoryOrdinances({ page: 1, perPage: 1000 })
    if (this.emitOnMount) {
      // eslint-disable-next-line vue/no-mutating-props
      setTimeout(() => this.$emit('input', this.value), 400)
    }
  },
  data() {
    return {}
  },
  methods: {
    onSearchModel(value) {
      this.searchRegulatoryOrdinances(value, this.filters)
    },
    onSelectModel(modelSelected) {
      this.$emit('input', modelSelected)
      this.$emit('select', modelSelected)
    },
    onRemoveModel(arg1, arg2) {
      this.$emit('remove', arg1)
    },
    ordinanceText(regulatoryOrdinance) {
      let modelsSummary = ' - [Sem medidores]'
      if (regulatoryOrdinance?.meterModels?.length) {
        modelsSummary =
          ' - Medidores: ' +
          regulatoryOrdinance?.meterModels
            .slice(0, 3)
            .map(function (model) {
              return model.name
            })
            .join(',')
      }
      return regulatoryOrdinance?.name + modelsSummary + ' #' + regulatoryOrdinance?.id
    },
    async onTag(tagName) {
      const result = await this.confirmSomething({
        title: 'Deseja Criar a Portaria (' + tagName + ') ?',
        html: 'Isso irá cadastrar uma nova Portaria Regulatória chamada ' + tagName + '!'
      })
      if (result.isDenied) return
      if (result.isConfirmed) {
        const data = await this.createRegulatoryOrdinance({ name: tagName })
        // eslint-disable-next-line vue/no-mutating-props
        this.value = data
        await this.onSelectModel(data)
        this.$refs?.ordinanceModal?.show()
        this.$refs?.ordinanceEditor?.mounted()
        return data
      }
    },
    async searchRegulatoryOrdinances(search, filters = []) {
      await this.listRegulatoryOrdinances({ text: search })
    }
  }
}
</script>
