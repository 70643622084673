import reportsApi from '@/features/table-reports/table-reports-api'
import meterSerialTableReportsApi from '@/features/meter-serials-table-report/meter-serial-table-reports-api'

import { setState, filterElements, mutate, doAsyncBusyTask } from '@/helpers/store-helpers'

import atMeterSerialApi from '@/features/services/at-meter-serial-api'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    showDetailsModal: false,
    reportsListingBusy: false,
    serialAnomaliesIsBusy: false,
    serials: [],
    filter: '',
    meterSerials: [],
    serialReports: [],
    currentDetails: null,
    _serialAndPositions: null,
    _lastReportID: null,
    _lastServiceItemId: null,
    currentServiceMeterSerial: null,
    detailsView: null,
    busySerial: {},
    positionSerial: {}
  },
  actions: {
    async fetchSerials({ state, commit }, id) {
      try {
        commit('setIsBusy', true)
        const meterSerials = await atMeterSerialApi().list({ service_id: id })
        setState(commit, '_lastServiceItemId', id)
        commit('setMeterSerials', meterSerials)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setIsBusy', false)
      }
    },
    async refetchSerials({ state, commit, dispatch }) {
      await dispatch('fetchSerials', state._lastServiceItemId)
    },
    async fetchReports({ state, commit }, { noteItemId, serialNumber }) {
      try {
        commit('setListingBusy', true)
        const data = await meterSerialTableReportsApi().list({
          noteItemId,
          serialNumber
        })
        commit('setSerialReports', data)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setListingBusy', false)
      }
    },
    async fetchDetails({ state, commit }, reportId) {
      try {
        commit('setDetailsBusy', true)
        const data = await meterSerialTableReportsApi().get(reportId)
        setState(commit, 'currentDetails', data)
      } catch (e) {
        console.error(e)
      } finally {
        commit('setDetailsBusy', false)
      }
    },
    async uploadReport({ state, commit }, { file, serviceItem, onProgressUpdate }) {
      const serviceID = serviceItem.fiscal_note_item_id
      const serialID = serviceItem.meter_serial_id
      try {
        commit('serialBusy', { id: serialID, value: true })
        commit('setIsBusy', true)
        const data = await reportsApi().uploadReport(file, serviceID, serialID, onProgressUpdate)
        commit('updateSerialReport', data)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('serialBusy', { id: serialID, value: false })
        commit('setIsBusy', false)
      }
    },
    async uploadReportFile({ state, commit }, { file, serviceItem }) {
      try {
        commit('setIsBusy', true)

        const reportInfo = await reportsApi().uploadReport(file, serviceItem.id)

        if (reportInfo.positions) {
          setState(commit, '_serialAndPositions', [])
          setState(commit, '_serialAndPositions', reportInfo.positions)
        }
        if (reportInfo.tableReport) {
          setState(commit, '_lastReportID', reportInfo.tableReport.id)
        }
        return reportInfo
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setIsBusy', false)
      }
    },
    async addUploadedReport({ state, commit }, { file, data }) {
      try {
        commit('setIsBusy', true)

        if (data.positions) {
          setState(commit, '_serialAndPositions', [''])
          setState(commit, '_serialAndPositions', data.positions)
        }
        if (data.tableReport) {
          setState(commit, '_lastReportID', data.tableReport.id)
        }
        return data
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setIsBusy', false)
      }
    },
    async associatePositionsReport({ state, commit, dispatch }, { serviceItem, reportArray }) {
      await commit('setIsBusy', true)
      try {
        const toExecute = []
        for (const report of reportArray) {
          const payload = {
            positions: report.data.positions.filter((val) => {
              return (
                val.position &&
                val.position.toString().length > 0 &&
                val.serial_number &&
                val.serial_number.toString().length > 0
              )
            }),
            tableReportID: report.data.tableReport.id,
            serviceItemID: serviceItem.id
          }
          toExecute.push(payload)
        }
        const metersWithData = await Promise.all(
          toExecute.map((item) => meterSerialTableReportsApi().associateSerialReport(item))
        )
        await Promise.all(metersWithData.map((value) => commit('updateSerialsData', value)))

        await dispatch('refetchSerials')
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setIsBusy', false)
      }
    },
    async updateMeterSerialReport({ state, commit, dispatch }, reportSerialPivot) {
      try {
        commit('setDetailsBusy', true)

        const metersWithData = await meterSerialTableReportsApi().recalculateSerialReport(reportSerialPivot.id)
        commit('updateSerialsData', metersWithData)

        await dispatch('fetchReports', {
          noteItemId: reportSerialPivot.tableReport.fiscal_note_item_id,
          serialNumber: reportSerialPivot.meterSerial.serial_number
        })
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setDetailsBusy', false)
      }
    },
    async fetchMeterSerialResponsible({ state, commit, dispatch }, meterData) {
      return doAsyncBusyTask({ commit, state }, async ({ commit, state }) => {
        setState(commit, 'currentServiceMeterSerial', meterData)
      })
    },
    async fetchFinalSigner({ state, commit, dispatch }, meterData) {
      return doAsyncBusyTask({ commit, state }, async ({ commit, state }) => {
        setState(commit, 'currentServiceMeterSerial', meterData)
      })
    },
    async updateTrialSigner({ state, commit, dispatch }, trialSigner) {
      return doAsyncBusyTask({ commit, state }, async ({ commit, state }) => {
        const serviceMeterSerial = state.currentServiceMeterSerial
        serviceMeterSerial.trialSigner = trialSigner
        const updated = await atMeterSerialApi().update({
          payload: serviceMeterSerial,
          serviceId: serviceMeterSerial.service_id
        })
        setState(commit, 'currentServiceMeterSerial', updated)
        commit('updateMeterSerials', updated)
      })
    },
    async updateFinalSigner({ state, commit, dispatch }, newSigner) {
      return doAsyncBusyTask({ commit, state }, async ({ commit, state }) => {
        const serviceMeterSerial = state.currentServiceMeterSerial
        serviceMeterSerial.finalTrialSigner = newSigner
        const updated = await atMeterSerialApi().update({
          payload: serviceMeterSerial,
          serviceId: serviceMeterSerial.service_id
        })
        setState(commit, 'currentServiceMeterSerial', updated)
        commit('updateMeterSerials', updated)
      })
    },
    async removeTrialSigner({ state, commit, dispatch }) {
      return dispatch('updateTrialSigner', null)
    },
    async dissociateSerialReport({ dispatch, state, commit }, reportSerialPivot) {
      try {
        commit('setIsBusy', true)

        await meterSerialTableReportsApi().delete(reportSerialPivot.id)

        await dispatch('fetchReports', {
          noteItemId: reportSerialPivot.tableReport.fiscal_note_item_id,
          serialNumber: reportSerialPivot.meterSerial.serial_number
        })
        await dispatch('refetchSerials')
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setIsBusy', false)
      }
    },
    async getReportMeterSerials({ dispatch, state, commit }, reporSerialPivottId) {
      try {
        commit('setIsBusy', true)

        const data = await meterSerialTableReportsApi().get(reporSerialPivottId)
        return data.tableReport.meterSerials
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setIsBusy', false)
      }
    },
    async dissociateAllSerialReport({ dispatch, state, commit }, reportSerialPivot) {
      try {
        commit('setIsBusy', true)

        await meterSerialTableReportsApi().dissociateAll(reportSerialPivot.id)

        await dispatch('fetchReports', {
          noteItemId: reportSerialPivot.tableReport.fiscal_note_item_id,
          serialNumber: reportSerialPivot.meterSerial.serial_number
        })
        await dispatch('refetchSerials')
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('setIsBusy', false)
      }
    }
  },
  getters: {
    certificatePositions(state) {
      return state._serialAndPositions || []
    },
    serialMeters(state) {
      return state.serials || []
    },
    serialsBusy(state) {
      return (serialID) => {
        try {
          return state.busySerial[serialID] || false
        } catch (e) {
          return false
        }
      }
    },
    isMissingPositions(state) {
      const totalPositions = state._serialAndPositions.length

      let totalFilled = 0
      for (const position in state.positionSerial) {
        if (Object.hasOwnProperty.call(state.positionSerial, position)) {
          if (state.positionSerial[position] && state.positionSerial[position].length > 0) {
            totalFilled += 1
          }
        }
      }
      return totalFilled !== totalPositions
    },
    filteredMeterAnomalies(state) {
      return filterElements(state.meterSerials, state.filter)
    }
  },
  mutations: {
    mutate,
    setSerialReports(state, value) {
      state.serialReports = value
    },
    setDetailsBusy(state, value = false) {
      state.detailsBusy = value
    },
    setShowDetailsModal(state, value = false) {
      state.showDetailsModal = value
    },
    setListingBusy(state, value = false) {
      state.reportsListingBusy = value
    },
    setMeterSerials(state, rows = []) {
      state.meterSerials = rows.map((row) => {
        if (!row.anomalies) row.anomalies = []
        if (!row.photos) row.photos = []
        return row
      })
    },
    updateMeterSerials(state, newPayload) {
      console.warn('updatedTrialSigner', newPayload)
      const updatedArray = state.meterSerials.map((ms) => {
        if (ms.id === newPayload.id) {
          ms = newPayload
        }
        return ms
      })
      state.meterSerials = updatedArray
    },
    deleteMeterSerials(state, newPayload) {
      console.warn('updatedTrialSigner', newPayload)
      const updatedArray = state.meterSerials.map((ms) => {
        if (ms.id === newPayload.id) {
          ms = newPayload
        }
        return ms
      })
      state.meterSerials = updatedArray
    },
    filterMeterSerialReports(state, value) {
      state.filter = value || ''
    },
    updateSerialReport(state, value) {
      for (let index = 0; index < state.serials.length; index++) {
        const element = state.serials[index]
        if (element.id === value.id) {
          state.serials.splice(index, 1, value)
        }
      }
    },
    updateSerialsData(state, value) {
      for (let idx = 0; idx < state.serials.length; idx++) {
        const element = state.serials[idx]
        if (element.serial_number === value.serial_number) {
          state.serials = state.serials.splice(idx, 1, value)
        }
      }
    },
    serialBusy(state, { id, value }) {
      state.busySerial[id] = value
    },
    setDetailView(state, item) {
      try {
        state.detailsView = null
        state.detailsView = JSON.parse(item.tableReports[0].pivot.parsed_data)
      } catch (error) {
        console.error(error)
      }
    },
    bindSerialPosition(state, { serial_number, position }) {
      for (let idx = 0; idx < state._serialAndPositions.length; idx++) {
        if (state._serialAndPositions[idx].position === position) {
          state._serialAndPositions.splice(idx, 1, {
            position,
            serial_number
          })
        }
      }
    },
    setIsBusy(state, value = true) {
      state.serialAnomaliesIsBusy = value
    }
  }
}
