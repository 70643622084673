'use strict'

export default {
  namespaced: true,
  state: {
    emailHistoryList: []
  },
  actions: {},
  getters: {},
  mutations: {
    addToEmailHistory(state, value) {
      if (!state.emailHistoryList.find((entry) => entry === value)) {
        state.emailHistoryList.push(value)
      }
    }
  }
}
