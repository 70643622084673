export default {
  mounted() {},
  methods: {
    buttonColor(state) {
      switch (state) {
        case 'sealing':
          return 'warning'
        case 'pistolado':
          return 'warning'
        case 'testes':
          return 'warning'
        case 'finished':
          return 'success'
        default:
          return 'warning'
      }
    },
    pageFromState(state) {
      switch (state) {
        case 'pistolcodes':
          return 0
        case 'samples':
          return 1
        case 'importreports':
          return 2
        case 'additionalchecks':
          return 3
        case 'sealing':
          return 4
        case 'finished':
          return 5
        default:
          return 0
      }
    }
  },
  computed: {}
}
