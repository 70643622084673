'use strict'

import sealingService from '@/features/sealing-meters/sealing-meter-api'
import { debounce } from 'lodash'

import { setState, filterElements, mutate } from '@/helpers/store-helpers'
// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    _sealState: {},
    _sealsErrors: {},
    _stampError: {},
    isBusy: false,
    inputSeals: {},
    serialInput: '',
    sealings: [],
    serialErrors: [],
    invalidSerial: null,
    validSerial: null,
    serialBusy: false,
    filter: '',
    sealsBusy: {
      0: false,
      1: false
    }
    // table
  },
  actions: {
    async fetchSealings({ state, commit }, serviceItemID) {
      try {
        await setState(commit, 'isBusy', true)
        await commit('setServiceItemID', serviceItemID)
        const data = await sealingService().list(serviceItemID)

        commit('setSealings', data)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        await setState(commit, 'isBusy', false)
      }
    },
    debounceAction: debounce(({ dispatch }, { name, value }) => {
      dispatch(name, value)
    }, 1000),
    checkValidSerial: async ({ state, commit, dispatch }, value) => {
      try {
        setState(commit, 'serialBusy', true)
        commit('setSerialInput', value)
        await sealingService().isValidSerial(state.serviceItemID, value)
        setState(commit, 'validSerial', value)
      } catch (err) {
        setState(commit, 'invalidSerial', value)
        if (err.response.status === 400) {
          setState(commit, 'serialErrors', err.response.data)
        } else {
          throw err
        }
      } finally {
        setState(commit, 'serialBusy', false)
      }
    },
    checkValidSeal: async ({ state, commit }, { value, index }) => {
      await commit('setBusySeal', { index, value: true })
      try {
        commit('setInputSeal', { value, index })

        await sealingService().isValidSeal(state.serviceItemID, value)
        commit('setValidSeal', { value, index })
        // check duplicated inputs
        // for (const key in state.inputSeals) {
        //   if (
        //     Object.hasOwnProperty.call(state.inputSeals, key) &&
        //     key !== index
        //   ) {
        //     if (state.inputSeals[key].trim() === value.trim()) {
        //       await commit('setInvalidSeal', { value, index })
        //       await commit('setSealsErrors', {
        //         index,
        //         value: [{ message: 'Este selo já foi digitado' }]
        //       })
        //     }
        //   }
        // }
      } catch (err) {
        commit('setInvalidSeal', { value, index })
        if (err.response.status === 400) {
          commit('setSealsErrors', {
            index,
            value: err.response.data
          })
        } else {
          throw err
        }
      } finally {
        commit('setBusySeal', { index, value: false })
      }
    },
    async stampMeter({ state, commit }) {
      await setState(commit, 'isBusy', true)
      try {
        const sealsToUpload = []

        for (const key in state.inputSeals) {
          if (Object.hasOwnProperty.call(state.inputSeals, key)) {
            sealsToUpload.push(state.inputSeals[key])
          }
        }
        console.log(state.inputSeals, sealsToUpload)
        const sealings = await sealingService().stampMeter({
          serviceItemID: state.serviceItemID,
          meterSerial: state.serialInput,
          seals: sealsToUpload
        })
        commit('setSealings', sealings)
        await setState(commit, 'isBusy', false)
      } catch (e) {
        await setState(commit, 'isBusy', false)
        throw e
      }
    },

    async resetSealingModal({ state, commit }, payload) {
      await commit('resetSealingData')
    },

    async damageSeal({ dispatch, state, commit }, seal) {
      await setState(commit, 'isBusy', true)
      try {
        await sealingService().setDamagedSeal(seal.code)

        await dispatch('fetchSealings', state.serviceItemID)
        await setState(commit, 'isBusy', false)
      } catch (error) {
        await dispatch('fetchSealings', state.serviceItemID)

        await setState(commit, 'isBusy', false)
        throw error
      }
    },

    async dissociateSeal({ dispatch, state, commit }, seal) {
      await setState(commit, 'isBusy', true)
      try {
        await sealingService().dissociateSeal(seal.code)

        await dispatch('fetchSealings', state.serviceItemID)
        await setState(commit, 'isBusy', false)
      } catch (error) {
        await dispatch('fetchSealings', state.serviceItemID)
        await setState(commit, 'isBusy', false)
        throw error
      }
    },
    async dissociateVirtualSeal({ dispatch, state, commit }, virtualSeal) {
      await setState(commit, 'isBusy', true)
      try {
        await sealingService().dissociateVirtualSeal(virtualSeal.pivot.id)

        await dispatch('fetchSealings', state.serviceItemID)
        await setState(commit, 'isBusy', false)
      } catch (error) {
        await dispatch('fetchSealings', state.serviceItemID)
        await setState(commit, 'isBusy', false)
        throw error
      }
    },
    async associateVirtualSeal({ dispatch, state, commit }, meterSerial) {
      await setState(commit, 'isBusy', true)
      try {
        await sealingService().associateVirtualSeal(meterSerial)

        await dispatch('fetchSealings', state.serviceItemID)
        await setState(commit, 'isBusy', false)
      } catch (error) {
        await dispatch('fetchSealings', state.serviceItemID)
        await setState(commit, 'isBusy', false)
        throw error
      }
    },
    async stampNoteItemVirtualSeals({ dispatch, state, commit }, noteItem) {
      await setState(commit, 'isBusy', true)
      try {
        await sealingService().stampNoteItemVirtualSeals(state.serviceItemID)
        await setState(commit, 'isBusy', false)
        await dispatch('fetchSealings', state.serviceItemID)
      } catch (error) {
        await setState(commit, 'isBusy', false)
        await dispatch('fetchSealings', state.serviceItemID)
        throw error
      }
    }
  },
  mutations: {
    // set state need this
    mutate,
    resetSeals(state, index) {
      if (index) {
        delete state.inputSeals[index]
        delete state._sealState[index]
        delete state.validSeals[index]
      } else {
        state.inputSeals = {}
        state._sealState = {}
        state.validSeals = {}
      }
    },
    cleanErrors(state) {
      state.errMessage = undefined
    },
    setSerialErrors(state, data) {
      state.serialErrors = data
    },
    resetSealingData(state) {
      if (!state.rememberBag) {
        state.selectedSealsBag = null
      }
      state.inputSeals = {}
      state._sealState = {}
      state._sealsErrors = {}
      state.validSeals = {}
      state.serialInput = null
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    setBusySeal(state, data) {
      const budy = state.sealsBusy
      budy[data.index] = data.value
      state.sealsBusy = {}
      state.sealsBusy = budy
    },
    setServiceItemID(state, value) {
      state.serviceItemID = value
    },
    setSealings(state, seals) {
      state.sealings = seals
    },
    setSerialInput(state, serial) {
      state.serialInput = serial
    },
    setSealsBagInput(state, input) {
      state.selectedSealsBag = input
    },
    setInputSeal(state, seal) {
      state.inputSeals[seal.index] = seal.value
    },
    setValidSeal(state, seal) {
      state._sealState[seal.index] = true
    },
    setInvalidSeal(state, seal) {
      state._sealState[seal.index] = false
    },
    setSealsErrors(state, entry) {
      const sealERr = state._sealsErrors
      sealERr[entry.index] = entry.value
      state._sealsErrors = {}
      state._sealsErrors = sealERr
    },
    removeSeal(state, seal) {
      state.sealings = state.sealings.filter((met) => met.id !== seal.id)
    },
    filterSealings(state, filter) {
      state.filter = filter.toLowerCase()
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalSealings(state) {
      return state.sealings.length || 0
    },
    currentSerial(state) {
      return state.serialInput || ''
    },
    isValidSerial(state) {
      const currentSerial = state.serialInput
      if (state.invalidSerial) {
        return !(currentSerial === state.invalidSerial)
      }
      if (state.validSerial) {
        return currentSerial === state.validSerial
      }
      return undefined
    },
    sealState(state) {
      return state._sealState
    },
    sealsErrors(state) {
      return state._sealsErrors || {}
    },
    selectedMeter(state) {
      return state.selected
    },
    needSealCount(state) {
      return state.sealings
    },
    canProceed(state) {
      return true
    },
    hasStampError(state) {
      return state._stampError
    },
    filteredSealings(state) {
      return filterElements(state.sealings, state.filter)
    }
  }
}
