'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import energyDirectionService from '@/features/energy-directions/energy-directions-api'

const busyMutateName = 'setEnergyDirectionsBusy'

export default {
  namespaced: true,
  state: {
    energyDirectionList: [],
    energyDirectionFilter: '',
    energyDirectionIsBusy: false,
    energyDirectionTotalRows: 0,
    energyDirectionCurrentPage: 1,
    energyDirectionPerPage: 10,
    energyDirectionLastPage: 0
  },
  actions: {
    async listEnergyDirections({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await energyDirectionService().list(page, perPage, text)
          setState(commit, 'energyDirectionTotalRows', payload.total)
          setState(commit, 'energyDirectionCurrentPage', payload.page)
          setState(commit, 'energyDirectionPerPage', payload.perPage)
          setState(commit, 'energyDirectionLastPage', payload.lastPage)

          commit('setEnergyDirectionsList', payload.data)
        }
      )
    },
    async showEnergyDirection({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return energyDirectionService().show(id)
        }
      )
    },
    async createEnergyDirection({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = energyDirectionService().create(payload)
          commit('unshiftEnergyDirection', entry)
        }
      )
    },
    async updateEnergyDirection({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = energyDirectionService().update(payload)
          commit('updateEnergyDirectionInList', entry)
          return entry
        }
      )
    },
    async deleteEnergyDirection({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await energyDirectionService().delete(id)
          if (result) await commit('removeEnergyDirectionInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredEnergyDirections(state) {
      return filterElements(state.energyDirectionList, state.filter)
    }
  },
  mutations: {
    mutate,
    setEnergyDirectionsList(state, payload) {
      state.energyDirectionList = payload
    },
    setEnergyDirectionsFilter(state, payload) {
      state.energyDirectionList = payload
    },
    setEnergyDirectionsBusy(state, value = false) {
      state.energyDirectionIsBusy = value
    },
    unshiftEnergyDirection(state, payload) {
      state.energyDirectionList.unshift(payload)
    },
    removeEnergyDirectionInList(state, payload) {
      state.energyDirectionList = state.energyDirectionList.filter(
        energyDirection => energyDirection.id !== payload
      )
    },
    updateEnergyDirectionInList(state, payload) {
      if (state.energyDirectionList) {
        state.energyDirectionList = state.energyDirectionList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
