'use strict'
// Thi store depends from some plugins like CASL in Vue instance
import meterModelsApi from '@/features/meter-models/meter-models-api'
import { setState, mutate } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    models: [],
    _ordinances: [],
    meterBusy: false,
    rated_voltages: ['127'],
    meterError: undefined
  },
  actions: {
    async loadFields({ state, commit }) {
      const data = await meterModelsApi().fetchOrdinances()
      setState(commit, '_ordinances', data.data)
    },
    async createOrdinance({ state, commit }, ordinance) {
      const data = await meterModelsApi().createOrdinance(ordinance)
      console.log('Fetched', data)
      commit('addToList', data)
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    isBusy(state, val = false) {
      state.meterBusy = val
    },
    addToList(state, model) {
      state._ordinances.push(model)
    },
    updateFilter(state, filter) {
      state.filter = filter.toLowerCase()
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    totalItems(state) {
      return state._ordinances.length || 0
    },
    sheetOrdinances(state) {
      const maped = state._ordinances.map((vol) => {
        return { ID: vol.id, Nº: vol.name, 'Criado em': vol.created_at }
      })
      return maped
    },
    filteredOrdinances(state) {
      // filter our data
      if (state._ordinances) {
        const filtered = state._ordinances.filter((d) => {
          // Concat data
          return (
            Object.keys(d)
              .map((k) => String(d[k]))
              .join('|')
              .toLowerCase()
              .indexOf(state.filter) !== -1 || !state.filter
          )
        })
        // update the rows
        return filtered
      } else {
        return []
      }
    }
  }
}
