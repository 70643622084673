<template>
  <BusyOverlay :show="isBusy">
    <small @click="refreshBranches" class="disabled">
      Exibindo de Filiais: {{ this.branchString }}
    </small>
  </BusyOverlay>
</template>

<script>
import branchesClient from '@/features/branches/branches-api'

export default {
  name: 'BranchesIndicator',
  async mounted() {
    await this.refreshBranches()
  },
  data() {
    return {
      isBusy: false,
      userBranches: []
    }
  },
  computed: {
    branchString() {
      if (this.userBranches?.length) {
        return this.userBranches.map(value => value.name).join(', ')
      }
      return '(Nenhuma Filial Encontrada)'
    }
  },
  methods: {
    async refreshBranches() {
      this.isBusy = true
      try {
        this.userBranches = await branchesClient().getAllowed()
      } catch (error) {
        this.isBusy = false
        throw error
      }
      this.isBusy = false
    }
  }
}
</script>

<style></style>
