import HttpClient from '@/services/http-client'

class BranchesAPI extends HttpClient {
  async list() {
    return this.rest.get('/v1/branches')
  }

  async getAllowed() {
    return this.rest.get('/v1/branches/allowed')
  }
}
export default () => {
  return new BranchesAPI()
}
