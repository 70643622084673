'use-strict'

/** @typedef {import('vue').VueConstructor } VueConstructor */

/**
 * Register all project native components
 * @param {VueConstructor} Vue
 */
function loadComponents(Vue) {
  const components = require.context(
    '../components/globals/',
    true,
    /.(vue)$/im
  )
  // components.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], components(key).default))
  // Para cada script de rota encontrada faça o mapeamento
  // const globals = new Map() // for debug purposes
  for (const componentKey of components.keys()) {
    const name = componentKey.split('/').pop().split('.')[0]
    // if (process?.env?.NODE_ENV !== 'production') {
    //   globals.set(name, componentKey) // for debug purposes
    // }
    const componentFile = components(componentKey)
    Vue.component(name, componentFile.default ?? componentFile)
  }
  // if (process?.env?.NODE_ENV !== 'production') {
  // console.log('[LOADED COMPONENTS]', globals) // for debug purposes
  // }
}

export default loadComponents
